import Vue from 'vue'
import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_BRANCH } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  branch: {},
  branches: {meta:{},data:[]},
  modal_add: {},
  getAddBranchMsg: {},
  modal_edit: {},
  getEditBranchMsg: {}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_BRANCHES": (state, payload) => {
    Vue.set(state, 'branches', payload)
  },
  "SET_BRANCH": (state, payload) => {
    Vue.set(state, 'branch', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modal_add', payload)
  },
  "CREATE_BRANCHES": (state, payload) => {
    Vue.set(state, 'getAddBranchMsg', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modal_edit', payload)
  },
  "UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'getEditBranchMsg', payload)
  }
}

const actions = {
  async getBranch(context, data) {
    try {
      const response = await apiGetAuth(API_BRANCH.LIST, data)
      context.commit('SET_BRANCHES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getBranchDetail(context, data) {
    try {
      const response = await apiGetAuth(API_BRANCH.DETAIL(data))
      context.commit('SET_BRANCH', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveBranch(context, data) {
    try {
      const response = await apiPostAuth(API_BRANCH.CREATE, data)
      context.commit('CREATE_BRANCHES', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async editBranch(context, data) {
    try {
      const response = await apiPutAuth(API_BRANCH.UPDATE, data)
      context.commit('UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  branches: state => {
    return state.branches
  },
  branch: state => {
    return state.branch
  },
  modal_add: state => {
    return state.modal_add
  },
  getAddBranchMsg: state => {
    return state.getAddBranchMsg
  },
  modal_edit: state => {
    return state.modal_edit
  },
  getEditBranchMsg: state => {
    return state.getEditBranchMsg
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
