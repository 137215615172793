import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_ACQUISITION_DASHBAORD } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = {
  dataTotalStatus: {},
  dataMeta: {},
  dataMetaClosingRate: {}
}

const mutations = {
  "SET_DATA_TOTAL_STATUS": (state, payload) => {
    Vue.set(state, 'dataTotalStatus', payload)
  },
  "SET_DATA_STATUS_MITRA": (state, payload) => {
    Vue.set(state, 'dataStatusMitra', payload)
  },
  "SET_DATA_REASON_FAILED": (state, payload) => {
    Vue.set(state, 'dataReasonFailed', payload)
  },
  "SET_DATA_MAPPING_AREA": (state, payload) => {
    Vue.set(state, 'dataMappingArea', payload)
  },
  "SET_DATA_MAPPING_AREA_CHLOROPLETH_DATASET": (state, payload) => {
    Vue.set(state, 'dataMappingAreaChloropleth', payload)
  },
  "SET_DATA_ACQUISITION_RATE": (state, payload) => {
    Vue.set(state, 'dataAcquisitionRate', payload)
  },
  "SET_DATA_CLOSING_RATE": (state, payload) => {
    Vue.set(state, 'dataClosingRate', payload)
  },
  "SET_DATA_META": (state, payload) => {
    Vue.set(state, 'dataMeta', payload)
  },
  "SET_DATA_META_CLOSING_RATE": (state, payload) => {
    Vue.set(state, 'dataMetaClosingRate', payload)
  }
}

const actions = {
  async fetchTotalStatus(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.TOTAL_STATUS, data)
      context.commit('SET_DATA_TOTAL_STATUS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchStatusMitra(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.STATUS_MITRA, data)
      context.commit('SET_DATA_STATUS_MITRA', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchReasonFailed(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.REASON_FAILED, data)
      context.commit('SET_DATA_REASON_FAILED', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchMappingArea(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.MAPPING_AREA, data)
      context.commit('SET_DATA_MAPPING_AREA', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchMappingAreaDataset(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.MAPPING_AREA_CHLOROPLETH_DATASET, data)
      context.commit('SET_DATA_MAPPING_AREA_CHLOROPLETH_DATASET', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchAcquisitionRate(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.ACQUISITION_RATE, data)
      context.commit('SET_DATA_ACQUISITION_RATE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchClosingRate(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION_DASHBAORD.CLOSING_RATE, data)
      context.commit('SET_DATA_CLOSING_RATE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  dataTotalStatus: state => {
    return state.dataTotalStatus
  },
  dataStatusMitra: state => {
    return state.dataStatusMitra
  },
  dataReasonFailed: state => {
    return state.dataReasonFailed
  },
  dataMappingArea: state => {
    return state.dataMappingArea
  },
  dataMappingAreaChloropleth: state => {
    return state.dataMappingAreaChloropleth
  },
  dataAcquisitionRate: state => {
    return state.dataAcquisitionRate
  },
  dataClosingRate: state => {
    return state.dataClosingRate
  },
  dataMeta: state => {
    return state.dataMeta
  },
  dataMetaClosingRate: state => {
    return state.dataMetaClosingRate
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
