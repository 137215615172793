import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_ACTIVITY_CATEGORY } from '@/utils/api-url'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'


const state = {
  activityCategorys: {meta:{},data:[]},
  activityCategorysFilter: {meta:{},data:[]},
  activityCategoryDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  deleteActivityCategory: {},
  modalAdd: {},
  modalEdit: {},
  addActivityCategory: {}
}

const mutations = {
  "SET_ACTIVITY_CATEGORYS": (state, payload) => {
    Vue.set(state, 'activityCategorys', payload)
  },
  "SET_ACTIVITY_CATEGORYS_FILTER": (state, payload) => {
    Vue.set(state, 'activityCategorysFilter', payload)
  },
  "SET_ACTIVITY_CATEGORY_DETAIL": (state, payload) => {
    Vue.set(state, 'activityCategoryDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteActivityCategory', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addActivityCategory', payload)
  }
}

const actions = {
  async getActivityCategory(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CATEGORY.LIST, data)
      context.commit('SET_ACTIVITY_CATEGORYS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityCategoryFilter(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CATEGORY.LIST, data)
      context.commit('SET_ACTIVITY_CATEGORYS_FILTER', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityCategoryDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CATEGORY.DETAIL(data))
      context.commit('SET_ACTIVITY_CATEGORY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importActivityCategory(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY_CATEGORY.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteActivityCategory(context, data) {
    try {
      const response = await apiDeleteAuth(API_ACTIVITY_CATEGORY.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveActivityCategory(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY_CATEGORY.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async editActivityCategory(context, data) {
    try {
      const response = await apiPutAuth(API_ACTIVITY_CATEGORY.UPDATE(data.id), data)
      notificationSuccess(`Ubah Data "${response.data.data.name || ''}" Berhasil`)
      setTimeout(function () {
        window.location.href = '/admin/kategori-kegiatan'
      }, 1000)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  activityCategorys: state => {
    return state.activityCategorys
  },
  activityCategorysFilter: state => {
    return state.activityCategorysFilter
  },
  activityCategoryDetail: state => {
    return state.activityCategoryDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  getDeleteActivityCategoryMsg: state => {
    return state.deleteActivityCategory
  },
  modalAdd: state => {
    return state.modalAdd
  },
  modalEdit: state => {
    return state.modalEdit
  },
  getAddActivityCategoryMsg: state => {
    return state.addActivityCategory
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
