import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_USER } from '@/utils/api-url'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'


const state = {
  roles: {meta:{},data:[]},
  meta: {page:1,limit:10},
  user: {},
  logHistoryUser: {meta:{},data:[]},
  metaLogHistoryUser: {page:1,limit:10},
  users: {meta:{},data:[]},
  userCageStaffs: {meta:{},data:[]},
  userFieldSupervisors: {meta:{},data:[]},
  metaUser: {page:1,limit:10},
  metaUserFieldSupervisor: {page:1,limit:10,role:'PPL',status:true},
  modalDelete: {},
  modalEdit: {},
  modalProfil: {},
  modalAdd: {},
  modalChange: {},
  modalShowPassword: {},
  modalShowNotif: {},
  updateResponse: {},
  deleteUser: {},
  limitNotif: {}
}

const mutations = {
  "SET_ROLES": (state, payload) => {
    Vue.set(state, 'roles', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_USERS": (state, payload) => {
    Vue.set(state, 'users', payload)
  },
  "SET_USER_CAGE_STAFFS": (state, payload) => {
    Vue.set(state, 'userCageStaffs', payload)
  },
  "SET_USER_FIELD_SUPERVISORS": (state, payload) => {
    Vue.set(state, 'userFieldSupervisors', payload)
  },
  "SET_USER": (state, payload) => {
    Vue.set(state, 'user', payload)
  },
  "SET_META_USER": (state, payload) => {
    Vue.set(state, 'metaUser', payload)
  },
  "SET_META_USER_FIELD_SUPERVISOR": (state, payload) => {
    Vue.set(state, 'metaUserFieldSupervisor', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteUser', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_PROFIL": (state, payload) => {
    Vue.set(state, 'modalProfil', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_CHANGE": (state, payload) => {
    Vue.set(state, 'modalChange', payload)
  },
  "SET_MODAL_SHOW_PASSWORD": (state, payload) => {
    Vue.set(state, 'modalShowPassword', payload)
  },
  "SET_MODAL_SHOW_NOTIFICATION": (state, payload) => {
    Vue.set(state, 'modalShowNotif', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateResponse', payload)
  },
  "SET_LOG_HISTORY_USER": (state, payload) => {
    Vue.set(state, 'logHistoryUser', payload)
  },
  "SET_META_LOG_HISTORY_USER": (state, payload) => {
    Vue.set(state, 'metaLogHistoryUser', payload)
  },
  "SET_IS_READ_NOTIFICATION": (state, payload) => {
    Vue.set(state, 'isReadNotification', payload)
  },
  "SET_DATA_EXPORT": (state, payload) => {
    Vue.set(state, 'dataExports', payload)
  },
  "SET_LIMIT_NOTIF": (state, payload) => {
    Vue.set(state, 'limitNotif', payload)
  }

}

const actions = {
  async getRole(context, data) {
    try {
      const response = await apiGetAuth(API_USER.ROLES, data)
      context.commit('SET_ROLES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getUser(context, data) {
    try {
      const response = await apiGetAuth(API_USER.USERS, data)
      context.commit('SET_USERS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getUserCageStaff(context, data) {
    try {
      const response = await apiGetAuth(API_USER.USERS, data)
      context.commit('SET_USER_CAGE_STAFFS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getUserFieldSupervisor(context, data) {
    try {
      const response = await apiGetAuth(API_USER.USERS, data)
      context.commit('SET_USER_FIELD_SUPERVISORS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getUserDetail(context, data) {
    try {
      const response = await apiGetAuth(API_USER.DETAIL(data))
      const userDetail = response.data.data.data.user
      const userOuterData = response.data.data
      context.commit('SET_USER', userDetail)
      context.commit('SET_LOG_HISTORY_USER', userOuterData)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteUser(context, data) {
    try {
      const response = await apiDeleteAuth(API_USER.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
      notificationSuccess(`Hapus User "${response.data.data.name || ''}" Berhasil`)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveUser(context, data) {
    try {
      const response = await apiPostAuth(API_USER.ADD, data)
      notificationSuccess(`Tambah User "${response.data.data.name || ''}" Berhasil`)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editUser(context, data) {
    try {
      const response = await apiPutAuth(API_USER.UPDATE, data)
      notificationSuccess(`Ubah Data "${response.data.data.name || ''}" Berhasil`)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editProfil(context, data) {
    try {
      const response = await apiPutAuth(API_USER.UPDATE_PROFIL, data)
      context.commit('SET_UPDATE_RESPONSE', response.data.data)
      notificationSuccess(`Ubah Data "${response.data.data.name || ''}" Berhasil`)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async checkUser(context, data) {
    try {
      await apiPostAuth(API_USER.CHECK, {
        password: data
      })
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async changePassword(context, data) {
    try {
      await apiPutAuth(API_USER.CHANGE, data)
      notificationSuccess("Ganti Password Berhasil")
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async listNotifications(context, data) {
    try {
      const response = await apiGetAuth(API_USER.NOTIFICATIONS, data)
      context.commit('SET_MODAL_SHOW_NOTIFICATION', response.data.data.data)
      context.commit('SET_LIMIT_NOTIF', response.data.data.meta.total_count)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async isRead(context, data) {
    try {
      const response = await apiGetAuth(API_USER.NOTIFICATIONS_STATUS, data)
      context.commit('SET_IS_READ_NOTIFICATION', response.data.data.notification_status)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async exportNotifications(context, data) {
    try {
      const response = await apiGetAuth(API_USER.NOTIFICATIONS_EXPORT, data)
      context.commit('SET_DATA_EXPORT', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err}
  }
}

const getters = {
  roles: state => {
    return state.roles
  },
  meta: state => {
    return state.meta
  },
  users: state => {
    return state.users
  },
  userCageStaffs: state => {
    return state.userCageStaffs
  },
  userFieldSupervisors: state => {
    return state.userFieldSupervisors
  },
  user: state => {
    return state.user
  },
  metaUser: state => {
    return state.metaUser
  },
  metaUserFieldSupervisor: state => {
    return state.metaUserFieldSupervisor
  },
  modalDelete: state => {
    return state.modalDelete
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalProfil: state => {
    return state.modalProfil
  },
  modalAdd: state => {
    return state.modalAdd
  },
  modalChange: state => {
    return state.modalChange
  },
  modalShowPassword: state => {
    return state.modalShowPassword
  },
  modalShowNotif: state => {
    return state.modalShowNotif
  },
  updateResponse: state => {
    return state.updateResponse
  },
  getDeleteUserMsg: state => {
    return state.deleteUser
  },
  logHistoryUser: state => {
    return state.logHistoryUser
  },
  metaLogHistoryUser: state => {
    return state.metaLogHistoryUser
  },
  isReadNotification: state => {
    return state.isReadNotification
  },
  dataExports: state => {
    return state.dataExports
  },
  limitNotif: state => {
    return state.limitNotif
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
