const BASE_URL = `${process.env.VUE_APP_API_URL}/dashboard`
const PLAIN_URL = `${process.env.VUE_APP_API_URL}`
// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL}/auth/login`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  FORGOT: (data) => `${BASE_URL}/user/forget_pass/${data.email}`,
  CHANGE: (data) => `${BASE_URL}/user/change_pass/${data.id}`
}

// City
export const API_CITY = {
  LIST: `${BASE_URL}/city`
}

// Input Type
export const API_INPUT_TYPE = {
  LIST: `${BASE_URL}/input-type`
}

// Farm
export const API_FARM = {
  LIST: `${BASE_URL}/farm`,
  DETAIL: (farm_id) => `${BASE_URL}/farm/${farm_id}`,
  DELETE: (farm_id) => `${BASE_URL}/farm/one/${farm_id}`,
  ADD: `${BASE_URL}/farm/add`,
  UPDATE: `${BASE_URL}/farm`
}

// Cage
export const API_CAGE = {
  LIST: `${BASE_URL}/cage`,
  LIST_BY_FARM: `${BASE_URL}/cage/list-by-farm`,
  DETAIL: (id) => `${BASE_URL}/cage/detail/${id}`,
  DELETE: (id) => `${BASE_URL}/cage/${id}`,
  ADD: `${BASE_URL}/cage/add`,
  UPDATE: `${BASE_URL}/cage`,
  IMPORT: `${BASE_URL}/cage/upload`,
  LOG_HISTORY: `${BASE_URL}/cycle/history-update`,
  DIGITAL_CONCENT: (id) => `${BASE_URL}/cage/digital-consent/${id}`,
  EXPORT_DIGITAL_CONCENT: (id) => `${BASE_URL}/cage/digital-consent-pdf/${id}`,
  BULK_IMPORT_CAGE: `${BASE_URL}/cage/import-cage`
}

// Sapronak
export const API_SAPRONAK = {
  LIST: `${BASE_URL}/third-party/ukirama/get/stock-transfers`,
  DETAIL: (id) => `${BASE_URL}/third-party/ukirama/get/stock-transfers/detail/${id}`,
  TABLE_DETAIL: `${BASE_URL}/third-party/ukirama/get/stock-transfer/items`,
  SYNC: `${BASE_URL}/third-party/ukirama/sync/stock-transfers`,
  CHECK_SYNC_STATUS: `${BASE_URL}/third-party/ukirama/check-status`
}

// Branch
export const API_BRANCH = {
  LIST: `${BASE_URL}/branch`,
  DETAIL: (id) => `${BASE_URL}/branch/get/${id}`,
  CREATE: `${BASE_URL}/branch/add`,
  UPDATE: `${BASE_URL}/branch/update`
}

// User
export const API_USER = {
  ROLES: `${BASE_URL}/role/list`,
  USERS: `${BASE_URL}/user/list`,
  DETAIL: (user_id) => `${BASE_URL}/user/get/${user_id}`,
  DELETE: (user_id) => `${BASE_URL}/user/delete/${user_id}`,
  ADD: `${BASE_URL}/user/add`,
  UPDATE: `${BASE_URL}/user/update`,
  UPDATE_PROFIL: `${BASE_URL}/user/update-profile`,
  CHECK: `${BASE_URL}/user/check-password`,
  CHANGE: `${BASE_URL}/user/change-password`,
  NOTIFICATIONS: `${BASE_URL}/user/log-history-notification/list`,
  NOTIFICATIONS_STATUS: `${BASE_URL}/user/log-history-notification/status`,
  NOTIFICATIONS_EXPORT: `${BASE_URL}/user/log-history-notification/export`
}

// Role
export const API_ROLE = {
  LIST: `${BASE_URL}/role/list`,
  DETAIL: (id) => `${BASE_URL}/role/one/${id}`,
  DELETE: (id) => `${BASE_URL}/role/delete/${id}`,
  ADD: `${BASE_URL}/role/store`,
  UPDATE: (id) => `${BASE_URL}/role/update/${id}`
}

// Setting
export const API_SETTING = {
  DETAIL: `${BASE_URL}/app-version`,
  UPDATE:(id) => `${BASE_URL}/app-version/${id}`
}

// Chicken Category
export const API_CHICKEN_CATEGORY = {
  LIST: `${BASE_URL}/chicken-category`,
  DETAIL: (id) => `${BASE_URL}/chicken-category/${id}`,
  IMPORT: `${BASE_URL}/chicken-category/upload`
}

// Cage Category
export const API_CAGE_CATEGORY = {
  LIST: `${BASE_URL}/cage-category`,
  DETAIL: (id) => `${BASE_URL}/cage-category/${id}`,
  DELETE: (id) => `${BASE_URL}/cage-category/${id}`,
  IMPORT: `${BASE_URL}/cage-category/upload`,
  ADD: `${BASE_URL}/cage-category/store`,
  UPDATE: (id) => `${BASE_URL}/cage-category/update/${id}`
}

// Activity Cage
export const API_ACTIVITY_CAGE = {
  LIST: `${BASE_URL}/log-cycle`,
  DETAIL: (id) => `${BASE_URL}/log-cycle/${id}`,
  DELETE: (id) => `${BASE_URL}/log-cycle/${id}`,
  ADD: `${BASE_URL}/log-cycle/add`,
  UPDATE: (id) =>  `${BASE_URL}/log-cycle/${id}`,
  IMPORT: `${BASE_URL}/log-cycle/upload`
}

// Activity
export const API_ACTIVITY = {
  LIST: `${BASE_URL}/activity`,
  DETAIL: (id) => `${BASE_URL}/activity/${id}`,
  DELETE: (id) => `${BASE_URL}/activity/${id}`,
  IMPORT: `${BASE_URL}/activity/upload`,
  ADD: `${BASE_URL}/activity/store`,
  UPDATE: (id) => `${BASE_URL}/activity/update/${id}`
}

// Activity Log
export const API_ACTIVITY_LOG = {
  LIST: (id) => `${BASE_URL}/log-activity/${id}`,
  LIST_PAGE: `${BASE_URL}/log-activity`,
  LIST_PAGE_WITH_DETAIL: (id) => `${BASE_URL}/log-activity/${id}/detail`
}

// Activity Log Detail
export const API_ACTIVITY_LOG_DETAIL = {
  LIST: `${BASE_URL}/log-detail-activity`,
  DELETE: (id) => `${BASE_URL}/log-detail-activity/${id}`
}

// Activity Log Detail Master
export const API_ACTIVITY_DETAIL_MASTER = {
  LIST_ALL: `${BASE_URL}/master-log-detail-activity/list-all`
}

// Activity Category
export const API_ACTIVITY_CATEGORY = {
  LIST: `${BASE_URL}/activity-category`,
  DETAIL: (id) => `${BASE_URL}/activity-category/${id}`,
  DELETE: (id) => `${BASE_URL}/activity-category/${id}`,
  IMPORT: `${BASE_URL}/activity-category/upload`,
  ADD: `${BASE_URL}/activity-category/store`,
  UPDATE: (id) => `${BASE_URL}/activity-category/update/${id}`
}

// Sub Activity Category
export const API_SUB_ACTIVITY_CATEGORY = {
  LIST: `${BASE_URL}/sub-activity-category`,
  DETAIL: (id) => `${BASE_URL}/sub-activity-category/${id}`,
  DELETE: (id) => `${BASE_URL}/sub-activity-category/${id}`,
  IMPORT: `${BASE_URL}/sub-activity-category/upload`,
  ADD: `${BASE_URL}/sub-activity-category/store`,
  UPDATE: (id) => `${BASE_URL}/sub-activity-category/update/${id}`
}

// Cycle
export const API_CYCLE = {
  LIST_CYCLE: (id) => `${BASE_URL}/cycle/by-cage/${id}/v2`,
  EDIT_CYCLE: `${BASE_URL}/cycle/update`,
  LIST_CYCLE_HISTORY: (id) => `${BASE_URL}/cycle/history-by-cage/${id}`,
  DAILY_RESUME: `${BASE_URL}/cycle/daily-resume-v2`,
  GRAPH_CYCLE: (id) => `${BASE_URL}/cycle/graph/${id}`,
  GRAPH_CYCLE_DASHBOARD: `${BASE_URL}/cycle/all/cage`,
  CYCLE_NO_LIMIT: (param) => `${BASE_URL}/cycle/by-cage/${param.cage_id}/no-limit?log_cycle_id=${param.log_cycle_id}`
}

// Detail Activity Category
export const API_DETAIL_ACTIVITY_CATEGORY = {
  LIST: `${BASE_URL}/detail-activity-category`,
  DETAIL: (id) => `${BASE_URL}/detail-activity-category/${id}`,
  DELETE: (id) => `${BASE_URL}/detail-activity-category/${id}`,
  IMPORT: `${BASE_URL}/detail-activity-category/upload`,
  ADD: `${BASE_URL}/detail-activity-category/store`,
  UPDATE: (id) => `${BASE_URL}/detail-activity-category/update/${id}`
}

// Kategori SOP
export const API_CATEGORY_SOP = {
  LIST: `${BASE_URL}/sop-category`,
  ADD: `${BASE_URL}/sop-category/add`,
  DETAIL: (id) => `${BASE_URL}/sop-category/${id}`,
  DELETE: (id) => `${BASE_URL}/sop-category/${id}`,
  IMPORT: `${BASE_URL}/sop-category/upload`,
  UPDATE: (id) => `${BASE_URL}/sop-category/update/${id}`
}

// Detail SOP
export const API_DETAIL_SOP = {
  LIST: `${BASE_URL}/detail-sop`,
  DETAIL: (id) => `${BASE_URL}/detail-sop/${id}`,
  DELETE: (id) => `${BASE_URL}/detail-sop/${id}`,
  IMPORT: `${BASE_URL}/detail-sop/upload`,
  ADD: `${BASE_URL}/detail-sop/store`,
  UPDATE: (id) => `${BASE_URL}/detail-sop/update/${id}`
}

// Permintaan Panen
export const API_HARVEST_REQUEST = {
  LIST: `${BASE_URL}/third-party/ukirama/get/sales-shipments`,
  DETAIL: (id) => `${BASE_URL}/third-party/ukirama/get/sales-shipments/detail/${id}`,
  SYNC: `${BASE_URL}/third-party/ukirama/sync/sales-shipments`
}

// Sync Item
export const API_SYNC_ITEM = {
  LIST: `${BASE_URL}/third-party/ukirama/sync/items`
}

// UKIRAMA (Merk DOC, Feed Category & OVK Category)
export const API_UKIRAMA = {
  LIST: `${BASE_URL}/third-party/ukirama/get/items`,
  DETAIL: (id) => `${BASE_URL}/third-party/ukirama/get/items/detail/${id}`,
  PRICE: `${BASE_URL}/third-party/ukirama/get/vendor/prices`
}

// CONTRACT PRICE
export const API_PRICE_LIST = {
  LIST: `${BASE_URL}/contract-price`,
  DETAIL: (id) => `${BASE_URL}/contract-price/get/${id}`,
  UPDATE: `${BASE_URL}/contract-price/store`,
  PRICE_BY_CHICKIN: `${BASE_URL}/contract-price/contract-price-effective-date`
}

// Vendor
export const API_VENDOR = {
  LIST: `${BASE_URL}/third-party/ukirama/get/vendors`,
  SYNC: `${BASE_URL}/third-party/ukirama/sync/vendors`
}

// Rhpp
export const API_RHPP = {
  LIST: `${BASE_URL}/rhpp/list`,
  LIST_LOG_CYCLE: `${BASE_URL}/log-cycle`,
  LIST_SAPRONAK: `${BASE_URL}/third-party/ukirama/get/stock-transfer/items`,
  LIST_RESULT_PRODUCTION: `${BASE_URL}/third-party/ukirama/get/sales-shipments`,
  DETAIL: (id) => `${BASE_URL}/rhpp/detail/${id}`,
  ADD: `${BASE_URL}/rhpp/store`,
  CALCULATE: `${BASE_URL}/rhpp/calculate`,
  DOWNLOAD_PDF: (id) => `${BASE_URL}/rhpp/pdf/${id}`,
  APPROVAL: `${BASE_URL}/rhpp/approval`,
  UPDATE: `${BASE_URL}/rhpp/update`,
  DELETE: (id) => `${BASE_URL}/rhpp/delete/${id}`,
  UPLOAD_DOC_FILE: (item) => `${BASE_URL}/rhpp/detail/${item}/doc`,
  UPLOAD_OVK_FILE: (item) => `${BASE_URL}/rhpp/detail/${item}/ovk`,
  UPLOAD_FEED_FILE: (item) => `${BASE_URL}/rhpp/detail/${item}/feed`,
  UPLOAD_WEIGHING_FILE: (item) => `${BASE_URL}/rhpp/detail/${item}/weighing`
}

// Acquisition
export const API_ACQUISITION = {
  LIST: `${BASE_URL}/acquisition/list`,
  LIST_FOR_CAGE: (id) => `${BASE_URL}/acquisition/list-for-cage/${id}`,
  CREATE: `${BASE_URL}/acquisition/store`,
  UPDATE: (id) =>`${BASE_URL}/acquisition/update/${id}`,
  DETAIL: (id) => `${BASE_URL}/acquisition/detail/${id}`,
  DELETE: (id) => `${BASE_URL}/acquisition/delete/${id}`,
  PROSPECT: (id) => `${BASE_URL}/acquisition/status/prospect/${id}`,
  POTENTIAL: (id) => `${BASE_URL}/acquisition/status/potential/${id}`,
  POTENTIAL_SURVEY: `${BASE_URL}/acquisition/status/potential/survey`,
  POTENTIAL_SURVEY_UPDATE: (id) => `${BASE_URL}/acquisition/status/potential/${id}/survey`,
  POTENTIAL_VERIFY_SURVEY: (id) => `${BASE_URL}/acquisition/status/potential/${id}/survey-approved`,
  DETAIL_STATUS: (id) => `${BASE_URL}/acquisition/status/detail/${id}`,
  DETAIL_SURVEY: (id) => `${BASE_URL}/acquisition/status/survey/${id}`,
  SUBMIT_VERIFICATION: `${BASE_URL}/acquisition/status/verification`,
  SUBMIT_VERIFICATION_EDIT: (id) => `${BASE_URL}/acquisition/status/verification/update/${id}`,
  SUBMIT_VERIFICATION_VERIFY_DOCUMENT: (id) => `${BASE_URL}/acquisition/status/verification/verified/${id}`,
  STATUS_ACQUISITION: `${BASE_URL}/acquisition/status/verification/verified`,
  UPLOAD_SPK_PLAN: (id) => `${BASE_URL}/acquisition/status/farmer/${id}/spk-planning`,
  UPLOAD_SPK: (id) => `${BASE_URL}/acquisition/status/farmer/${id}/upload-spk`,
  LIST_INSTALLMENT: `${BASE_URL}/administration/list/installment`,
  DOWNLOAD_RECEIPT: (id) => `${BASE_URL}/administration/installment/receipt/${id}`,
  STORE_FACILITY: `${BASE_URL}/acquisition/status/facility`,
  UPDATE_FACILITY: (id) => `${BASE_URL}/acquisition/status/facility/update/${id}`,
  GUARANTEE_FUND: (id) => `${BASE_URL}/acquisition/guarantee-fund?acquisition_id=${id}`,
  STATUS_ACQUISITION_LISTS: `${BASE_URL}/acquisition/status/list-status`,
  LISTS_NOT_INTERESTED: `${BASE_URL}/acquisition/reason/prospect/not-intrested`,
  LISTS_NOT_WORTHY: `${BASE_URL}/acquisition/reason/potential/not-eligible`,
  LIST_WATER_RESOURCES:`${BASE_URL}/acquisition/list/water-resources`,
  LIST_CAGE_ACCESS:`${BASE_URL}/acquisition/list/access-cage-location`,
  RETIRE: (id) =>  `${BASE_URL}/acquisition/resign/${id}`,
  SPK_FILE: `${PLAIN_URL}/static/acquisition/spk/SPK-Mitra.pdf`,
  BULK_IMPORT: (id) =>  `${BASE_URL}/acquisition/import/${id}`
}

// Acquisition Dashboard
export const API_ACQUISITION_DASHBAORD = {
  TOTAL_STATUS: `${BASE_URL}/acquisition/dashboard/status/total`,
  STATUS_MITRA: `${BASE_URL}/acquisition/dashboard/status-follow-up/total`,
  ACQUISITION_RATE: `${BASE_URL}/acquisition/dashboard/acquisition-rate/total`,
  MAPPING_AREA: `${BASE_URL}/acquisition/dashboard/city/total`,
  MAPPING_AREA_CHLOROPLETH_DATASET: `${BASE_URL}/acquisition/dashboard/mapping-area/total`,
  REASON_FAILED: `${BASE_URL}/acquisition/dashboard/acquisition-reason-failed/total`,
  CLOSING_RATE: `${BASE_URL}/acquisition/dashboard/closing-rate-acquisition/total`
}
