<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-flex uk-flex-center uk-flex-middle">
          <h4 class="uk-margin-remove title label-green">
            Top 5 Reason Acquisition Fail
          </h4>
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <div class="section-progress">
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Kendala Pada Modal
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.kendala_pada_modal) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-fund uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.kendala_pada_modal)"
                  max="100"
                />
              </div>
            </div>
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Tanah Tidak Memenuhi Persyaratan
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.tanah_tidak_memenuhi_persyaratan) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-land uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.tanah_tidak_memenuhi_persyaratan)"
                  max="100"
                />
              </div>
            </div>
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Tidak Percaya Program Paranje
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.tidak_percaya_program_paranje) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-believe uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.tidak_percaya_program_paranje)"
                  max="100"
                />
              </div>
            </div>
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Sumber Daya Listrik & Air
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.sumber_daya_listrik_dan_air) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-resource uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.sumber_daya_listrik_dan_air)"
                  max="100"
                />
              </div>
            </div>
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Lainnya
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.lainnya) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-other uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.lainnya)"
                  max="100"
                />
              </div>
            </div>
            <div
              class="uk-text-center uk-margin-top"
            >
              <div class="uk-width-1-1">
                <div class="uk-flex uk-flex-start uk-flex-middle">
                  <h4 class="uk-text-left uk-width-1-1 uk-margin-remove">
                    Withdrawal - Mengundurkan Diri
                  </h4>
                  <h4 class="uk-text-right uk-width-1-6 uk-margin-remove">
                    {{ handlePercentage(dataPercentage.withdrawal) }}%
                  </h4>
                </div>
              </div>
              <div class="uk-width-1-1 uk-margin-top">
                <progress
                  class="uk-progress progress-fail-retire uk-margin-remove-bottom"
                  :value="handlePercentage(dataPercentage.withdrawal)"
                  max="100"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { float0Decimals } from '@/utils/formater'
import ComponentLoading from '@/components/globals/loading'

export default {
  components: {
    ComponentLoading
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isLoading: true,
      dataPercentage:{}
    }
  },
  computed: {
    ...mapGetters({
      dataReasonFailed: 'acquisitionDashboard/dataReasonFailed',
      dataMeta: 'acquisitionDashboard/dataMeta'
    })
  },
  watch: {
    dataReasonFailed() {
      if (this.dataReasonFailed) {
        this.setDataStatusAndPercentage() 
      }
    }
  },
  methods: {
    handlePercentage(value) {
      return float0Decimals(value)
    },
    setDataStatusAndPercentage() {
      if (this.dataReasonFailed) {
        this.dataPercentage = this.dataReasonFailed || {}
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .wrapper-status {
    padding: 16px;
  }

  .wrapper-status h4 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #1F2E28;
  }

  .wrapper-status .section-progress h4 {
    font-family: 'ClanOTMedium';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1F2E28;
  }

  .wrapper-status .title {
    font-size: 20px;
    font-weight: 700;
  }

  .progress-fail-fund::-webkit-progress-value {
    background: rgba(44, 217, 197, 1) !important;
    border-radius: 10px;
    z-index: 1;
  }
  
  .progress-fail-land::-webkit-progress-value {
    background: rgba(45, 153, 255, 1) !important;
    border-radius: 10px;
  }
  .progress-fail-believe::-webkit-progress-value {
    background: rgba(130, 106, 249, 1) !important;
    border-radius: 10px;
  }
  .progress-fail-resource::-webkit-progress-value {
    background: rgba(255, 231, 0, 1) !important;
    border-radius: 10px;
  }
  .progress-fail-other::-webkit-progress-value {
    background: rgba(0, 68, 40, 1) !important;
    border-radius: 10px;
  }
  .progress-fail-retire::-webkit-progress-value {
    background: rgba(249, 38, 38, 1) !important;
    border-radius: 10px;
  }
  .uk-progress::-webkit-progress-bar {
    background: transparent;
  }
</style>
