import { render, staticRenderFns } from "./Ytfu-Acquisition.vue?vue&type=template&id=363a5206&scoped=true"
import script from "./Ytfu-Acquisition.vue?vue&type=script&lang=js"
export * from "./Ytfu-Acquisition.vue?vue&type=script&lang=js"
import style0 from "./Ytfu-Acquisition.vue?vue&type=style&index=0&id=363a5206&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "363a5206",
  null
  
)

export default component.exports