<template>
  <div class="uk-margin-top">
    <div
      v-if="!isCanAccessUser(`view`, `Dashboard`)"
      class="uk-card uk-card-default uk-padding-small kandang-card"
    >
      <div
        class="uk-grid-small"
        uk-grid
      >
        <div class="uk-width-2-3">
          <div>Dashboard</div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="uk-card uk-card-default kandang-card"
    >
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <p>
            Tampilan : <b>{{ isBtnView ? 'List' : 'Grid' }}</b>
          </p>
        </div>
        <div
          v-if="screenWidth"
          class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl uk-flex uk-flex-right"
        >
          <button
            :class="[isBtnView ? 'uk-button-primary' : 'uk-button-default', {'uk-button btn-list': true}]"
            @click="isBtnView = true"
          >
            <img
              v-lazy="`${images}/icon/${isBtnView ? 'list-white' : 'list-green'}.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            List
          </button>
          <button
            :class="[isBtnView ? 'uk-button-default' : 'uk-button-primary', {'uk-button btn-grid': true}]"
            @click="isBtnView = false"
          >
            <img
              v-lazy="`${images}/icon/${isBtnView ? 'grid-green' : 'grid-white'}.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Grid
          </button>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-margin-auto-left">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l uk-first-column">
              <label class="uk-form-label filter-title">Cabang</label>
              <div class="uk-form-controls ">
                <multiselect 
                  id="branch"
                  v-model="branchName" 
                  label="name"
                  name="branch_name" 
                  track-by="name" 
                  placeholder="Semua" 
                  open-direction="bottom" 
                  :options="dataBranches" 
                  :searchable="true" 
                  :loading="isLoading"
                  :close-on-select="true" 
                  :options-limit="10"
                  :max-height="500" 
                  :show-no-results="true"
                  @select="onSelectBranch"
                  @remove="onRemoveBranch"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
              </div>
            </div>
            <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
              <label class="uk-form-label filter-title">PPL</label>
              <div class="uk-form-controls">
                <multiselect 
                  id="supervisor"
                  v-model="supervisorName" 
                  label="name"
                  name="supervisor_name" 
                  track-by="code" 
                  placeholder="Semua" 
                  open-direction="bottom" 
                  :options="dataFieldSupervisors" 
                  :searchable="true" 
                  :loading="isLoading"
                  :close-on-select="true"
                  :options-limit="10"
                  :max-height="500" 
                  :show-no-results="true"
                  @select="onSelectPPL"
                  @remove="onRemovePPL"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-grid"
        style="margin-top: 0;"
      >
        <div :class="isBtnView ? 'uk-width-1' : 'uk-width-1-2'">
          <div class="small">
            <h3 class="title-chart">
              Daily Intake
            </h3>
            <hr>
            <div style="overflow-x: auto;">
              <bar-chart
                :chart-data="dataPointFeed"
                :options="barLineOptionPointFeed"
              />
            </div>
            <div
              v-if="!isBtnView && pointFeedLength > 20"
              class="uk-flex uk-flex-center uk-flex-top uk-margin-small-top uk-margin-small-bottom"
            >
              <button
                class="next-btn uk-margin-small-right"
                :disabled="pointFeedPrevDisabled"
                @click="prevButton('point_feed')"
              >
                Prev
              </button>
              <button
                class="next-btn"
                :disabled="pointFeedNextDisabled"
                @click="nextButton('point_feed')"
              >
                Next
              </button>
            </div>
            <div class="uk-flex uk-flex-left">
              <div class="standard-dot" />
              <span class="standard-text">Standard</span>
            </div>
          </div>
          <div class="small">
            <h3 class="title-chart">
              Body Weight
            </h3>
            <hr>
            <bar-chart
              :chart-data="dataBodyWeight"
              :options="barLineOptionBodyWeight"
            />
            <div
              v-if="!isBtnView && bodyWeightLength > 20"
              class="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom"
            >
              <button
                class="next-btn uk-margin-small-right"
                :disabled="bodyWeightPrevDisabled"
                @click="prevButton('body_weight')"
              >
                Prev
              </button>
              <button
                class="next-btn"
                :disabled="bodyWeightNextDisabled"
                @click="nextButton('body_weight')"
              >
                Next
              </button>
            </div>
            <div class="uk-flex uk-flex-left">
              <div class="standard-dot" />
              <span class="standard-text">Standard</span>
            </div>
          </div>
        </div>
        <div :class="isBtnView ? 'uk-width-1' : 'uk-width-1-2'">
          <div class="small">
            <h3 class="title-chart">
              Feed Intake
            </h3>
            <hr>
            <bar-chart
              :chart-data="dataFeedIntake"
              :options="barLineOptionFeedIntake"
            />
            <div
              v-if="!isBtnView && feedIntakeLength > 20"
              class="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom"
            >
              <button
                class="next-btn uk-margin-small-right"
                :disabled="feedIntakePrevDisabled"
                @click="prevButton('feed_intake')"
              >
                Prev
              </button>
              <button
                class="next-btn"
                :disabled="feedIntakeNextDisabled"
                @click="nextButton('feed_intake')"
              >
                Next
              </button>
            </div>
            <div class="uk-flex uk-flex-left">
              <div class="standard-dot" />
              <span class="standard-text">Standard</span>
            </div>
          </div>
          <div class="small">
            <h3 class="title-chart">
              Feed Conversion Ratio (FCR)
            </h3>
            <hr>
            <bar-chart
              :chart-data="dataFCR"
              :options="barLineOptionFCR"
            />
            <div
              v-if="!isBtnView && fcrLength > 20"
              class="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom"
            >
              <button
                class="next-btn uk-margin-small-right"
                :disabled="fcrPrevDisabled"
                @click="prevButton('fcr')"
              >
                Prev
              </button>
              <button
                class="next-btn"
                :disabled="fcrNextDisabled"
                @click="nextButton('fcr')"
              >
                Next
              </button>
            </div>
            <div class="uk-flex uk-flex-left">
              <div class="standard-dot" />
              <span class="standard-text">Standard</span>
            </div>
          </div>
        </div>
        <div :class="isBtnView ? 'uk-width-1' : 'uk-width-1-2'">
          <div class="small">
            <h3 class="title-chart">
              Indeks Prestasi (IP)
            </h3>
            <hr>
            <bar-chart
              :chart-data="dataIP"
              :options="barLineOptionIP"
            />
            <div
              v-if="!isBtnView && ipLength > 20"
              class="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom"
            >
              <button
                class="next-btn uk-margin-small-right"
                :disabled="ipPrevDisabled"
                @click="prevButton('ip')"
              >
                Prev
              </button>
              <button
                class="next-btn"
                :disabled="ipNextDisabled"
                @click="nextButton('ip')"
              >
                Next
              </button>
            </div>
            <div class="uk-flex uk-flex-left">
              <div class="standard-dot" />
              <span class="standard-text">Standard</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import BarChart from '@/components/globals/chart/BarLineChart.js'
import { float3Decimals, generateColor } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    BarChart
  },
  data () {
    return {
      images: PREFIX_IMAGE,
      isLoading: false,
      isBtnView: true,
      isDisabledSelect: true,
      dataPointFeed: null,
      dataFeedIntake: null,
      dataBodyWeight: null,
      dataFCR: null,
      dataIP: null,
      barLineOptionPointFeed: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionFeedIntake: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionBodyWeight: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionFCR: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptionIP: {title:{},scales:{yAxes:[{ticks:{}}]}},
      barLineOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              max: 200,
              min: 0,
              stepSize: 20
            }
          }]
        },
        title: {
          display: false
        },
        legend: {
          display: false
        }
      },
      branchName: '',
      supervisorName:'',
      dataBranches: [],
      dataFieldSupervisors: [],
      branch_id: '',
      ppl_id: '',
      dataDashboard: [],
      pointFeedPrevDisabled: true,
      pointFeedNextDisabled: false,
      bodyWeightPrevDisabled: true,
      bodyWeightNextDisabled: false,
      feedIntakePrevDisabled: true,
      feedIntakeNextDisabled: false,
      fcrPrevDisabled: true,
      fcrNextDisabled: false,
      ipPrevDisabled: true,
      ipNextDisabled: false,
      pointFeedLength: null,
      feedIntakeLength: null,
      bodyWeightLength: null,
      fcrLength: null,
      ipLength: null,
      pointFeedStart: 0,
      pointFeedEnd: 20,
      bodyWeightStart: 0,
      bodyWeightEnd: 20,
      feedIntakeStart: 0,
      feedIntakeEnd: 20,
      fcrStart: 0,
      fcrEnd: 20,
      ipStart: 0,
      ipEnd: 20,
      screenWidth: false
    }
  },
  computed: {
    ...mapGetters({
      cycles_graph_dashboard: 'cycle/cycles_graph_dashboard',
      branches: 'branch/branches',
      userFieldSupervisors: 'user/userFieldSupervisors'
    })
  },
  watch: {
  },
  async mounted() {
    await Promise.all([
      this.getCycleGraphDashboard(),
      this.getBranch(),
      this.getUserFieldSupervisor({ role: 'PPL' })
    ])

    this.generatedGrafikData()
    this.handleWidthScreen(screen.width)
    this.dataBranches = this.branches ? this.branches.data ? this.branches.data : [] : []
    this.dataFieldSupervisors = this.userFieldSupervisors ? this.userFieldSupervisors.data ? this.userFieldSupervisors.data : [] : []
  },
  methods: {
    ...mapActions({
      getCycleGraphDashboard: 'cycle/getCycleGraphDashboard',
      getBranch: 'branch/getBranch',
      getUserFieldSupervisor: 'user/getUserFieldSupervisor'
    }),
    ...mapMutations({
    }),
    handleWidthScreen(value) {
      this.screenWidth = value >= 820
    },
    prevButton (type) {
      switch (type) {
      case 'point_feed':
        this.pointFeedNextDisabled = false
        if (this.pointFeedStart <= 0) {
          this.pointFeedStart = 0
          this.pointFeedEnd = 20
          this.generatedGrafikData()
          this.pointFeedPrevDisabled = true
        } else {
          this.pointFeedStart = this.pointFeedStart - 20
          this.pointFeedEnd = this.pointFeedEnd - 20
          this.generatedGrafikData()
          if (this.pointFeedStart <= 0) {
            this.pointFeedPrevDisabled = true
            this.pointFeedStart = 0
            this.pointFeedEnd = 20
          }
        }
        break
      case 'feed_intake':
        this.feedIntakeNextDisabled = false
        if (this.feedIntakeStart <= 0) {
          this.feedIntakeStart = 0
          this.feedIntakeEnd = 20
          this.generatedGrafikData()
          this.feedIntakePrevDisabled = true
        } else {
          this.feedIntakeStart = this.feedIntakeStart - 20
          this.feedIntakeEnd = this.feedIntakeEnd - 20
          this.generatedGrafikData()
          if (this.feedIntakeStart <= 0) {
            this.feedIntakePrevDisabled = true
            this.feedIntakeStart = 0
            this.feedIntakeEnd = 20
          }
        }
        break
      case 'body_weight':
        this.bodyWeightNextDisabled = false
        if (this.bodyWeightStart <= 0) {
          this.bodyWeightStart = 0
          this.bodyWeightEnd = 20
          this.generatedGrafikData()
          this.bodyWeightPrevDisabled = true
        } else {
          this.bodyWeightStart = this.bodyWeightStart - 20
          this.bodyWeightEnd = this.bodyWeightEnd - 20
          this.generatedGrafikData()
          if (this.bodyWeightStart <= 0) {
            this.bodyWeightPrevDisabled = true
            this.bodyWeightStart = 0
            this.bodyWeightEnd = 20
          }
        }
        break
      case 'fcr':
        this.fcrNextDisabled = false
        if (this.fcrStart <= 0) {
          this.fcrStart = 0
          this.fcrEnd = 20
          this.generatedGrafikData()
          this.fcrPrevDisabled = true
        } else {
          this.fcrStart = this.fcrStart - 20
          this.fcrEnd = this.fcrEnd - 20
          this.generatedGrafikData()
          if (this.fcrStart <= 0) {
            this.fcrPrevDisabled = true
            this.fcrStart = 0
            this.fcrEnd = 20
          }
        }
        break
      case 'ip':
        this.ipNextDisabled = false
        if (this.ipStart <= 0) {
          this.ipStart = 0
          this.ipEnd = 20
          this.generatedGrafikData()
          this.ipPrevDisabled = true
        } else {
          this.ipStart = this.ipStart - 20
          this.ipEnd = this.ipEnd - 20
          this.generatedGrafikData()
          if (this.ipStart <= 0) {
            this.ipPrevDisabled = true
            this.ipStart = 0
            this.ipEnd = 20
          }
        }
        break
      default:
        break
      }
    },
    nextButton (type) {
      switch (type) {
      case 'point_feed':
        this.pointFeedPrevDisabled = false
        if (this.pointFeedEnd >= this.pointFeedLength) {
          this.pointFeedStart = this.pointFeedStart + 20
          this.pointFeedEnd = this.pointFeedLength
          this.generatedGrafikData()
          this.pointFeedNextDisabled = true
        } else {
          this.pointFeedStart = this.pointFeedStart + 20
          this.pointFeedEnd = this.pointFeedEnd + 20
          this.generatedGrafikData()
          if (this.pointFeedEnd >= this.pointFeedLength) {
            this.pointFeedNextDisabled = true
          }
        }
        break
      case 'feed_intake':
        this.feedIntakePrevDisabled = false
        if (this.feedIntakeEnd >= this.feedIntakeLength) {
          this.feedIntakeStart = this.feedIntakeStart + 20
          this.feedIntakeEnd = this.feedIntakeLength
          this.generatedGrafikData()
          this.feedIntakeNextDisabled = true
        } else {
          this.feedIntakeStart = this.feedIntakeStart + 20
          this.feedIntakeEnd = this.feedIntakeEnd + 20
          this.generatedGrafikData()
          if (this.feedIntakeEnd >= this.feedIntakeLength) {
            this.feedIntakeNextDisabled = true
          }
        }
        break
      case 'body_weight':
        this.bodyWeightPrevDisabled = false
        if (this.bodyWeightEnd >= this.bodyWeightLength) {
          this.bodyWeightStart = this.bodyWeightStart + 20
          this.bodyWeightEnd = this.bodyWeightLength
          this.generatedGrafikData()
          this.bodyWeightNextDisabled = true
        } else {
          this.bodyWeightStart = this.bodyWeightStart + 20
          this.bodyWeightEnd = this.bodyWeightEnd + 20
          this.generatedGrafikData()
          if (this.bodyWeightEnd >= this.bodyWeightLength) {
            this.bodyWeightNextDisabled = true
          }
        }
        break
      case 'fcr':
        this.fcrPrevDisabled = false
        if (this.fcrEnd >= this.fcrLength) {
          this.fcrStart = this.fcrStart + 20
          this.fcrEnd = this.fcrLength
          this.generatedGrafikData()
          this.fcrNextDisabled = true
        } else {
          this.fcrStart = this.fcrStart + 20
          this.fcrEnd = this.fcrEnd + 20
          this.generatedGrafikData()
          if (this.fcrEnd >= this.fcrLength) {
            this.fcrNextDisabled = true
          }
        }
        break
      case 'ip':
        this.ipPrevDisabled = false
        if (this.ipEnd >= this.ipLength) {
          this.ipStart = this.ipStart + 20
          this.ipEnd = this.ipLength
          this.generatedGrafikData()
          this.ipNextDisabled = true
        } else {
          this.ipStart = this.ipStart + 20
          this.ipEnd = this.ipEnd + 20
          this.generatedGrafikData()
          if (this.ipEnd >= this.ipLength) {
            this.ipNextDisabled = true
          }
        }
        break
      default:
        break
      }
    },
    generatedGrafikData() {
      this.dataDashboard = this.cycles_graph_dashboard.data ? this.cycles_graph_dashboard.data : []
      // BUILD FOR RESPONSE API DASHBOARD
      const buildBodyWeight = {
        days : [],
        cages: [],
        standard: []
      }
      const buildFcr = {
        days : [],
        cages: [],
        standard: []
      }
      const buildFeedIntake = {
        days : [],
        cages: [],
        standard: []
      }
      const buildPointFeed = {
        days : [],
        cages: [],
        standard: []
      }
      const buildIp = {
        days : [],
        cages: []
      }

      this.dataDashboard.forEach(element => {
        // BODY WEIGHT BUILD
        const cageBodyWeight = {
          text : element.cage_name,
          data : element.data[0].body_weight.y.input ? element.data[0].body_weight.y.input : [],
          fillStyle: generateColor()
        }
        const dayBodyWeight = element.data[0].body_weight.x ? element.data[0].body_weight.x : []
        const stdBodyWeight = element.data[0].body_weight.y.standard ? element.data[0].body_weight.y.standard : []
        buildBodyWeight.cages.push(cageBodyWeight)
        buildBodyWeight.days.push(...dayBodyWeight)
        buildBodyWeight.standard.push(...stdBodyWeight)

        // FCR BUILD
        const cageFcr = {
          text : element.cage_name,
          data : element.data[0].fcr.y.input ? element.data[0].fcr.y.input : [],
          fillStyle: generateColor()
        }
        const dayBodyFcr = element.data[0].fcr.x ? element.data[0].fcr.x : []
        const stdFcr = element.data[0].fcr.y.standard ? element.data[0].fcr.y.standard : []
        buildFcr.cages.push(cageFcr)
        buildFcr.days.push(...dayBodyFcr)
        buildFcr.standard.push(...stdFcr)
        
        // IP BUILD
        const cageIp = {
          text : element.cage_name,
          data : element.data[0].ip.y.input ? element.data[0].ip.y.input : [],
          fillStyle: generateColor()
        }
        const dayBodyIp = element.data[0].ip.x ? element.data[0].ip.x : []
        buildIp.cages.push(cageIp)
        buildIp.days.push(...dayBodyIp)

        // FEED INTAKE BUILD
        const cageFeedIntake = {
          text : element.cage_name,
          data : element.data[0].feed_intake.y.input ? element.data[0].feed_intake.y.input : [],
          fillStyle: generateColor()
        }
        const dayBodyFeedIntake = element.data[0].feed_intake.x ? element.data[0].feed_intake.x : []
        const stdFeedIntake = element.data[0].feed_intake.y.standard ? element.data[0].feed_intake.y.standard : []
        buildFeedIntake.cages.push(cageFeedIntake)
        buildFeedIntake.days.push(...dayBodyFeedIntake)
        buildFeedIntake.standard.push(...stdFeedIntake)

        // POINT FEED BUILD
        const cagePointFeed = {
          text : element.cage_name,
          data : element.data[0].feed_intake.y.input ? element.data[0].feed_intake.y.input : [],
          fillStyle: generateColor()
        }
        const dayBodyPointFeed = element.data[0].point_feed.x ? element.data[0].point_feed.x : []
        const stdPointFeed = element.data[0].point_feed.y.standard ? element.data[0].point_feed.y.standard : []
        buildPointFeed.cages.push(cagePointFeed)
        buildPointFeed.days.push(...dayBodyPointFeed)
        buildPointFeed.standard.push(...stdPointFeed)
      })
      this.fillData('point_feed', this.getUnique(buildPointFeed.days), buildPointFeed)
      this.fillData('feed_intake', this.getUnique(buildFeedIntake.days), buildFeedIntake)
      this.fillData('body_weight', this.getUnique(buildBodyWeight.days), buildBodyWeight)
      this.fillData('fcr', this.getUnique(buildFcr.days), buildFcr)
      this.fillData('ip', this.getUnique(buildIp.days), buildIp)
    },
    async onSelectBranch(val) {
      this.branch_id = val ? val.id : ''
      try {
        this.is_loading = true
        await this.getCycleGraphDashboard({
          branch_id: this.branch_id,
          field_supervisor: this.ppl_id
        })
        this.generatedGrafikData()
        this.is_loading = false
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
    },
    async onRemoveBranch() {
      this.branch_id = ''
      await this.getCycleGraphDashboard({
        branch_id: this.branch_id,
        field_supervisor: this.ppl_id
      })
      this.generatedGrafikData()
    },
    async onSelectPPL(val) {
      this.ppl_id = val ? val.id : ''
      try {
        this.is_loading = true
        await this.getCycleGraphDashboard({
          branch_id: this.branch_id,
          field_supervisor: this.ppl_id
        })
        this.generatedGrafikData()
        this.is_loading = false
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
    },
    async onRemovePPL() {
      this.ppl_id = ''
      await this.getCycleGraphDashboard({
        branch_id: this.branch_id,
        field_supervisor: this.ppl_id
      })
      this.generatedGrafikData()
    },
    formatFloat(number) {
      return float3Decimals(number)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    fillData (type, labelsChart, dataChart) {
      const standardChart = dataChart.standard
      const pushDataSets = []
      dataChart.cages.forEach(element => {
        const objSet = {
          label: element.text,
          type: 'line',
          categoryPercentage: 0.5,
          barThickness: 20,
          borderColor: '#045A95',
          backgroundColor: element.fillStyle,
          fill: false,
          data: element.data,
          pointStyle: 'circle',
          pointRadius: 5,
          pointHoverRadius: 15
        }
        pushDataSets.push(objSet)
      })
      if (type === 'point_feed') {
        this.pointFeedLength = dataChart.cages.length
      } else if (type === 'feed_intake') {
        this.feedIntakeLength = dataChart.cages.length
      } else if (type === 'body_weight') {
        this.bodyWeightLength = dataChart.cages.length
      } else if (type === 'fcr') {
        this.fcrLength = dataChart.cages.length
      } else if (type === 'ip') {
        this.ipLength = dataChart.cages.length
      }
      // BUILD DATA STANDARD
      const objStandard ={
        label: 'Standard',
        type: 'line',
        borderColor: '#045A95',
        backgroundColor: '#045A95',
        fill: false,
        data: standardChart,
        radius: 0
      }
      pushDataSets.push(objStandard)
      
      const buildData = {
        labels: labelsChart,
        datasets: pushDataSets
      }
      const dataTooltip = {
        enabled: true,
        mode: 'label',
        displayColors: true,
        callbacks: {
          title: (t) => {
            const xLabelBuild =  t[0].xLabel ? t[0].xLabel : ''
            const title = 'Hari ke ' + xLabelBuild
            return title
          },
          label: function(t, d) {
            const xLabel = d.datasets[t.datasetIndex].label
            const yLabel = xLabel === 'Standard' ? t.yLabel : float3Decimals(t.yLabel)
            return xLabel + ': ' + yLabel
          }
        }
      }

      const dataLegend = {
        display: screen.width > 820,
        position: 'bottom',
        align: 'start',
        maxHeight: 100,
        fullSize: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          generateLabels: () => {
            if (this.isBtnView) {
              return dataChart.cages ? dataChart.cages : []
            } else {
              let newData = []
              if (dataChart.cages.length <= 20) {
                return dataChart.cages ? dataChart.cages : []
              } else {
                switch (type) {
                case 'point_feed':
                  newData = dataChart.cages ? dataChart.cages.slice(this.pointFeedStart, this.pointFeedEnd) : []
                  break
                case 'feed_intake':
                  newData = dataChart.cages ? dataChart.cages.slice(this.feedIntakeStart, this.feedIntakeEnd) : []
                  break
                case 'body_weight':
                  newData = dataChart.cages ? dataChart.cages.slice(this.bodyWeightStart, this.bodyWeightEnd) : []
                  break
                case 'fcr':
                  newData = dataChart.cages ? dataChart.cages.slice(this.fcrStart, this.fcrEnd) : []
                  break
                case 'ip':
                  newData = dataChart.cages ? dataChart.cages.slice(this.ipStart, this.ipEnd) : []
                  break
                default:
                  break
                }
                return newData
              }
            }
          }
        }
      }
      const dataScales = {
        xAxes: [{
          // stacked: true,
          categoryPercentage: 0.5,
          barPercentage: 1,
          gridLines: {
            display: false,
            drawOnChartArea: false
          }
        }],
        yAxes: [{
          gridLines: {
            display: true,
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            min: 0
          }
        }]
      }

      switch (type) {
      case 'point_feed':
        this.dataPointFeed = buildData
        this.barLineOptionPointFeed = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionPointFeed.tooltips = dataTooltip
        this.barLineOptionPointFeed.legend = dataLegend
        this.barLineOptionPointFeed.scales = dataScales
        this.barLineOptionPointFeed.maintainAspectRatio = false
        // this.barLineOptionPointFeed.scales.yAxes[0].ticks.max = 200
        // this.barLineOptionPointFeed.scales.yAxes[0].ticks.stepSize = 20
        // this.barLineOptionPointFeed.title.text = 'Daily Intake'
        break
      case 'feed_intake':
        this.dataFeedIntake = buildData
        this.barLineOptionFeedIntake = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionFeedIntake.tooltips = dataTooltip
        this.barLineOptionFeedIntake.legend = dataLegend
        this.barLineOptionFeedIntake.scales = dataScales
        this.barLineOptionPointFeed.maintainAspectRatio = false
        // this.barLineOptionFeedIntake.scales.yAxes[0].ticks.max = 200
        // this.barLineOptionFeedIntake.scales.yAxes[0].ticks.stepSize = 20
        // this.barLineOptionFeedIntake.title.text = 'Feed Intake'
        break
      case 'body_weight':
        this.dataBodyWeight = buildData
        this.barLineOptionBodyWeight = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionBodyWeight.tooltips = dataTooltip
        this.barLineOptionBodyWeight.legend = dataLegend
        this.barLineOptionBodyWeight.scales = dataScales
        this.barLineOptionPointFeed.maintainAspectRatio = false
        // this.barLineOptionBodyWeight.scales.yAxes[0].ticks.max = 200
        // this.barLineOptionBodyWeight.scales.yAxes[0].ticks.stepSize = 20
        this.barLineOptionBodyWeight.title.text = 'Body Weight'
        break
      case 'fcr':
        this.dataFCR = buildData
        this.barLineOptionFCR = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionFCR.tooltips = dataTooltip
        this.barLineOptionFCR.legend = dataLegend
        this.barLineOptionFCR.scales = dataScales
        this.barLineOptionPointFeed.maintainAspectRatio = false
        // this.barLineOptionFCR.scales.yAxes[0].ticks.max = 200
        // this.barLineOptionFCR.scales.yAxes[0].ticks.stepSize = 20
        // this.barLineOptionFCR.title.text = 'Feed Conversion Ratio (FCR)'
        break
      case 'ip':
        this.dataIP = buildData
        this.barLineOptionIP = JSON.parse(JSON.stringify(this.barLineOptions))
        this.barLineOptionIP.tooltips = dataTooltip
        this.barLineOptionIP.legend = dataLegend
        this.barLineOptionIP.scales = dataScales
        this.barLineOptionPointFeed.maintainAspectRatio = false
        // this.barLineOptionIP.scales.yAxes[0].ticks.max = 200
        // this.barLineOptionIP.scales.yAxes[0].ticks.stepSize = 20
        // this.barLineOptionIP.title.text = 'Indeks Prestasi (IP)'
        break
      default:
        break
      }
    },
    getUnique (data) {
      var uniqueArray = []
      for(let i=0; i < data.length; i++){
        if(uniqueArray.indexOf(data[i]) === -1) {
          uniqueArray.push(data[i])
        }
      }
      uniqueArray.sort(function(a, b){return a-b})
      return uniqueArray
    }
  }
}
</script>

<style>
  .title-chart {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #025231;
    text-align: left;
  }

  .small {
    text-align: right;
    border: 1px solid #C2D6CE;
    border-radius: 16px;
    padding: 30px 20px;
    margin:  50px auto;
  }
  .filter-title {
    margin-right: 20px;
  }
  .filter-graphic {
    display: flex;
    align-items: center;
  }
  .standard-dot {
    position: static;
    width: 24px;
    height: 6px;
    left: 0px;
    top: 6px;
    background: #045A95;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 7px 4px;
  }
  .standard-text {
    font-size: 12px;
  }
  .btn-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;

    position: static;
    width: 90px;
    height: 32px;
    left: 0px;
    top: 0px;
    border-radius: 8px 0px 0px 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 10px 0px;
  }
  .btn-grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;

    position: static;
    width: 90px;
    height: 32px;
    left: 0px;
    top: 0px;
    border: 1px solid #85AD9C;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 0px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 10px 0px;
  }
  .next-btn {
    cursor: pointer;
  }

  .chartjs-render-monitor {
    width: 100% !important;
    height: 100% !important;
    max-height: 1100px;
    overflow-x: scroll;
    display: flex !important;
  }

  @media screen and (min-width: 820px) {
    .small {
      padding: 30px 35px;
    }
  }
</style>
