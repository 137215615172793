<template>
  <div>
    <gmap-map
      ref="gmap"
      :center="{ lat: latVal, lng: longVal }"
      :zoom="zoomVal"
      style="width: 100%; height: 400px;"
      :options="mapOptions"
      disabled
      @load="initMap"
    />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    latVal: {
      type: Number,
      default: -6.6461448
    },
    longVal: {
      type: Number,
      default: 107.0795711 
    },
    zoomVal: {
      type: Number,
      required: false,
      default: 8
    },
    mapDataset: {
      type: Array,
      required: false,
      default: null
    },
    filter: {
      type: String,
      required: true,
      default: 'Semua'
    }
  },
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true
      },
      infoWindow: null
    }
  },
  watch: {
    mapDataset(a, b) {
      if(a != b){
        this.mapDataset = a
        this.initMap()
      }
    }
  },
  async mounted() {
    // Load GeoJSON data using Axios
    try {
      const response = await axios.get('/files/geojson/id-city.geojson')
      this.geojsonData = response.data
      this.initMap()
    } catch (error) {
      console.error('Failed to load GeoJSON data:', error)
    }
  },
  methods: {
    interpolateHexColor(color1, color2, percentage) {
      const r1 = parseInt(color1.substring(1, 3), 16)
      const g1 = parseInt(color1.substring(3, 5), 16)
      const b1 = parseInt(color1.substring(5, 7), 16)

      const r2 = parseInt(color2.substring(1, 3), 16)
      const g2 = parseInt(color2.substring(3, 5), 16)
      const b2 = parseInt(color2.substring(5, 7), 16)

      const r = Math.round(r1 + (r2 - r1) * (percentage / 100)).toString(16).padStart(2, '0')
      const g = Math.round(g1 + (g2 - g1) * (percentage / 100)).toString(16).padStart(2, '0')
      const b = Math.round(b1 + (b2 - b1) * (percentage / 100)).toString(16).padStart(2, '0')

      return `#${r}${g}${b}`
    },
    initMap() {
      const map = this.$refs.gmap.$mapObject // Access the underlying Google Map instance
      this.hideInfoWindow()

      const legend = document.createElement('div')
      legend.id = 'legend'

      // Set the content of the legend
      const content = `
      <div class="legend-container">
        <div class="color-box uk-width-1-1"></div>
        <div class="legend-item">
          <div class="label uk-text-left">0</div>
          <div class="label uk-text-right">99+</div>
        </div>
      </div>
      `

      legend.innerHTML = content

      // Add the legend to the map
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(legend)

      const mapDatasetCorrected = this.mapDataset.map((cityObj) => ({
        ...cityObj,
        // total: cityObj.total == 4 ? cityObj.total + 10 : cityObj.total,            //for testing purposes
        city_name: cityObj.city_name.includes('KABUPATEN') ? cityObj.city_name.replace('KABUPATEN', '').trim() : cityObj.city_name
      }))

      map.data.setStyle((feature) => {
        const cityName = feature.getProperty('NAME_1') // Replace 'NAME_1' with the actual property name for city names

        // Find the corresponding city object from mapDatasetCorrected
        const foundCity = mapDatasetCorrected.find(
          (cityObj) => (
            cityObj.city_name.toUpperCase() === cityName.toUpperCase() ||
            cityObj.city_name.replace('KABUPATEN', 'KOTA').trim().toUpperCase() === cityName.toUpperCase()
          )
        )

        if (foundCity) {
          const total = foundCity.total
          const maxTotal =  Math.max(...mapDatasetCorrected.map((cityObj) => cityObj.total))
          const colorTint = 1 - ( total / maxTotal ) // Calculate the color tint based on the ratio

          // Calculate the dynamic color using interpolation between your two hex colors
          const startColor = '#91AEAB'
          const endColor = '#C6D5CE'
          const color = this.interpolateHexColor(startColor, endColor, colorTint * 100)
          return {
            fillColor: color,
            fillOpacity: 1,
            strokeWeight: 0.5,
            strokeColor: '#049559'
          }
        } else {
          return {
            fillColor: '#E8E8E8',
            fillOpacity: 1,
            strokeWeight: 0,
            strokeColor: '#049559'
          }
        }
      })

      // Load the GeoJSON data
      map.data.addGeoJson(this.geojsonData)

      // Add event listeners for mouseover and mouseout events
      map.data.addListener('mouseover', (event) => {
        // Change the appearance of the hovered feature
        

        // Get the matching city object from mapDatasetCorrected
        const cityDataset = mapDatasetCorrected.find(
          (city) => city.city_name.toUpperCase() === event.feature.getProperty('NAME_1').toUpperCase()
        )
        if(cityDataset){
          map.data.overrideStyle(event.feature, {
            fillOpacity: 0.8,
            strokeColor: 'transparent'
          })
        }
        // Get the city name for the InfoWindow
        const city = event.feature.getProperty('NAME_1')
        // Display the city name in an InfoWindow
        this.showInfoWindow(city, event.latLng, cityDataset.total)
      })

      map.data.addListener('mouseout', () => {
        // Revert back to the original style when the mouse moves out of the feature
        map.data.revertStyle()
        // Close the InfoWindow
        this.hideInfoWindow()
      })
    },
    showInfoWindow(city, position, totalCount) {
      console.log(this.filter)
      this.hideInfoWindow() // Close any existing info window
      let contentInfo
      if (this.filter == 'Semua'){
        contentInfo = '<div style="font-size: 16px; padding: 10px;" class="label-green">' + city + '<br>  Mitra Prospek dan Paranje Farmers: ' + totalCount + '</div>'
      } else {
        contentInfo = '<div style="font-size: 16px; padding: 10px;" class="label-green">' + city + '<br> Paranje Farmers: ' + totalCount + '</div>'
      }

      this.infoWindow = new window.google.maps.InfoWindow({
        content:  contentInfo,
        position: position
      })

      // Add event listener to handle closing the info window
      this.infoWindow.addListener('closeclick', () => {
        this.infoWindow = null // Clear the infoWindow instance when closed
      })

      this.infoWindow.open(this.$refs.gmap.$mapObject)
    },
    hideInfoWindow() {
      if (this.infoWindow) {
        this.infoWindow.close()
        this.infoWindow = null
      }
    }
  }
}
</script>
<style>
#legend {
  background-color: #fff;
  border: 1px solid #ccc;
  right: 20px !important;
  top: 20px !important;
  padding: 12px;
  border-radius: 8px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.color-box {
  width: 100%;
  height: 20px;
  margin-right: 5px;
  border-radius: 4px;
  background: linear-gradient(270deg, #8AB0AB 0%, #8AB0AB 32.69%, #C2D6CE 68.79%, #F0F5F3 100%);
}

.label {
  text-align: left;
  font-size: 12px;
}
</style>
