import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_HARVEST_REQUEST } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  harvestRequest: {meta:{},data:[]},
  harvestRequestDetail: {meta:{},data:{}},
  sync_data: {},
  meta: {page:1,limit:10}
}

const mutations = {
  "SET_HARVEST_REQUEST": (state, payload) => {
    Vue.set(state, 'harvestRequest', payload)
  },
  "SET_HARVEST_REQUEST_DETAIL": (state, payload) => {
    Vue.set(state, 'harvestRequestDetail', payload)
  },
  "SYNC_DATA": (state, payload) => {
    Vue.set(state, 'sync_data', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  }
}

const actions = {
  async getHarvestRequest(context, data) {
    try {
      const response = await apiGetAuth(API_HARVEST_REQUEST.LIST, data)
      context.commit('SET_HARVEST_REQUEST', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getHarvestRequestDetail(context, data) {
    try {
      const response = await apiGetAuth(API_HARVEST_REQUEST.DETAIL(data))
      context.commit('SET_HARVEST_REQUEST_DETAIL', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async syncHarvestRequest(context, data) {
    try {
      const response = await apiGetAuth(API_HARVEST_REQUEST.SYNC, data)
      context.commit('SYNC_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  harvestRequest: state => {
    return state.harvestRequest
  },
  harvestRequestDetail: state => {
    return state.harvestRequestDetail
  },
  sync_data: state => {
    return state.sync_data
  },
  meta: state => {
    return state.meta
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
