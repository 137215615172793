import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_DETAIL_SOP } from '@/utils/api-url'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'


const state = {
  detailSops: {meta:{},data:[]},
  detailSopDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  deleteDetailSop: {},
  modalAdd: {},
  modalEdit: {},
  addDetailSop: {}
}

const mutations = {
  "SET_DETAIL_SOP": (state, payload) => {
    Vue.set(state, 'detailSops', payload)
  },
  "SET_DETAIL_SOP_DETAIL": (state, payload) => {
    Vue.set(state, 'detailSopDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteDetailSop', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addDetailSop', payload)
  }
}

const actions = {
  async getDetailSop(context, data) {
    try {
      const response = await apiGetAuth(API_DETAIL_SOP.LIST, data)
      context.commit('SET_DETAIL_SOP', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getDetailSopDetail(context, data) {
    try {
      const response = await apiGetAuth(API_DETAIL_SOP.DETAIL(data))
      context.commit('SET_DETAIL_SOP_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importDetailSop(context, data) {
    try {
      const response = await apiPostAuth(API_DETAIL_SOP.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteDetailSop(context, data) {
    try {
      const response = await apiDeleteAuth(API_DETAIL_SOP.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveDetailSop(context, data) {
    try {
      const response = await apiPostAuth(API_DETAIL_SOP.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editDetailSop(context, data) {
    try {
      const response = await apiPutAuth(API_DETAIL_SOP.UPDATE(data.id), data)
      notificationSuccess(`Ubah Data "${response.data.data.name || ''}" Berhasil`)
      setTimeout(function () {
        window.location.href = '/admin/detail-sop'
      }, 1000)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  detailSops: state => {
    return state.detailSops
  },
  detailSopDetail: state => {
    return state.detailSopDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  getDeleteDetailSopMsg: state => {
    return state.deleteDetailSop
  },
  modalAdd: state => {
    return state.modalAdd
  },
  modalEdit: state => {
    return state.modalEdit
  },
  getAddDetailSopMsg: state => {
    return state.addDetailSop
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
