<template>
  <div class="uk-margin-large-top bg-login">
    <div class="bg-front" />
    <div class="uk-card uk-card-default uk-card-body login-card">
      <div class="content-wrapper">
        <div class="logo-wrapper">
          <img
            v-lazy="`${images}/icon/logo-color.png`"
            alt=""
            class="logo-img"
            height="80px"
            width="74px"
          >
          <div>
            <h1 class="uk-text-left uk-margin-remove-bottom-s">
              PARANJE
            </h1>
            <h2 class="uk-text-left uk-margin-small-top uk-margin-remove-bottom-s">
              Admin Dashboard
            </h2>
          </div>
        </div>
        <h3>Masuk Akun</h3>
        <form
          style="margin-bottom: 29.5px"
          @submit.prevent="onSubmit"
        >
          <div class="uk-margin uk-text-left">
            <h4>Email</h4>
            <div class="uk-inline uk-width-1-1">
              <input
                v-model="email"
                v-validate="'required|email'"
                name="email"
                class="uk-input"
                type="text"
                placeholder="Masukkan email..."
                :class="{'uk-form-danger': errors.has('email')}"
              >
            </div>
            <span
              v-show="errors.has('email')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('email') }}</span>
          </div>
          <div class="uk-margin uk-text-left">
            <h4>Password</h4>
            <div class="uk-inline uk-width-1-1 password-field">
              <input
                id="password"
                v-model="password"
                v-validate="'required|min:8'"
                name="password"
                class="uk-input"
                :type="passwordFieldType"
                placeholder="Masukkan password..."
                autocomplete="new-password"
                :class="{'uk-form-danger': errors.has('password')}"
              >
              <img
                v-lazy="`${images}/icon/${eyeIcon}`"
                alt=""
                class="toggle-eye"
                @click="passwordVisibility"
              >
            </div>
            <span
              v-show="errors.has('password')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('password') }}</span>
          </div>
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary uk-width-1-1"
            type="submit"
            :disabled="is_loading"
          >
            Masuk
          </button>
          <button
            v-else
            class="uk-button uk-button-primary uk-width-1-1"
            type="button"
            disabled
          >
            <div uk-spinner />
          </button>
        </form>
        <router-link to="/forgot">
          <h4 style="font-weight: bold; text-align: center;">
            Lupa Password
          </h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { setProfile } from '@/utils/auth'

export default {
  data() {
    return {
      is_loading: false,
      email: '',
      password: '',
      passwordFieldType: 'password',
      images: PREFIX_IMAGE,
      eyeIcon: 'eye.svg'
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      loginResponse: 'auth/getLoginResponse'
    })
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/login',
      getUserDetail: 'user/getUserDetail'
    }),
    passwordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    },
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.login()
        }
      })
    },
    async login() {
      this.is_loading = true
      await this.loginUser({
        email: this.email,
        password: this.password
      })
      if (this.loginResponse.id) {
        await this.getUserDetail(this.loginResponse.id)
        setProfile(this.user)
      }
      this.is_loading = false
    }
  }
}
</script>

<style scoped>

.logo-img {
  position: relative;
  width: 80px;
  height: 74px;
  margin-right: 16px;
}

h1 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #025231;
}

h2 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #025231;
}

h3 {
  text-align: left;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: #1F2E28;
  margin: 0 0 32px;
}

h4 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #025231;
  margin-bottom: 5px;
}

.password-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.uk-margin-large-top {
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-top: 0 !important;
}

.toggle-eye {
  position: absolute;
  margin-right: 10px;
  cursor: pointer;
}
</style>
