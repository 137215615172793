import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_UKIRAMA, API_SYNC_ITEM } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  ovkCategory: {},
  ovkCategories: {meta:{},data:[]},
  syncOVKCategory: {},
  ovkPrice: {meta:{},data:[]},
  metaPrice: {page:1,limit:10}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_OVK_CATEGORIES": (state, payload) => {
    Vue.set(state, 'ovkCategories', payload)
  },
  "SET_OVK_CATEGORY": (state, payload) => {
    Vue.set(state, 'ovkCategory', payload)
  },
  "SYNC_OVK": (state, payload) => {
    Vue.set(state, 'syncOVKCategory', payload)
  },
  "OVK_PRICE": (state, payload) => {
    Vue.set(state, 'ovkPrice', payload)
  },
  "SET_META_PRICE": (state, payload) => {
    Vue.set(state, 'metaPrice', payload)
  }
}

const actions = {
  async getOvkCategory(context, data) {
    try {
      const response = await apiGetAuth(API_UKIRAMA.LIST, data)
      context.commit('SET_OVK_CATEGORIES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getOvkCategoryDetail(context, data) {
    try {
      const response = await apiGetAuth(API_UKIRAMA.DETAIL(data))
      context.commit('SET_OVK_CATEGORY', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async syncOVKCategory(context, data) {
    try {
      const response = await apiGetAuth(API_SYNC_ITEM.LIST, data)
      context.commit('SYNC_OVK', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getOvkPrice(context, data) {
    try {
      const response = await apiGetAuth(API_UKIRAMA.PRICE, data)
      context.commit('OVK_PRICE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  ovkCategories: state => {
    return state.ovkCategories
  },
  ovkCategory: state => {
    return state.ovkCategory
  },
  syncOVKCategory: state => {
    return state.syncOVKCategory
  },
  ovkPrice: state => {
    return state.ovkPrice
  },
  metaPrice: state => {
    return state.metaPrice
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
