import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth, apiGetPdfAuth } from '@/utils/api'
import { API_CAGE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  cages: {meta:{},data:[]},
  cageFarms: {meta:{},data:[]},
  cage: {meta:{},data:{}},
  digitalConcent: {meta:{},data:[]},
  meta: {page:1,limit:10},
  metaCageFarm: {page:1,limit:10},
  metaLogHistory: {
    page: 1,
    limit: 10
  },
  metaDigitalConcent: {page:1,limit:10},
  importData: {},
  modalDelete: {},
  modalEdit: {},
  modalAdd: {},
  addCage: {},
  updateCage: {},
  deleteCage: {},
  cageLogHistory: {
    meta: {},
    data:[]
  }
}

const mutations = {
  "SET_CAGES": (state, payload) => {
    Vue.set(state, 'cages', payload)
  },
  "SET_CAGE_FARMS": (state, payload) => {
    Vue.set(state, 'cageFarms', payload)
  },
  "SET_CAGE_DETAIL": (state, payload) => {
    Vue.set(state, 'cage', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_META_CAGE_FARM": (state, payload) => {
    Vue.set(state, 'metaCageFarm', payload)
  },
  "SET_META_LOG_HISTORY": (state, payload) => {
    Vue.set(state, 'metaLogHistory', payload)
  },
  "SET_META_DIGITAL_CONCENT": (state, payload) => {
    Vue.set(state, 'metaDigitalConcent', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addCage', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateCage', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteCage', payload)
  },
  "SET_CAGE_LOG_HISTORY": (state, payload) => {
    Vue.set(state, 'cageLogHistory', payload)
  },
  "SET_DIGITAL_CONCENT": (state, payload) => {
    Vue.set(state, 'digitalConcent', payload)
  },
  "SET_DIGITAL_CONCENT_EXPORT": (state, payload) => {
    Vue.set(state, 'downloadDigitalConcent', payload)
  },
  "SET_RESPONSE_IMPORT_CAGE": (state, payload) => {
    Vue.set(state, 'responseImportCage', payload)
  }
}

const actions = {
  async getCage(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE.LIST, data)
      context.commit('SET_CAGES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCageFarm(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE.LIST_BY_FARM, data)
      context.commit('SET_CAGE_FARMS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCageDetail(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE.DETAIL(data))
      context.commit('SET_CAGE_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCageLogHistory(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE.LOG_HISTORY, data)
      context.commit('SET_CAGE_LOG_HISTORY', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importCage(context, data) {
    // this for master data cage type
    try {
      const response = await apiPostAuth(API_CAGE.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteCage(context, data) {
    try {
      const response = await apiDeleteAuth(API_CAGE.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveCage(context, data) {
    try {
      const response = await apiPostAuth(API_CAGE.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editCage(context, data) {
    try {
      const response = await apiPutAuth(API_CAGE.UPDATE, data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getDigitalConcent(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE.DIGITAL_CONCENT(data))
      context.commit('SET_DIGITAL_CONCENT', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getDigitalConcentPdf(context, data) {
    try {
      const response = await apiGetPdfAuth(API_CAGE.EXPORT_DIGITAL_CONCENT(data))
      context.commit('SET_DIGITAL_CONCENT_EXPORT', response.data)
      console.log(response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async importCageList(context, data) {
    // this for bulk upload cage data
    try {
      const response = await apiPostAuth(API_CAGE.BULK_IMPORT_CAGE, data)
      context.commit('SET_RESPONSE_IMPORT_CAGE', response.data.message)
    } catch (err) {
      notificationDanger(err)
    }

  }
}

const getters = {
  cages: state => {
    return state.cages
  },
  cageFarms: state => {
    return state.cageFarms
  },
  cage: state => {
    return state.cage
  },
  meta: state => {
    return state.meta
  },
  metaCageFarm: state => {
    return state.metaCageFarm
  },
  metaLogHistory: state => {
    return state.metaLogHistory
  },
  metaDigitalConcent: state => {
    return state.metaDigitalConcent
  },
  importData: state => {
    return state.importData
  },
  modalDelete: state => {
    return state.modalDelete
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  getAddCageMsg: state => {
    return state.addCage
  },
  getUpdateCageMsg: state => {
    return state.updateCage
  },
  getDeleteCageMsg: state => {
    return state.deleteCage
  },
  cageLogHistory: state => {
    return state.cageLogHistory
  },
  digitalConcent: state => {
    return state.digitalConcent
  },
  downloadDigitalConcent: state => {
    return state.downloadDigitalConcent
  },
  responseImportCage: state => {
    return state.responseImportCage
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
