import { apiPostAuth, apiPostNonAuth } from '@/utils/api'
import { API_AUTH } from '@/utils/api-url'
import { logoutUser, setCookie, setStorageRoleInfo, checkRoleManagement } from '@/utils/auth'
import {
  notificationSuccess,
  notificationDanger,
  notificationInfo
} from '@/utils/notification'
import router from '@/router'
import Vue from 'vue'

const state = {
  changePassword: {},
  loginResponse: {}
}

const mutations = {
  "SET_CHANGE_RESPONSE": (state, payload) => {
    Vue.set(state, 'changePassword', payload)
  },
  "SET_LOGIN_RESPONSE": (state, payload) => {
    Vue.set(state, 'loginResponse', payload)
  }
}

const actions = {
  async login(context, data) {
    try {
      const response = await apiPostNonAuth(API_AUTH.LOGIN, data)
      context.commit('SET_LOGIN_RESPONSE', response.data.data)
      let result = false
      const getRole = response.data.data.role.Name
      setCookie(response.data.data)
      setStorageRoleInfo(response.data.data.role_management, response.data.data.role)
      const checkRoles = checkRoleManagement()


      if (getRole === `Super Admin`) {
        result = true
      } else {
        if (checkRoles) {
          result = true
        } else {
          notificationInfo(`Anda tidak mempunyai akses ke dashboard silahkan hubungi admin`)
        }
      }
      if (result) {
        notificationSuccess('Login Success')
        setTimeout(() => {
          router.push('/admin/dashboard')
          router.go(0)
        }, 1000)
      }
    } catch (err) {
      notificationDanger(err)
    }
  },
  async logout() {
    try {
      await apiPostAuth(API_AUTH.LOGOUT)
      notificationSuccess('Logout Success')
      setTimeout(() => {
        logoutUser()
      }, 1000)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async forgot(context, data) {
    try {
      await apiPostNonAuth(API_AUTH.FORGOT(data))
      notificationSuccess("Link reset password sudah dikirik ke email. Silahkan cek email.")
      setTimeout(() => {
        router.push('/login')
        router.go(0)
      }, 1000)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async changePassword(context, data) {
    try {
      const response = await apiPostNonAuth(API_AUTH.CHANGE(data), {
        password: data.password
      })
      context.commit('SET_CHANGE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  getChangePassword: state => {
    return state.changePassword
  },
  getLoginResponse: state => {
    return state.loginResponse
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
