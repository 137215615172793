import Vue from 'vue'
import { apiGetAuth, apiPutAuth } from '@/utils/api'
import { API_SETTING } from '@/utils/api-url'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'


const state = {
  setting: {},
  settingEdit:{},
  updateSetting:{}
}

const mutations = {
  "SET_SETTING": (state, payload) => {
    Vue.set(state, 'setting', payload)
  },
  "SET_SETTING_EDIT": (state, payload) => {
    Vue.set(state,'settingEdit', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateSetting', payload)
  }
}

const actions = {
  async getSetting(context, data) {
    try {
      const response = await apiGetAuth(API_SETTING.DETAIL, data)
      context.commit('SET_SETTING', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async editSetting(context, data) {
    try {
      const response = await apiPutAuth(API_SETTING.UPDATE(data.id), data)   
      context.commit('SET_UPDATE_RESPONSE', response.data)
      notificationSuccess('Ubah Data Berhasil')
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
  
}

const getters = {
  setting: state => {
    return state.setting
  },
  settingEdit: state => {
    return state.settingEdit
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
