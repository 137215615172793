import Vue from 'vue'
import { apiGetAuth, apiPostAuth, apiPutAuth, apiDeleteAuth } from '@/utils/api'
import { API_ACQUISITION } from '@/utils/api-url'
import { notificationDanger, notificationSuccess } from '@/utils/notification'

const state = {
  dataListForCage: {},
  dataList: {},
  dataMeta: {
    page: 1,
    limit: 10
  },
  dataMetaInstallment: {
    is_all: true,
    acquisition_id: ''
  },
  statusSurvey:false,
  disabledEdit:false
}

const mutations = {
  "SET_DISABLED_EDIT": (state, payload) => {
    Vue.set(state, 'disabledEdit', payload)
  },
  "SET_DATA_LIST_FOR_CAGE": (state, payload) => {
    Vue.set(state, 'dataListForCage', payload)
  },
  "SET_DATA_LIST": (state, payload) => {
    Vue.set(state, 'dataList', payload)
  },
  "SET_DATA_META": (state, payload) => {
    Vue.set(state, 'dataMeta', payload)
  },
  "SET_DATA_CREATE": (state, payload) => {
    Vue.set(state, 'dataCreate', payload)
  },
  "SET_DATA_UPDATE": (state, payload) => {
    Vue.set(state, 'dataUpdate', payload)
  },
  "SET_DATA_DETAIL": (state, payload) => {
    Vue.set(state, 'dataDetail', payload)
  },
  "SET_DATA_DELETE": (state, payload) => {
    Vue.set(state, 'dataDelete', payload)
  },
  "SET_DATA_PROSPECT_PARTNER": (state, payload) => {
    Vue.set(state, 'dataProspect', payload)
  },
  "SET_DATA_POTENTIAL_PARTNER": (state, payload) => {
    Vue.set(state, 'dataPotential', payload)
  },
  "SET_DATA_POTENTIAL_PARTNER_SURVEY": (state, payload) => {
    Vue.set(state, 'dataPotentialSurvey', payload)
  },
  "SET_DATA_POTENTIAL_PARTNER_SURVEY_UPDATE": (state, payload) => {
    Vue.set(state, 'dataPotentialSurveyUpdate', payload)
  },
  "SET_VERIFY_SURVEY": (state, payload) => {
    Vue.set(state, 'dataVerifySurvey', payload)
  },
  "SET_DATA_SUBMIT_VERIFICATION": (state, payload) => {
    Vue.set(state, 'dataSubmitVerification', payload)
  },
  "SET_DATA_SUBMIT_VERIFICATION_EDIT": (state, payload) => {
    Vue.set(state, 'dataSubmitVerificationEdit', payload)
  },
  "SET_VERIFY_DOCUMENT": (state, payload) => {
    Vue.set(state, 'dataVerifyDocument', payload)
  },
  "SET_DATA_DETAIL_STATUS": (state, payload) => {
    Vue.set(state, 'dataDetailStatus', payload)
  },
  "SET_DATA_DETAIL_SURVEY": (state, payload) => {
    Vue.set(state, 'dataDetailSurvey', payload)
  },
  "SET_LIST_INSTALLMENT": (state, payload) => {
    Vue.set(state, 'dataListInstallment', payload)
  },
  "SET_DATA_META_INSTALLMENT": (state, payload) => {
    Vue.set(state, 'dataMetaInstallment', payload)
  },
  "SET_STATUS_ACQUISITION_LISTS": (state, payload) => {
    Vue.set(state, 'statusAcquisitionLists', payload)
  },
  "SET_STATUS_ACQUISITION_LISTS_NOT_INTERESTED": (state, payload) => {
    Vue.set(state, 'listsNotInterested', payload)
  },
  "SET_LISTS_NOT_WORTHY": (state, payload) => {
    Vue.set(state, 'listsNotWorthy', payload)
  },
  "SET_LIST_WATER_RESOURCES": (state, payload) => {
    Vue.set(state, 'listWaterResources', payload)
  },
  "SET_LIST_CAGE_ACCESS": (state, payload) => {
    Vue.set(state, 'listCageAccess', payload)
  },
  "SET_STATUS_SURVEY": (state, payload) => {
    Vue.set(state, 'statusSurvey', payload)
  },
  "SET_UPLOAD_SPK_PLAN": (state, payload) => {
    Vue.set(state, 'dateContractUploaded', payload)
  },
  'SET_STORE_FACILITY_RESPONSE': (state, payload) => {
    Vue.set(state, 'storeFaciltyResponse', payload)
  },
  'SET_DATA_GUARANTEE_FUND': (state, payload) => {
    Vue.set(state, 'getGuaranteeFund', payload)
  },
  'SET_RESPONSE_UPLOAD_RECIPT': (state, payload) => {
    Vue.set(state, 'getResponseRecipt', payload)
  },
  'SET_DATA_RETIRE': (state, payload) => {
    Vue.set(state, 'retireMessage', payload)
  },
  'SET_RESPONSE_IMPORT' : (state, payload) => {
    Vue.set(state, 'importResponse', payload)
  }
}

const actions = {
  async getDataList(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.LIST, data)
      context.commit('SET_DATA_LIST', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getDataListForCage(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.LIST_FOR_CAGE(data.cage_category_id), data)
      context.commit('SET_DATA_LIST_FOR_CAGE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async createAcquisition(context, data) {
    try {
      const response = await apiPostAuth(API_ACQUISITION.CREATE, data)
      context.commit('SET_DATA_CREATE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async updateAcquisition(context, data) {
    try {
      const response = await apiPutAuth(API_ACQUISITION.UPDATE(data.id), data)
      context.commit('SET_DATA_UPDATE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getDataDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.DETAIL(data))
      context.commit('SET_DATA_DETAIL', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getDeleteAcquisition(context, data) {
    try {
      const response = await apiDeleteAuth(API_ACQUISITION.DELETE(data))
      context.commit('SET_DATA_DELETE', response.data)
    } catch (err) {
      context.commit('SET_DATA_DELETE', err)
      notificationDanger(err)
    }
  },
  async getPartnerProspect(context, data) {
    try {
      // note: update to tertarik or tidak tertarik
      const response = await apiPutAuth(API_ACQUISITION.PROSPECT(data.id), data)
      context.commit('SET_DATA_PROSPECT_PARTNER', response.data.message)
      if (response.data.data) return notificationSuccess('Mitra Prospek berhasil dirubah')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getPartnerPotential(context, data) {
    // note: update tidak layak
    try {
      const response = await apiPutAuth(API_ACQUISITION.POTENTIAL(data.id), data.payload)
      context.commit('SET_DATA_POTENTIAL_PARTNER', response.data.message)
      if (response.data.data) return notificationSuccess('Mitra Potensial berhasil dirubah')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getPartnerPotentialSurvey(context, data) {
    try {
      // note: create survey
      const response = await apiPostAuth(API_ACQUISITION.POTENTIAL_SURVEY, data)
      context.commit('SET_DATA_POTENTIAL_PARTNER_SURVEY', response.data.data)
      if (response.data.data) return notificationSuccess('Berhasil Menyimpan Data Survei Mitra!')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getPartnerPotentialSurveyUpdate(context, data) {
    try {
      // note: update survey
      data.forEach((value, key) => {
        if (key === 'acquisition_id') {
          data.id = value
        }
      })
      const response = await apiPutAuth(API_ACQUISITION.POTENTIAL_SURVEY_UPDATE(data.id), data)
      context.commit('SET_DATA_POTENTIAL_PARTNER_SURVEY_UPDATE', response.data.data)
      if (response.data.data) return notificationSuccess('Berhasil Merubah Data Survei Mitra!')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async verifySurvey(context, data) {
    try {
      const response = await apiPutAuth(API_ACQUISITION.POTENTIAL_VERIFY_SURVEY(data))
      context.commit('SET_VERIFY_SURVEY', response.data)
      if(response.data.message == 'OK'){
        window.location.href = '/admin/akuisisi/detail/'+data
      }
    }
    catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getPartnerSubmitVerification(context, data) {
    try {
      const response = await apiPostAuth(API_ACQUISITION.SUBMIT_VERIFICATION, data)
      context.commit('SET_DATA_SUBMIT_VERIFICATION', response.data.message)
      if (response.data.data) return notificationSuccess('Dokumen Berhasil Ditambahkan')
    } catch (err) {
      context.commit('SET_DATA_SUBMIT_VERIFICATION', err.message)
      notificationDanger(err)
    }
  },
  async editPartnerSubmitVerification(context, data) {
    try {
      data.forEach((value, key) => {
        if (key === 'id') {
          data.id = value
        }
      })
      const response = await apiPutAuth(API_ACQUISITION.SUBMIT_VERIFICATION_EDIT(data.id), data)
      context.commit('SET_DATA_SUBMIT_VERIFICATION_EDIT', response.data.message)
    } catch (err) {
      context.commit('SET_DATA_SUBMIT_VERIFICATION_EDIT', err.message)
      notificationDanger(err)
    }
  },
  async verifyDocument(context, data) {
    try {
      const response = await apiPutAuth(API_ACQUISITION.SUBMIT_VERIFICATION_VERIFY_DOCUMENT(data))
      context.commit('SET_VERIFY_DOCUMENT', response.data)
    }
    catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async getDataDetailStatus(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.DETAIL_STATUS(data))
      context.commit('SET_DATA_DETAIL_STATUS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getDataDetailSurvey(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.DETAIL_SURVEY(data))
      context.commit('SET_DATA_DETAIL_SURVEY', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchingStatusAcquisition(context, data) {
    try {
      const response = await apiPutAuth(API_ACQUISITION.STATUS_ACQUISITION, data)
      if (response.data.data) return notificationSuccess('Dokumen berhasil di verifikasi')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async uploadDateContract(context, data) {
    try {
      const response = await apiPutAuth(API_ACQUISITION.UPLOAD_SPK_PLAN(data.acquisition_id), data)
      context.commit('SET_UPLOAD_SPK_PLAN', response.data.data)
      if (response.data.data) return notificationSuccess('Rencana TTD SPK Telah Ditentukan')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchingUploadSpk(context, data) {
    try {
      data.forEach((value, key) => {
        if (key === 'acquisition_id') {
          data.id = value
        }
      })
      const response = await apiPutAuth(API_ACQUISITION.UPLOAD_SPK(data.id), data)
      if (response.data.data) return notificationSuccess('Dokumen SPK berhasil di unggah')
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchingListInstallment(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.LIST_INSTALLMENT, data)
      context.commit('SET_LIST_INSTALLMENT', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async fetchingReceipt(context, data) {
    try {
      data.forEach((value, key) => {
        if (key === 'id') {
          data.id = value
        }
      })
      const response = await apiPutAuth(API_ACQUISITION.DOWNLOAD_RECEIPT(data.id), data)
      if (response.data.data) return notificationSuccess('Kuitansi Dana Jaminan Berhasil di Unggah')
      context.commit('SET_RESPONSE_UPLOAD_RECIPT', response.data.message)
    } catch (err) {
      context.commit('SET_RESPONSE_UPLOAD_RECIPT', err.message)
      notificationDanger(err)
    }
  },
  async fetchingGuaranteeFund(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.GUARANTEE_FUND(data))
      context.commit('SET_DATA_GUARANTEE_FUND', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async storeFacility(context, data) {
    try {
      const response = await apiPostAuth(API_ACQUISITION.STORE_FACILITY, data)
      context.commit('SET_STORE_FACILITY_RESPONSE', response.data.message)
    } catch (err) {
      context.commit('SET_STORE_FACILITY_RESPONSE', err.message)
      notificationDanger(err)
    }
  },
  async updateFacility(context, data) {
    try {
      data.forEach((value, key) => {
        if (key === 'id') {
          data.id = value
        }
      })
      const response = await apiPutAuth(API_ACQUISITION.UPDATE_FACILITY(data.id), data)
      context.commit('SET_STORE_FACILITY_RESPONSE', response.data.message)
    } catch (err) {
      context.commit('SET_STORE_FACILITY_RESPONSE', err.message)
      notificationDanger(err)
    }
  },
  async getListStatusAcquisition(context, data){
    try {
      const response = await apiGetAuth(API_ACQUISITION.STATUS_ACQUISITION_LISTS, data)
      context.commit('SET_STATUS_ACQUISITION_LISTS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getListsNotInterested(context, data){
    try {
      const response = await apiGetAuth(API_ACQUISITION.LISTS_NOT_INTERESTED, data)
      context.commit('SET_STATUS_ACQUISITION_LISTS_NOT_INTERESTED', response.data.data)
    }catch (err) {
      notificationDanger(err)
    }
  },
  async getListsNotWorthy(context, data) {
    try {
      const response = await apiGetAuth(API_ACQUISITION.LISTS_NOT_WORTHY, data)
      context.commit('SET_LISTS_NOT_WORTHY', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getListForSurveyForm(context, data) {
    try {
      // water resources
      const responseWater = await apiGetAuth(API_ACQUISITION.LIST_WATER_RESOURCES, data)
      context.commit('SET_LIST_WATER_RESOURCES', responseWater.data.data) 
      // cage access
      const responseAccess = await apiGetAuth(API_ACQUISITION.LIST_CAGE_ACCESS, data)
      context.commit('SET_LIST_CAGE_ACCESS', responseAccess.data.data) 
    } catch (err) {
      notificationDanger(err)
    }
  },
  async retire(context, data) {
    try {
      const response = await apiPostAuth(API_ACQUISITION.RETIRE(data.id), data)
      context.commit('SET_DATA_RETIRE', response.data.message)
    } catch (err) {
      context.commit('SET_DATA_RETIRE', err.message)
      notificationDanger(err)
    }
  },
  async downloadSpkTemplate() {
    try {
      const response = await fetch(API_ACQUISITION.SPK_FILE)
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Template_SPK.pdf')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      notificationDanger(err)
    }
  },
  async bulkImport(context, data){
    try {
      data.forEach((value, key) => {
        if (key === 'endPoint') {
          data.endPoint = value
        }
      })
      const response = await apiPostAuth(API_ACQUISITION.BULK_IMPORT(data.endPoint), data)
      context.commit('SET_RESPONSE_IMPORT', response.data.message)
    } catch (error) {
      notificationDanger(error)
    }
  }
}

const getters = {
  dataList: state => {
    return state.dataList
  },
  dataListForCage: state => {
    return state.dataListForCage
  },
  dataMeta: state => {
    return state.dataMeta
  },
  dataCreate: state => {
    return state.dataCreate
  },
  dataUpdate: state => {
    return state.dataUpdate
  },
  dataDetail: state => {
    return state.dataDetail
  },
  dataDelete: state => {
    return state.dataDelete
  },
  dataProspect: state => {
    return state.dataProspect
  },
  dataPotential: state => {
    return state.dataPotential
  },
  dataPotentialSurvey: state => {
    return state.dataPotentialSurvey
  },
  dataPotentialSurveyUpdate: state => {
    return state.dataPotentialSurveyUpdate
  },
  dataVerifySurvey: state => {
    return state.dataVerifySurvey
  },
  dataDetailStatus: state => {
    return state.dataDetailStatus
  },
  dataDetailSurvey: state => {
    return state.dataDetailSurvey
  },
  dataSubmitVerification: state => {
    return state.dataSubmitVerification
  },
  dataSubmitVerificationEdit: state => {
    return state.dataSubmitVerificationEdit
  },
  dataVerifyDocument: state => {
    return state.dataVerifyDocument
  },
  dataListInstallment: state => {
    return state.dataListInstallment
  },
  dataMetaInstallment: state => {
    return state.dataMetaInstallment
  },
  statusAcquisitionLists: state => {
    return state.statusAcquisitionLists
  },
  listsNotInterested: state => {
    return state.listsNotInterested
  },
  listsNotWorthy: state => {
    return state.listsNotWorthy
  },
  listWaterResources: state => {
    return state.listWaterResources
  },
  listCageAccess: state => {
    return state.listCageAccess
  },
  statusSurvey: state => {
    return state.statusSurvey
  },
  dateContractUploaded: state => {
    return state.dateContractUploaded
  },
  storeFaciltyResponse: state => {
    return state.storeFaciltyResponse
  },
  getGuaranteeFund: state => {
    return state.getGuaranteeFund
  },
  getResponseRecipt: state => {
    return state.getResponseRecipt
  },
  retireMessage: state => {
    return state.retireMessage
  },
  disabledEdit: state => {
    return state.disabledEdit
  },
  importResponse: state => {
    return state.importResponse
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
