<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-width-1-4@xl">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-flex uk-flex-between uk-flex-top">
          <h4 class="uk-margin-remove-bottom">
            Mitra Prospek
          </h4>
          <img
            v-lazy="`${images}/icon/ic-user.svg`"
            alt="icon user"
          >
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <h1 class="uk-margin-top">
            {{ dataStatus.total_prospect || '0' }}
          </h1>
          <div>
            <span :class="dataPercentage.total_prospect > 0 ? 'status-plus' : 'status-minus'">
              {{ dataPercentage.total_prospect ? 
                handlePercentage(dataPercentage.total_prospect) : '0' }}%
            </span>
            <span> Dari Bulan Lalu</span>
          </div>
        </template>
      </div>
    </div>
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-width-1-4@xl">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-flex uk-flex-between uk-flex-top">
          <h4 class="uk-margin-remove-bottom">
            Mitra Potensial
          </h4>
          <img
            v-lazy="`${images}/icon/ic-chart.svg`"
            alt="icon user"
          >
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <h1 class="uk-margin-top">
            {{ dataStatus.total_potential || '0' }}
          </h1>
          <div>
            <span :class="dataPercentage.total_potential > 0 ? 'status-plus' : 'status-minus'">
              {{
                dataPercentage.total_potential ? 
                  handlePercentage(dataPercentage.total_potential)
                  :
                  '0'
              }}%
            </span>
            <span> Dari Bulan Lalu</span>
          </div>
        </template>
      </div>
    </div>
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-width-1-4@xl">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-flex uk-flex-between uk-flex-top">
          <h4 class="uk-margin-remove-bottom">
            Submit & Verifikasi
          </h4>
          <img
            v-lazy="`${images}/icon/ic-letter.svg`"
            alt="icon user"
          >
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <h1 class="uk-margin-top">
            {{ dataStatus.total_verification || '0' }}
          </h1>
          <div>
            <span :class="dataPercentage.total_verification > 0 ? 'status-plus' : 'status-minus'">
              {{
                dataPercentage.total_verification ? 
                  handlePercentage(dataPercentage.total_verification)
                  :
                  '0'
              }}%
            </span>
            <span> Dari Bulan Lalu</span>
          </div>
        </template>
      </div>
    </div>
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-2@m uk-width-1-4@l uk-width-1-4@xl">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-flex uk-flex-between uk-flex-top">
          <h4 class="uk-margin-remove-bottom">
            PARANJE Farmers
          </h4>
          <img
            v-lazy="`${images}/icon/ic-cage.svg`"
            alt="icon user"
          >
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <h1 class="uk-margin-top">
            {{ dataStatus.total_farmer || '0' }}
          </h1>
          <div>
            <span :class="dataPercentage.total_farmer > 0 ? 'status-plus' : 'status-minus'">
              {{
                dataPercentage.total_farmer ? 
                  handlePercentage(dataPercentage.total_farmer)
                  :
                  '0'
              }}%
            </span>
            <span> Dari Bulan Lalu</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { float1Decimals } from '@/utils/formater'
import ComponentLoading from '@/components/globals/loading'

export default {
  components: {
    ComponentLoading
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isLoading: true,
      dataStatus: {},
      dataPercentage: {}
    }
  },
  computed: {
    ...mapGetters({
      dataTotalStatus: 'acquisitionDashboard/dataTotalStatus',
      dataMeta: 'acquisitionDashboard/dataMeta'
    })
  },
  watch: {
    dataTotalStatus() {
      if (this.dataTotalStatus) {
        this.setDataStatusAndPercentage() 
      }
    }
  },
  methods: {
    handlePercentage(value) {
      return float1Decimals(value)
    },
    setDataStatusAndPercentage() {
      if (this.dataTotalStatus) {
        this.dataPercentage = this.dataTotalStatus.percent_dashboard_total_status || {}
        this.dataStatus = this.dataTotalStatus.dashboard_total_status || {}
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .wrapper-status {
    padding: 16px;
  }

  .wrapper-status h4 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .wrapper-status h1 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
  }

  .wrapper-status span {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .wrapper-status .status-minus {
    color: #F92626;
  }

  .wrapper-status .status-plus {
    color: #025231;
  }
</style>
