import Vue from 'vue'
import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_CYCLE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  cycles: {meta:{},data:[]},
  modalEdit: {},
  cycleHistory: {meta:{},data:[]},
  metaHistory: {page:1,limit:10},
  metaCycle: {page:1,limit:10},
  daily_resume: {meta:{},data:[]},
  metaResume: {
    page: 1,
    limit: 10
  },
  cycles_graph: {
    fcr: {x:[], y:{input:[], standard:[]}},
    body_weight: {x:[], y:{input:[], standard:[]}},
    feed_intake: {x:[], y:{input:[], standard:[]}},
    point_feed: {x:[], y:{input:[], standard:[]}},
    ip: {x:[], y:{input:[]}}
  },
  cycles_graph_dashboard: {},
  cycles_no_limit: {},
  cycles_loading: false
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_CYCLES": (state, payload) => {
    Vue.set(state, 'cycles', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_CYCLE_HISTORY": (state, payload) => {
    Vue.set(state, 'cycleHistory', payload)
  },
  "SET_META_HISTORY": (state, payload) => {
    Vue.set(state, 'metaHistory', payload)
  },
  "SET_META_CYCLE": (state, payload) => {
    Vue.set(state, 'metaCycle', payload)
  },
  "SET_CYCLES_GRAPH": (state, payload) => {
    Vue.set(state, 'cycles_graph', payload)
  },
  "SET_CYCLES_NO_LIMIT": (state, payload) => {
    Vue.set(state, 'cycles_no_limit', payload)
  },
  "SET_CYCLES_GRAPH_DASHBOARD": (state, payload) => {
    Vue.set(state, 'cycles_graph_dashboard', payload)
  },
  "SET_DAILY_RESUME": (state, payload) => {
    Vue.set(state, 'daily_resume', payload)
  },
  "SET_META_RESUME": (state, payload) => {
    Vue.set(state, 'metaResume', payload)
  },
  "SET_CYCLES_LOADING": (state, payload) => {
    Vue.set(state, 'cycles_loading', payload)
  }
}

const actions = {
  async getCycle(context, data) {
    try {
      const url = data.log_cycle_id ? `${data.cage_id}?log_cycle_id=${data.log_cycle_id}` : data.cage_id
      const response = await apiGetAuth(API_CYCLE.LIST_CYCLE(url), data.meta)
      context.commit('SET_CYCLES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async editCycle(context, data) {
    try {
      const response = await apiPutAuth(API_CYCLE.EDIT_CYCLE, data)
      return response.data
    } catch (err) {
      return err.response.data
    }
  },
  async getCycleHistory(context, data) {
    try {
      const response = await apiGetAuth(API_CYCLE.LIST_CYCLE_HISTORY(data.cage_id), data.meta)
      context.commit('SET_CYCLE_HISTORY', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCycleGraph(context, data) {
    try {
      const response = await apiGetAuth(API_CYCLE.GRAPH_CYCLE(data))
      context.commit('SET_CYCLES_GRAPH', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCycleNoLimit(context, data) {
    try {
      const response = await apiGetAuth(API_CYCLE.CYCLE_NO_LIMIT(data))
      context.commit('SET_CYCLES_NO_LIMIT', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCycleGraphDashboard(context, data) {
    try {
      const response = await apiPostAuth(API_CYCLE.GRAPH_CYCLE_DASHBOARD, data)
      context.commit('SET_CYCLES_GRAPH_DASHBOARD', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getDailyResume(context, data) {
    try {
      const response = await apiGetAuth(API_CYCLE.DAILY_RESUME, data)
      context.commit('SET_DAILY_RESUME', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  cycles: state => {
    return state.cycles
  },
  modalEdit: state => {
    return state.modalEdit
  },
  cycleHistory: state => {
    return state.cycleHistory
  },
  metaHistory: state => {
    return state.metaHistory
  },
  metaCycle: state => {
    return state.metaCycle
  },
  cycles_graph: state => {
    return state.cycles_graph
  },
  cycles_no_limit: state => {
    return state.cycles_no_limit
  },
  cycles_graph_dashboard: state => {
    return state.cycles_graph_dashboard
  },
  daily_resume: state => {
    return state.daily_resume
  },
  metaResume: state => {
    return state.metaResume
  },
  cycles_loading: state => {
    return state.cycles_loading
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
