<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div>
          <h4 class="title uk-margin-small-bottom label-green">
            Yet To Follow Up
          </h4>
        </div>
        <template>
          <div class="uk-padding uk-padding-remove-top">
            <div
              v-if="!isNoData"
              class="square"
            >
              <div 
                class="rectangle bg-submit-ver"
                :style="{ height: squareHeight(dataStatusMitra.submit_and_verification )+ 'px' }"
              >
                <div class="dialog-box">
                  <div class="dialog-text">
                    Submit & Verifikasi
                  </div>
                  <div class="percentage-text">
                    <div class="square-inner bg-submit-ver uk-inline uk-margin-small-right" />{{ handlePercentage(dataStatusMitra.submit_and_verification) }}%
                  </div>
                  <div class="triangle" />
                </div>
              </div>
              <div 
                class="rectangle bg-potential"
                :style="{ height: squareHeight(dataStatusMitra.mitra_potential )+ 'px' }"
              >
                <div class="dialog-box">
                  <div class="dialog-text">
                    Mitra Potensial
                  </div>
                  <div class="percentage-text">
                    <div class="square-inner bg-potential uk-inline uk-margin-small-right" />{{ handlePercentage(dataStatusMitra.mitra_potential) }}%
                  </div>
                  <div class="triangle" />
                </div>
              </div>
              <div 
                class="rectangle bg-prospect"
                :style="{ height: squareHeight(dataStatusMitra.mitra_prospect )+ 'px' }"
              >
                <div class="dialog-box">
                  <div class="dialog-text">
                    Mitra Prospek
                  </div>
                  <div class="percentage-text">
                    <div class="square-inner bg-prospect uk-inline uk-margin-small-right" />{{ handlePercentage(dataStatusMitra.mitra_prospect) }}%
                  </div>
                  <div class="triangle" />
                </div>
              </div>
            </div>
            <div
              v-else
            >
              <div class="square">
                <div
                  class="uk-position-center"
                >
                  No Data
                </div>
              </div>
            </div>
            <div class="uk-margin-medium-top">
              <div
                v-for="item in rectangles"
                :key="item.key"
              >
                <div
                  class="rectangle-outer uk-inline"
                  :class="item.class"
                  :style="{ order: item.order }"
                />
                {{ item.label }}
                <br>
              </div>
              <div 
                v-if="rectangles.length<1"
                class="uk-text-center uk-position-absolute"
              >
                No Data
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { float0Decimals } from '@/utils/formater'

export default {
  data() {
    return {
      isLoading: true,
      isNoData: false,
      dataStatusMitra:{
        submit_and_verification : 0,
        mitra_potential: 0,
        mitra_prospect: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      dataStatusMitraList: 'acquisitionDashboard/dataStatusMitra'
    }),
    rectangles() {
      const rectangles = [
        { key: 'submit_and_verification', class: 'bg-submit-ver', label: 'Submit & Verifikasi' },
        { key: 'mitra_potential', class: 'bg-potential', label: 'Mitra Potensial' },
        { key: 'mitra_prospect', class: 'bg-prospect', label: 'Mitra Prospek' }
      ]

      return rectangles.map(rectangle => ({
        ...rectangle,
        order: this.dataStatusMitra[rectangle.key]
      })).filter(rectangle => rectangle.order > 0)
        .sort((a, b) => b.order - a.order)
    }
  },
  watch: {
    dataStatusMitraList() {
      if (this.dataStatusMitraList) {
        this.dataStatusMitra = this.dataStatusMitraList
        if(this.dataStatusMitra.mitra_prospect == 0 && this.dataStatusMitra.mitra_potential == 0 && this.dataStatusMitra.submit_and_verification == 0){
          this.isNoData = true
        } else {
          this.isNoData = false
        }
        this.isLoading = false
      }

    }
  },
  methods: {
    handlePercentage(value) {
      return float0Decimals(value)
    },
    squareHeight(value) {
      const totalHeight = 300
      return (value / 100) * totalHeight
    }
  }
}
</script>

<style scoped>
  .wrapper-status {
    padding: 16px;
  }

  .wrapper-status h4 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 5px;
  }

  .wrapper-status p {
    font-family: ClanOT;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #000000;
    margin-top: 0;
  }


  .wrapper-status .title {
    font-size: 20px;
    font-weight: 700;
  }

  /* square */
  .square {
  position: relative;
  height: 300px;
}

.rectangle {
  width: 100%;
  left: 0;
}


.dialog-box {
  z-index: 9999;
  display: none;
  position: relative;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  background-color:rgba(255, 255, 255, 1);
  padding: 16px;
  text-align: left;
  width: 120px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(133, 157, 173, 0.30);
}

.dialog-text {
  font-family: ClanOTMedium;
  font-size: 12px;
  font-weight: 500;
  color: #025231;
}

.percentage-text {
  font-size: 12px;
  font-weight: 500;
  color: #025231;
  margin-top: 10px;
}

.triangle {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(255, 255, 255, 1);
  border-width: 15px;
}

.rectangle:hover .dialog-box {
  display: block;
}

.rectangle:hover .dialog-box .rectangle {
  height: 50%;
}

.square-inner {
  width: 14px;
  height: 14px;
}

.rectangle-outer{
  width: 18px;
  height: 8px;
}

.bg-submit-ver{
  background: rgba(82, 224, 165, 1) !important;
}

.bg-potential{
  background: #826AF9 !important;
}

.bg-prospect{
  background: rgba(158, 134, 255, 1) !important;
}

</style>
