<template>
  <div
    class="uk-child-width-expand@s"
    uk-grid
  >
    <div class="uk-width-1-1">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div class="uk-width-1-1">
          <div class="uk-flex uk-flex-between uk-flex-middle">
            <h4 class="uk-margin-remove label-green">
              Closing Rates By Acquisition Stages
            </h4>
            <div class="uk-width-1-2@s uk-flex uk-flex-right">
              <label
                class="uk-form-label uk-margin-right filter-margin"
                for="form-horizontal-text"
              >Periode</label>
              <div class="uk-form-controls">
                <div
                  uk-grid
                  class="uk-grid-small"
                >
                  <div class="uk-width-1-1">
                    <div v-if="showDatepicker">
                      <span class="uk-margin-left uk-padding">Masukkan Tahun Akhir Priode!</span>
                      <Datepicker
                        v-model="dateRangeChose"
                        format="yyyy - yyyy"
                        input-class="uk-input"
                        minimum-view="year"
                        inline
                        :disabled-dates="{to: new Date(2019, 12, 31)}"
                        @blur="showDatepicker = false"
                        @input="handleDateRangeChange"
                      />
                    </div>
                    <div
                      v-else
                      class="uk-inline"
                    >
                      <input
                        style="background: #fff !important;"
                        :value="dateRange"
                        placeholder="YYYY - YYYY"
                        class="uk-input"
                        tabindex="-1"
                        disabled
                      >
                      <span
                        style="pointer-events: all; max-width:50px;"
                        class="uk-form-icon uk-form-icon-flip"
                        uk-icon="icon:   chevron-down"
                        @click="toggleDatepicker"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="uk-width-1-1">
          <ComboBarLineChart 
            :chart-data="chartData"
            :options="chartOption"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import { PREFIX_IMAGE, PARTNER_STATUS } from "@/utils/constant"
import { getYearForRange, float0Decimals } from "@/utils/formater"
import Datepicker from "vuejs-datepicker"
import ComboBarLineChart from '@/components/globals/chart/ComboBarLineChart.js'

export default {
  name: 'Range',
  components: {
    Datepicker,
    ComboBarLineChart
  },
  data() {
    return {
      showDatepicker: false,
      images: PREFIX_IMAGE,
      listStatusPartner: PARTNER_STATUS,
      propsPayload: {},
      dataListAcquisition: {
        isLoading: true,
        data: [],
        meta: {
          total_count: 0
        }
      },
      tableProps: {
        total_count: 0
      },
      dateRange: null,
      start_year: null,
      end_year: null,
      dateRangeChose: '',
      chartOption:{
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes:[{
            id: 'x',
            ticks: {
              fontColor: '#3D5C4F',
              fontStyle: 'bold'
            }
          }],
          yAxes: [{
            id: 'y',
            beginAtZero: true,
            ticks: {
              fontColor: '#049559',
              fontStyle: 'bold',
              callback: function(value) {
                return value + '%'
              }
            }
          },{
            id: 'prospect',
            ticks:{
              fontColor: '#049559',
              fontStyle: 'bold'
            },
            position: 'right'
          }]
        }
      },
      chartData: {}
    }
  },
  computed: {
    ...mapGetters({
      dataClosingRate: 'acquisitionDashboard/dataClosingRate',
      dataMetaClosingRate: "acquisitionDashboard/dataMetaClosingRate"
    })
  },
  watch: {
    async dataMeta() {
      await this.getDataList(this.dataMeta)
    },
    dataClosingRate() {
      if (this.dataClosingRate){
        this.handleChartData(this.dataClosingRate)
      }
    }
  },
  async mounted() {
    this.dateRange = new Date().getFullYear() - 1 + ' - ' + new Date().getFullYear()
    this.setDataMetaClosingRate({
      ...this.dataMetaClosingRate,
      end_year: new Date().getFullYear(),
      start_year: new Date().getFullYear() - 1
    })
    await this.fetchAcquisitionClosingRate(this.dataMetaClosingRate)
    await this.getDataList(this.dataMeta)
  },
  methods: {
    handleChartData(dataClosingRate) {
      this.chartData = {
        labels: dataClosingRate.range.map((date) => {
          const [month, year] = date.split('-')
          return month + ' ' + year.slice(-2)
        }),
        datasets: [
          {
            type: 'bar',
            label: 'Akumulatif Mitra Prospek',
            data: dataClosingRate.mitra_prospect.map(this.handlePercentage),
            backgroundColor: '#049559',
            borderColor: '#049559',
            borderWidth: 1,
            order: 4,
            yAxisID: 'prospect'
          },
          {
            type: 'line',
            label: 'Akumulatif Mitra Potensial',
            data: dataClosingRate.mitra_potential.map(this.handlePercentage),
            backgroundColor: 'transparent',
            borderColor: '#ED5E5E',
            borderWidth: 2,
            fill: false,
            tension: 0.1,
            pointBackgroundColor: '#ED5E5E',
            order: 3
          },
          {
            type: 'line',
            label: 'Akumulatif Submit & Verifikasi',
            data: dataClosingRate.submit_and_verification.map(this.handlePercentage),
            backgroundColor: 'transparent',
            borderColor: '#52E0A5',
            borderWidth: 2,
            fill: false,
            tension: 0.1,
            pointBackgroundColor: '#52E0A5',
            order: 2
          },
          {
            type: 'line',
            label: 'Akumulatif Paranje Farmers',
            data: dataClosingRate.mitra_farmer.map(this.handlePercentage),
            backgroundColor: 'transparent',
            borderColor: '#EDA55C',
            borderWidth: 2,
            fill: false,
            tension: 0.1,
            pointBackgroundColor: '#EDA55C)',
            order: 1
          }
        ]
      }
    },
    handleDateRangeChange() {
      if (this.dateRangeChose) {
        this.endDate =  getYearForRange(this.dateRangeChose)
        this.startDate =  parseInt(this.endDate) - 1
        this.dateRange = `${this.startDate} - ${this.endDate}`
        this.handleAcquisitionRate(this.endDate, this.startDate)

      } else {
        this.startDate = null
        this.endDate = null
        this.dateRangeChose = null
      }
      this.showDatepicker = false
    },
    async handleAcquisitionRate(end, start){
      this.setDataMetaClosingRate({
        ...this.dataMetaClosingRate,
        end_year: end,
        start_year: start
      })
      this.fetchAcquisitionClosingRate(this.dataMetaClosingRate)
    },
    toggleDatepicker() {
      // this.dateRangeChose = this.dateRange
      this.showDatepicker = true
    },
    handlePercentage(value) {
      return float0Decimals(value)
    },
    ...mapActions({
      fetchAcquisitionClosingRate: "acquisitionDashboard/fetchClosingRate"
    }),
    ...mapMutations({
      setDataMeta: "acquisition/SET_DATA_META",
      setDataMetaClosingRate: "acquisitionDashboard/SET_DATA_META_CLOSING_RATE"
    })
  }
}
</script>

<style>
.wrapper-status {
  padding: 16px;
}

.wrapper-status h4 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bolder;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
}

.wrapper-status a span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #025231;
}
</style>
