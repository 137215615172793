import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth } from '@/utils/api'
import { API_CHICKEN_CATEGORY } from '@/utils/api-url'
import {
  notificationDanger,
  notificationSuccess
} from '@/utils/notification'


const state = {
  chickenCategorys: {meta:{},data:[]},
  chickenCategoryDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {}
}

const mutations = {
  "SET_CHICKEN_CATEGORYS": (state, payload) => {
    Vue.set(state, 'chickenCategorys', payload)
  },
  "SET_CHICKEN_CATEGORY_DETAIL": (state, payload) => {
    Vue.set(state, 'chickenCategoryDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  }
}

const actions = {
  async getChickenCategory(context, data) {
    try {
      const response = await apiGetAuth(API_CHICKEN_CATEGORY.LIST, data)
      context.commit('SET_CHICKEN_CATEGORYS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getChickenCategoryDetail(context, data) {
    try {
      const response = await apiGetAuth(API_CHICKEN_CATEGORY.DETAIL(data))
      context.commit('SET_CHICKEN_CATEGORY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importChickenCategory(context, data) {
    try {
      const response = await apiPostAuth(API_CHICKEN_CATEGORY.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteUser(context, data) {
    try {
      await apiDeleteAuth(API_CHICKEN_CATEGORY.DELETE(data))
      const succesMsg = `User has been deleted`
      notificationSuccess(succesMsg)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  chickenCategorys: state => {
    return state.chickenCategorys
  },
  chickenCategoryDetail: state => {
    return state.chickenCategoryDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
