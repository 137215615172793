import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_UKIRAMA, API_SYNC_ITEM } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  feedCategorys: {meta:{},data:[]},
  meta: {page:1,limit:10},
  syncFeedCategory: {}
}

const mutations = {
  "SET_FEED_CATEGORYS": (state, payload) => {
    Vue.set(state, 'feedCategorys', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SYNC_FEED": (state, payload) => {
    Vue.set(state, 'syncFeedCategory', payload)
  }
}

const actions = {
  async getFeedCategory(context, data) {
    try {
      const response = await apiGetAuth(API_UKIRAMA.LIST, data)
      context.commit('SET_FEED_CATEGORYS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async syncFeedCategory(context, data) {
    try {
      const response = await apiGetAuth(API_SYNC_ITEM.LIST, data)
      context.commit('SYNC_FEED', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  feedCategorys: state => {
    return state.feedCategorys
  },
  meta: state => {
    return state.meta
  },
  syncFeedCategory: state => {
    return state.syncFeedCategory
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
