import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_CAGE_CATEGORY } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  cageCategorys: {meta:{},data:[]},
  cageCategoryDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  deleteCageCategory: {},
  modalEdit: {},
  modalAdd: {},
  addCageCategory: {},
  updateCageCategory: {}
}

const mutations = {
  "SET_CAGE_CATEGORYS": (state, payload) => {
    Vue.set(state, 'cageCategorys', payload)
  },
  "SET_CAGE_CATEGORY_DETAIL": (state, payload) => {
    Vue.set(state, 'cageCategoryDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_RESPONSE_DELETE": (state, payload) => {
    Vue.set(state, 'deleteCageCategory', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addCageCategory', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateCageCategory', payload)
  }
}

const actions = {
  async getCageCategory(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE_CATEGORY.LIST, data)
      context.commit('SET_CAGE_CATEGORYS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCageCategoryDetail(context, data) {
    try {
      const response = await apiGetAuth(API_CAGE_CATEGORY.DETAIL(data))
      context.commit('SET_CAGE_CATEGORY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importCageCategory(context, data) {
    try {
      const response = await apiPostAuth(API_CAGE_CATEGORY.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteCageCategory(context, data) {
    try {
      const response = await apiDeleteAuth(API_CAGE_CATEGORY.DELETE(data))
      context.commit('SET_RESPONSE_DELETE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveCageCategory(context, data) {
    try {
      const response = await apiPostAuth(API_CAGE_CATEGORY.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async updateCageCategory(context, data) {
    try {
      const response = await apiPutAuth(API_CAGE_CATEGORY.UPDATE(data.id), data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  cageCategorys: state => {
    return state.cageCategorys
  },
  cageCategoryDetail: state => {
    return state.cageCategoryDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  getDeleteCageCategoryMsg: state => {
    return state.deleteCageCategory
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  getAddCageCategoryMsg: state => {
    return state.addCageCategory
  },
  getUpdateCageCategoryMsg: state => {
    return state.updateCageCategory
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
