import Vue from 'vue'
import Router from 'vue-router'
import { isLogin, getUserRoleInfo } from '@/utils/auth'
import Login from '@/components/pages/Login'
import Dashboard from '@/components/pages/admin/dashboard/index'
import DashboardAcquisition from '@/components/pages/admin/dashboard/acquisition/index'
import AdminLayout from '@/components/layouts/Admin'
import EmptyPageLayout from '@/components/layouts/EmptyPage'

Vue.use(Router)

const router =  new Router({
  base: '',
  mode: 'history', // remove # in url
  routes: [
    {
      path: '/',
      component: EmptyPageLayout,
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/forgot',
          name: 'Forgot',
          component: () => import(/* webpackChunkName: "forgot" */ '@/components/pages/Forgot'),
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        },
        {
          path: '/lupa-password/:token',
          name: 'ForgotPassword',
          component: () => import(/* webpackChunkName: "forgot-password" */ '@/components/pages/ForgotPassword/index'),
          meta: {
            public: true,
            onlyWhenLoggedOut: true
          }
        }
      ]
    },
    {
      path: '/admin',
      component: AdminLayout,
      redirect: '/admin/dashboard',
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            menu: 'Dashboard',
            type: 'view'
          }
        },
        {
          path: 'dashboard-acquisition',
          name: 'Dashboard Akuisisi',
          component: DashboardAcquisition,
          meta: {
            menu: 'Dashboard Akuisisi',
            type: 'view'
          }
        },
        {
          path: 'daily-resume',
          name: 'Daily Resume',
          component: () => import(/* webpackChunkName: "daily-resume" */ '@/components/pages/admin/daily-resume/index'),
          meta: {
            menu: 'Daily Resume',
            type: 'view'
          }
        },
        // PROFILE
        {
          path: 'profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "profile" */ '@/components/pages/admin/profile')
        },
        {
          path: 'profile/edit',
          name: 'Ubah Profile',
          component: () => import(/* webpackChunkName: "edit-profile" */ '@/components/pages/admin/profile/edit')
        },
        // CHANGE PASSWORD
        {
          path: 'change-password',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "change-password" */ '@/components/pages/admin/change-password')
        },
        // THE FARM
        {
          path: 'peternakan',
          name: 'Peternakan',
          component: () => import(/* webpackChunkName: "peternakan" */ '@/components/pages/admin/farm/peternakan'),
          meta: {
            menu: 'Peternakan',
            type: 'view'
          }
        },
        {
          path: 'peternakan/add',
          name: 'Tambah Peternakan',
          component: () => import(/* webpackChunkName: "add-Peternakan" */ '@/components/pages/admin/farm/peternakan/add'),
          meta: {
            menu: 'Peternakan',
            type: 'add'
          }
        },
        {
          path: 'peternakan/detail/:id',
          name: 'Detail Peternakan',
          component: () => import(/* webpackChunkName: "detail-peternakan" */ '@/components/pages/admin/farm/peternakan/detail'),
          meta: {
            menu: 'Peternakan',
            type: 'view'
          }
        },
        {
          path: 'peternakan/edit/:id',
          name: 'Ubah Peternakan',
          component: () => import(/* webpackChunkName: "edit-peternakan" */ '@/components/pages/admin/farm/peternakan/edit'),
          meta: {
            menu: 'Peternakan',
            type: 'edit'
          }
        },
        // CHICKEN COOP
        {
          path: 'kandang-ayam',
          name: 'Kandang Ayam',
          component: () => import(/* webpackChunkName: "kandang-ayam" */ '@/components/pages/admin/farm/kandang-ayam'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'view'
          }
        },
        {
          path: 'kandang-ayam/add',
          name: 'Tambah Kandang Ayam',
          component: () => import(/* webpackChunkName: "add-kandang-ayam" */ '@/components/pages/admin/farm/kandang-ayam/add'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'add'
          }
        },
        {
          path: 'kandang-ayam/detail/:id',
          name: 'Detail Kandang Ayam',
          component: () => import(/* webpackChunkName: "detail-kandang-ayam" */ '@/components/pages/admin/farm/kandang-ayam/detail'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'view'
          }
        },
        {
          path: 'kandang-ayam/detail/riwayat-siklus/:id',
          name: 'Detail Riwayat Kandang Ayam',
          component: () => import(/* webpackChunkName: "detail-riwayat-siklus-kandang-ayam" */ '@/components/pages/admin/farm/kandang-ayam/detail/riwayat-siklus')
        },
        {
          path: 'kandang-ayam/edit/:id',
          name: 'Ubah Kandang Ayam',
          component: () => import(/* webpackChunkName: "edit-kandang-ayam" */ '@/components/pages/admin/farm/kandang-ayam/edit'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'edit'
          }
        },
        {
          path: 'kandang-ayam/edit-siklus/:id',
          name: 'Ubah Data Siklus',
          component: () => import(/* webpackChunkName: "edit-data-siklus" */ '@/components/pages/admin/farm/kandang-ayam/edit-siklus'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'edit'
          }
        },
        {
          path: 'kandang-ayam/import',
          name: 'Import Kandang Ayam',
          component:  () => import(/* webpackChunkName: "edit-kegiatan-kandang" */ '@/components/pages/admin/farm/kandang-ayam/import'),
          meta: {
            menu: 'Kandang Ayam',
            type: 'add'
          }
        },
        // ACTIVITY CAGE
        {
          path: 'kegiatan-kandang',
          name: 'Kegiatan Kandang',
          component: () => import(/* webpackChunkName: "kegiatan-kandang" */ '@/components/pages/admin/farm/kegiatan-kandang'),
          meta: {
            menu: 'Kegiatan Kandang',
            type: 'view'
          }
        },
        {
          path: 'kegiatan-kandang/add',
          name: 'Tambah Kegiatan Kandang',
          component: () => import(/* webpackChunkName: "add-kegiatan-kandang" */ '@/components/pages/admin/farm/kegiatan-kandang/add'),
          meta: {
            menu: 'Kegiatan Kandang',
            type: 'add'
          }
        },
        {
          path: 'kegiatan-kandang/detail/:id',
          name: 'Detail Kegiatan Kandang',
          component: () => import(/* webpackChunkName: "detail-kegiatan-kandang" */ '@/components/pages/admin/farm/kegiatan-kandang/detail'),
          meta: {
            menu: 'Kegiatan Kandang',
            type: 'view'
          }
        },
        {
          path: 'kegiatan-kandang/edit/:id',
          name: 'Ubah Kegiatan Kandang',
          component:  () => import(/* webpackChunkName: "edit-kegiatan-kandang" */ '@/components/pages/admin/farm/kegiatan-kandang/edit'),
          meta: {
            menu: 'Kegiatan Kandang',
            type: 'edit'
          }
        },
        {
          path: 'kegiatan-kandang/import',
          name: 'Import Kegiatan Kandang',
          component:  () => import(/* webpackChunkName: "edit-kegiatan-kandang" */ '@/components/pages/admin/farm/kegiatan-kandang/import'),
          meta: {
            menu: 'Kegiatan Kandang',
            type: 'add'
          }
        },
        // SAPRONAK
        {
          path: 'sapronak',
          name: 'Sapronak',
          component:  () => import(/* webpackChunkName: "sapronak" */ '@/components/pages/admin/farm/sapronak'),
          meta: {
            menu: 'Sapronak',
            type: 'view'
          }
        },
        {
          path: 'sapronak/detail-sapronak/:id',
          name: 'Detail Sapronak',
          component:  () => import(/* webpackChunkName: "detail-sapronak" */ '@/components/pages/admin/farm/sapronak/detailSapronak'),
          meta: {
            menu: 'Sapronak',
            type: 'edit'
          }
        },
        {
          path: 'sapronak/detail-retur/:id',
          name: 'Detail Retur',
          component:  () => import(/* webpackChunkName: "detail-retur" */ '@/components/pages/admin/farm/sapronak/detailRetur'),
          meta: {
            menu: 'Sapronak',
            type: 'edit'
          }
        },

        // AKUISISI
        {
          path: 'akuisisi',
          name: 'Akuisisi',
          component:  () => import(/* webpackChunkName: "acquisition" */ '@/components/pages/admin/farm/akuisisi'),
          meta: {
            menu: 'Akuisisi',
            type: 'view'
          }
        },
        {
          path: 'akuisisi/add',
          name: 'Tambah Mitra',
          component:  () => import(/* webpackChunkName: "add-acquisition" */ '@/components/pages/admin/farm/akuisisi/form-mitra'),
          meta: {
            menu: 'Akuisisi',
            type: 'add'
          }
        },
        {
          path: 'akuisisi/edit/:id',
          name: 'Update Mitra',
          component:  () => import(/* webpackChunkName: "edit-acquisition" */ '@/components/pages/admin/farm/akuisisi/form-mitra'),
          meta: {
            menu: 'Akuisisi',
            type: 'edit'
          }
        },
        {
          path: 'akuisisi/detail/:id',
          name: 'Detail Mitra',
          component:  () => import(/* webpackChunkName: "detail-acquisition" */ '@/components/pages/admin/farm/akuisisi/form-mitra'),
          meta: {
            menu: 'Akuisisi',
            type: 'view'
          }
        },
        {
          path: 'akuisisi/detail/:id/mitra-potensial',
          name: 'Mitra Potensial',
          component:  () => import(/* webpackChunkName: "mitra-potensial" */ '@/components/pages/admin/farm/akuisisi/form-mitra/Component/mitra-potensial'),
          meta: {
            menu: 'Akuisisi',
            type: 'edit'
          }
        },
        {
          path: 'akuisisi/detail/:id/submit-and-verification',
          name: 'Submit & Verifikasi',
          component:  () => import(/* webpackChunkName: "submit-and-verification" */ '@/components/pages/admin/farm/akuisisi/form-mitra/Component/submit-and-verification'),
          meta: {
            menu: 'Akuisisi',
            type: 'edit'
          }
        },
        {
          path: 'akuisisi/import',
          name: 'Import Akuisisi',
          component:  () => import(/* webpackChunkName: "edit-kegiatan-kandang" */ '@/components/pages/admin/farm/akuisisi/import'),
          meta: {
            menu: 'Akuisisi',
            type: 'add'
          }
        },
        // PERMINTAAN PANEN
        {
          path: 'permintaan-panen',
          name: 'Permintaan Panen',
          component:  () => import(/* webpackChunkName: "permintaan-panen" */ '@/components/pages/admin/farm/permintaan-panen'),
          meta: {
            menu: 'Permintaan Panen',
            type: 'view'
          }
        },
        {
          path: 'permintaan-panen/detail/:id',
          name: 'Detail Permintaan Panen',
          component:  () => import(/* webpackChunkName: "detail-permintaan-panen" */ '@/components/pages/admin/farm/permintaan-panen/detail'),
          meta: {
            menu: 'Permintaan Panen',
            type: 'edit'
          }
        },
        // RHPP
        {
          path: 'rhpp',
          name: 'Rekapitulasi Hasil Produksi Peternak',
          component: () => import(/* webpackChunkName: "list-RHPP" */ '@/components/pages/admin/farm/rhpp/list'),
          meta: {
            menu: 'RHPP',
            type: 'view'
          }
        },
        {
          path: 'rhpp/add',
          name: 'Tambah RHPP',
          component: () => import(/* webpackChunkName: "add-RHPP" */ '@/components/pages/admin/farm/rhpp/add'),
          meta: {
            menu: 'RHPP',
            type: 'add'
          }
        },
        {
          path: 'rhpp/edit/:id',
          name: 'Edit RHPP',
          component: () => import(/* webpackChunkName: "edit-RHPP" */ '@/components/pages/admin/farm/rhpp/add'),
          meta: {
            menu: 'RHPP',
            type: 'edit'
          }
        },
        {
          path: 'rhpp/detail/:id/:cycle_id',
          name: 'Detail RHPP',
          component: () => import(/* webpackChunkName: "detail-RHPP" */ '@/components/pages/admin/farm/rhpp/add'),
          meta: {
            menu: 'RHPP',
            type: 'detail'
          }
        },
        // MASTER
        // JENIS AYAM
        {
          path: 'jenis-ayam',
          name: 'Jenis Ayam',
          component: () => import(/*webpackChunkName: "jenis-ayam" */ '@/components/pages/admin/master/jenis-ayam')
        },
        {
          path: 'jenis-ayam/detail/:id',
          name: 'Detail Jenis Ayam',
          component: () => import(/*webpackChunkName: "detail-jenis-ayam" */ '@/components/pages/admin/master/jenis-ayam/detail')
        },
        {
          path: 'jenis-ayam/import',
          name: 'Import Jenis Ayam',
          component: () => import(/*webpackChunkName: "import-jenis-ayam" */ '@/components/pages/admin/master/jenis-ayam/import')
        },
        //JENIS KANDANG
        {
          path: 'jenis-kandang',
          name: 'Jenis Kandang',
          component: () => import(/*webpackChunkName: "jenis-kandang" */ '@/components/pages/admin/master/jenis-kandang'),
          meta: {
            menu: 'Kategori Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'jenis-kandang/add',
          name: 'Tambah Jenis Kandang',
          component: () => import(/*webpackChunkName: "add-jenis-kandang" */ '@/components/pages/admin/master/jenis-kandang/add')
        },
        {
          path: 'jenis-kandang/detail/:id',
          name: 'Detail Jenis Kandang',
          component: () => import(/*webpackChunkName: "detail-jenis-kandang" */ '@/components/pages/admin/master/jenis-kandang/detail'),
          meta: {
            menu: 'Jenis Kandang',
            type: 'view'
          }
        },
        {
          path: 'jenis-kandang/edit/:id',
          name: 'Edit Jenis Kandang',
          component: () => import(/*webpackChunkName: "edit-jenis-kandang" */ '@/components/pages/admin/master/jenis-kandang/edit'),
          meta: {
            menu: 'Jenis Kandang',
            type: 'edit'
          }
        },
        {
          path: 'jenis-kandang/import',
          name: 'Import Jenis Kandang',
          component: () => import(/*webpackChunkName: "import-jenis-kandang" */ '@/components/pages/admin/master/jenis-kandang/import'),
          meta: {
            menu: 'Jenis Kandang',
            type: 'add'
          }
        },
        //JENIS PAKAN
        {
          path: 'jenis-pakan',
          name: 'Jenis Pakan',
          component: () => import(/*webpackChunkName: "jenis-pakan" */ '@/components/pages/admin/master/jenis-pakan'),
          meta: {
            menu: 'Kategori Kegiatan',
            type: 'view'
          }
        },
        // TAHAPAN
        {
          path: 'tahapan',
          name: 'Tahapan',
          component: () => import(/*webpackChunkName: "tahapan" */ '@/components/pages/admin/master/tahapan'),
          meta: {
            menu: 'Tahapan',
            type: 'view'
          }
        },
        {
          path: 'tahapan/add',
          name: 'Tambah Tahapan',
          component: () => import(/*webpackChunkName: "add-tahapan" */ '@/components/pages/admin/master/tahapan/add')
        },
        {
          path: 'tahapan/detail/:id',
          name: 'Detail Tahapan',
          component: () => import(/*webpackChunkName: "detail-tahapan" */ '@/components/pages/admin/master/tahapan/detail'),
          meta: {
            menu: 'Tahapan',
            type: 'view'
          }
        },
        {
          path: 'tahapan/edit/:id',
          name: 'Edit Tahapan',
          component: () => import(/*webpackChunkName: "edit-tahapan" */ '@/components/pages/admin/master/tahapan/edit'),
          meta: {
            menu: 'Tahapan',
            type: 'edit'
          }
        },
        {
          path: 'tahapan/import',
          name: 'Import Tahapan',
          component: () => import(/*webpackChunkName: "import-tahapan" */ '@/components/pages/admin/master/tahapan/import'),
          meta: {
            menu: 'Tahapan',
            type: 'add'
          }
        },
        // KATEGORI KEGIATAN
        {
          path: 'kategori-kegiatan',
          name: 'Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "kategori-kegiatan" */ '@/components/pages/admin/master/kategori/kegiatan'),
          meta: {
            menu: 'Kategori Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'kategori-kegiatan/add',
          name: 'Tambah Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "add-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/kegiatan/add')
        },
        {
          path: 'kategori-kegiatan/edit/:id',
          name: 'Edit Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "edit-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/kegiatan/edit')
        },
        {
          path: 'kategori-kegiatan/detail/:id',
          name: 'Detail Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "detail-kategori-" */ '@/components/pages/admin/master/kategori/kegiatan/detail'),
          meta: {
            menu: 'Kategori Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'kategori-kegiatan/import',
          name: 'Import Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "import-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/kegiatan/import'),
          meta: {
            menu: 'Kategori Kegiatan',
            type: 'add'
          }
        },
        // SUB KATEGORI KEGIATAN
        {
          path: 'sub-kategori-kegiatan',
          name: 'Sub Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "sub-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/sub-kegiatan'),
          meta: {
            menu: 'Sub Kategori Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'sub-kategori-kegiatan/add',
          name: 'Tambah Sub Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "add-sub-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/sub-kegiatan/add')
        },
        {
          path: 'sub-kategori-kegiatan/detail/:id',
          name: 'Sub Detail Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "detail-sub-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/sub-kegiatan/detail'),
          meta: {
            menu: 'Sub Kategori Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'sub-kategori-kegiatan/edit/:id',
          name: 'Edit Sub Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "edit-sub-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/sub-kegiatan/edit'),
          meta: {
            menu: 'Sub Kategori Kegiatan',
            type: 'edit'
          }
        },
        {
          path: 'sub-kategori-kegiatan/import',
          name: 'Import Sub Kategori Kegiatan',
          component: () => import(/*webpackChunkName: "import-sub-kategori-kegiatan" */ '@/components/pages/admin/master/kategori/sub-kegiatan/import'),
          meta: {
            menu: 'Sub Kategori Kegiatan',
            type: 'add'
          }
        },
        // DETAIL KEGIATAN
        {
          path: 'detail-kegiatan',
          name: 'Detail Kegiatan',
          component: () => import(/*webpackChunkName: "detail-kegiatan" */ '@/components/pages/admin/master/kategori/detail-kegiatan'),
          meta: {
            menu: 'Detail Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'detail-kegiatan/add',
          name: 'Tambah Detail Kegiatan',
          component: () => import(/*webpackChunkName: "add-detail-kegiatan" */ '@/components/pages/admin/master/kategori/detail-kegiatan/add')
        },
        {
          path: 'detail-kegiatan/detail/:id',
          name: 'Detail Kegiatan Detail',
          component: () => import(/*webpackChunkName: "detail-kegiatan-detail" */ '@/components/pages/admin/master/kategori/detail-kegiatan/detail'),
          meta: {
            menu: 'Detail Kegiatan',
            type: 'view'
          }
        },
        {
          path: 'detail-kegiatan/edit/:id',
          name: 'Edit Detail Kegiatan',
          component: () => import(/*webpackChunkName: "edit-detail-kegiatan" */ '@/components/pages/admin/master/kategori/detail-kegiatan/edit'),
          meta: {
            menu: 'Detail Kegiatan',
            type: 'edit'
          }
        },
        {
          path: 'detail-kegiatan/import',
          name: 'Import Detail Kegiatan',
          component: () => import(/*webpackChunkName: "import-detail-kegiatan" */ '@/components/pages/admin/master/kategori/detail-kegiatan/import'),
          meta: {
            menu: 'Detail Kegiatan',
            type: 'add'
          }
        },
        // DETAIL SOP
        {
          path: 'kategori-sop',
          name: 'Kategori SOP',
          component: () => import(/*webpackChunkName: "kategori-sop" */ '@/components/pages/admin/master/sop/kategori-sop'),
          meta: {
            menu: 'Kategori SOP',
            type: 'view'
          }
        },
        {
          path: 'kategori-sop/add',
          name: 'Tambah Kategori SOP',
          component: () => import(/*webpackChunkName: "add-kategori-sop" */ '@/components/pages/admin/master/sop/kategori-sop/add')
        },
        {
          path: 'kategori-sop/edit/:id',
          name: 'Edit Kategori SOP',
          component: () => import(/*webpackChunkName: "edit-kategori-sop" */ '@/components/pages/admin/master/sop/kategori-sop/edit')
        },
        {
          path: 'detail-sop',
          name: 'Detail SOP',
          component: () => import(/*webpackChunkName: "detail-sop" */ '@/components/pages/admin/master/sop/detail-sop'),
          meta: {
            menu: 'Detail SOP',
            type: 'view'
          }
        },
        {
          path: 'kategori-sop/detail/:id',
          name: 'Kategori SOP Detail',
          component: () => import(/*webpackChunkName: "kategori-sop-detail" */ '@/components/pages/admin/master/sop/kategori-sop/detail'),
          meta: {
            menu: 'Kategori SOP',
            type: 'view'
          }
        },
        {
          path: 'detail-sop/detail/:id',
          name: 'Detail SOP Detail',
          component: () => import(/*webpackChunkName: "detail-sop-detail" */ '@/components/pages/admin/master/sop/detail-sop/detail'),
          meta: {
            menu: 'Detail SOP',
            type: 'edit'
          }
        },
        {
          path: 'detail-sop/add',
          name: 'Tambah Detail SOP',
          component: () => import(/*webpackChunkName: "add-detail-sop" */ '@/components/pages/admin/master/sop/detail-sop/add')
        },
        {
          path: 'detail-sop/edit/:id',
          name: 'Edit Detail SOP',
          component: () => import(/*webpackChunkName: "edit-detail-sop" */ '@/components/pages/admin/master/sop/detail-sop/edit')
        },
        {
          path: 'kategori-sop/import',
          name: 'Import Kategori SOP',
          component: () => import(/*webpackChunkName: "import-kategori-sop" */ '@/components/pages/admin/master/sop/kategori-sop/import'),
          meta: {
            menu: 'Kategori SOP',
            type: 'add'
          }
        },
        {
          path: 'detail-sop/import',
          name: 'Import Detail SOP',
          component: () => import(/*webpackChunkName: "import-detail-sop" */ '@/components/pages/admin/master/sop/detail-sop/import'),
          meta: {
            menu: 'Detail SOP',
            type: 'add'
          }
        },
        // Cabang
        {
          path: 'cabang',
          name: 'Cabang',
          component: () => import(/*webpackChunkName: "cabang" */ '@/components/pages/admin/master/cabang'),
          meta: {
            menu: 'Cabang',
            type: 'view'
          }
        },
        {
          path: 'cabang/add',
          name: 'Tambah Cabang',
          component: () => import(/* webpackChunkName: "add-Cabang" */ '@/components/pages/admin/master/cabang/add'),
          meta: {
            menu: 'Cabang',
            type: 'add'
          }
        },
        {
          path: 'cabang/edit/:id',
          name: 'Ubah Cabang',
          component: () => import(/* webpackChunkName: "edit-cabang" */ '@/components/pages/admin/master/cabang/edit'),
          meta: {
            menu: 'Cabang',
            type: 'edit'
          }
        },
        {
          path: 'cabang/detail/:id',
          name: 'Detail Cabang',
          component: () => import(/* webpackChunkName: "detail-cabang" */ '@/components/pages/admin/master/cabang/detail'),
          meta: {
            menu: 'Cabang',
            type: 'view'
          }
        },
        // OVK CATEGORY
        {
          path: 'jenis-ovk',
          name: 'Jenis OVK',
          component: () => import(/*webpackChunkName: "jenis-ovk" */ '@/components/pages/admin/master/jenis-ovk'),
          meta: {
            menu: 'Jenis OVK',
            type: 'view'
          }
        },
        {
          path: 'jenis-ovk/detail/:id',
          name: 'Detail Jenis OVK',
          component: () => import(/*webpackChunkName: "detail-jenis-ovk" */ '@/components/pages/admin/master/jenis-ovk/detail'),
          meta: {
            menu: 'Jenis OVK',
            type: 'view'
          }
        },
        // Harga Kontrak
        {
          path: 'harga-kontrak',
          name: 'Harga Kontrak',
          component:  () => import(/* webpackChunkName: "harga-kontrak" */ '@/components/pages/admin/master/harga-kontrak'),
          meta: {
            menu: 'Harga Kontrak',
            type: 'view'
          }
        },
        {
          path: 'harga-kontrak/detail/:id',
          name: 'Detail Harga Kontrak',
          component:  () => import(/* webpackChunkName: "detail-harga-kontrak" */ '@/components/pages/admin/master/harga-kontrak/detail'),
          meta: {
            menu: 'Harga Kontrak',
            type: 'edit'
          }
        },
        {
          path: 'harga-kontrak/edit/:id',
          name: 'Ubah Harga Kontrak',
          component:  () => import(/* webpackChunkName: "update-harga-kontrak" */ '@/components/pages/admin/master/harga-kontrak/edit'),
          meta: {
            menu: 'Harga Kontrak',
            type: 'edit'
          }
        },
        // VENDOR
        {
          path: 'vendor',
          name: 'Vendor',
          component: () => import(/*webpackChunkName: "vendor" */ '@/components/pages/admin/master/vendor'),
          meta: {
            menu: 'Vendor',
            type: 'view'
          }
        },
        // MERK DOC
        {
          path: 'merk-doc',
          name: 'Merk DOC',
          component: () => import(/*webpackChunkName: "merk-doc" */ '@/components/pages/admin/master/merk-doc'),
          meta: {
            menu: 'Merek DOC',
            type: 'view'
          }
        },
        // USER
        {
          path: 'user',
          name: 'User',
          component: () => import(/*webpackChunkName: "user" */ '@/components/pages/admin/user/user'),
          meta: {
            menu: 'User',
            type: 'view'
          }
        },
        {
          path: 'user/add',
          name: 'Tambah User',
          component: () => import(/*webpackChunkName: "add-user" */ '@/components/pages/admin/user/user/add'),
          meta: {
            menu: 'User',
            type: 'add'
          }
        },
        {
          path: 'user/detail/:id',
          name: 'Detail User',
          component: () => import(/*webpackChunkName: "detail-user" */ '@/components/pages/admin/user/user/detail'),
          meta: {
            menu: 'User',
            type: 'view'
          }
        },
        {
          path: 'user/edit/:id',
          name: 'Ubah User',
          component: () => import(/*webpackChunkName: "edit-user" */ '@/components/pages/admin/user/user/edit'),
          meta: {
            menu: 'User',
            type: 'edit'
          }
        },
        // ROLE
        {
          path: 'role',
          name: 'Role',
          component: () => import(/*webpackChunkName: "role" */ '@/components/pages/admin/user/role'),
          meta: {
            menu: 'Role',
            type: 'view'
          }
        },
        {
          path: 'role/add',
          name: 'Tambah Role',
          component: () => import(/*webpackChunkName: "add-role" */ '@/components/pages/admin/user/role/add'),
          meta: {
            menu: 'Role',
            type: 'add'
          }
        },
        {
          path: 'role/edit/:id',
          name: 'Ubah Role',
          component: () => import(/*webpackChunkName: "edit-role" */ '@/components/pages/admin/user/role/edit'),
          meta: {
            menu: 'Role',
            type: 'edit'
          }
        },
        {
          path: 'role/detail/:id',
          name: 'Detail Role',
          component: () => import(/*webpackChunkName: "detail-role" */ '@/components/pages/admin/user/role/detail'),
          meta: {
            menu: 'Role',
            type: 'view'
          }
        },
        {
          path: 'settings',
          name: 'Settings',
          component: () => import(/*webpackChunkName: "settings" */ '@/components/pages/admin/user/settings'),
          meta: {
            menu: 'Settings',
            type: 'view'
          }
        },
        {
          path: 'settings/edit',
          name: 'Ubah Settings',
          component: () => import (/*webpackChunkName: "ubah-setting" */ '@/components/pages/admin/user/settings/edit'),
          meta: {
            menu: 'Settings',
            type: 'edit'
          }
        },
        {
          path: 'unauthorized',
          name: 'Unauthorized',
          component: () => import(/*webpackChunkName: "unauthorized" */ '@/components/pages/Unauthorized')
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "not-found" */ '@/components/pages/NotFound'),
      meta: {
        public: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = isLogin()
  const roles = JSON.parse(getUserRoleInfo()) ? JSON.parse(getUserRoleInfo()) : {}
  const userRole = roles.role_management ? roles.role_management : []
  
  if (to.meta.menu) {
    const find = userRole.find(x => x.name === to.meta.menu)
    if (find) {
      const typeUser = find[to.meta.type] ? find[to.meta.type] : false
      if (typeUser) {
        next()
      } else {
        return next('unauthorized')
      }
    }
  }
  // let userRole;

  // if(loggedIn){
  //   userRole = getUserRole()
  // }

  // to.matched.map(record => {
  //   if (record.meta.roles === undefined) {
  //     next()
  //   }else{
  //     if(record.meta.roles.some(item => item == userRole)){
  //       next()
  //     }else{
  //       next("/admin/unauthorized")
  //     }
  //   }
  // })

  if (!isPublic && !loggedIn) {
    return next({
      path:'/login',
      query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
    })
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/admin')
  }

  next()
})

export default router
