import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_ACTIVITY_LOG } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  activityLogs: {},
  activityLogPaginations: {meta:{},data:[]},
  activityLogWithDetails: {meta:{},data:[]},
  historyCycleDetail: {},
  meta: {page:1,limit:10}
}

const mutations = {
  "SET_ACTIVITY_LOGS": (state, payload) => {
    Vue.set(state, 'activityLogs', payload)
  },
  "SET_ACTIVITY_LOGS_PAGINATION": (state, payload) => {
    Vue.set(state, 'activityLogPaginations', payload)
  },
  "SET_ACTIVITY_LOG_WITH_DETAIL": (state, payload) => {
    Vue.set(state, 'activityLogWithDetails', payload)
  },
  "SET_HISTORY_CYCLE_DETAIL": (state, payload) => {
    Vue.set(state, 'historyCycleDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  }
}

const actions = {
  async getActivityLog(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_LOG.LIST(data))
      context.commit('SET_ACTIVITY_LOGS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityLogPagination(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_LOG.LIST_PAGE, data)
      context.commit('SET_ACTIVITY_LOGS_PAGINATION', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityLogWithDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_LOG.LIST_PAGE_WITH_DETAIL(data))
      context.commit('SET_ACTIVITY_LOG_WITH_DETAIL', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  activityLogs: state => {
    return state.activityLogs
  },
  activityLogPaginations: state => {
    return state.activityLogPaginations
  },
  activityLogWithDetails: state => {
    return state.activityLogWithDetails
  },
  historyCycleDetail: state => {
    return state.historyCycleDetail
  },
  meta: state => {
    return state.meta
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
