import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_CATEGORY_SOP } from '@/utils/api-url'
import {
  notificationSuccess,
  notificationDanger
} from '@/utils/notification'


const state = {
  categorySops: {meta:{},data:[]},
  categorySopDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  deleteMessage: {},
  importData: {},
  modalAdd: {},
  modalEdit: {},
  addCategorySop: {}
}

const mutations = {
  "SET_CATEGORY_SOP": (state, payload) => {
    Vue.set(state, 'categorySops', payload)
  },
  "SET_CATEGORY_SOP_DETAIL": (state, payload) => {
    Vue.set(state, 'categorySopDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteMessage', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addCategorySop', payload)
  }
}

const actions = {
  async getCategorySop(context, data) {
    try {
      const response = await apiGetAuth(API_CATEGORY_SOP.LIST, data)
      context.commit('SET_CATEGORY_SOP', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getCategorySopDetail(context, data) {
    try {
      const response = await apiGetAuth(API_CATEGORY_SOP.DETAIL(data))
      context.commit('SET_CATEGORY_SOP_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importCategorySop(context, data) {
    try {
      const response = await apiPostAuth(API_CATEGORY_SOP.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteCategorySop(context, data) {
    try {
      const response = await apiDeleteAuth(API_CATEGORY_SOP.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveCategorySop(context, data) {
    try {
      const response = await apiPostAuth(API_CATEGORY_SOP.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editCategorySop(context, data) {
    try {
      const response = await apiPutAuth(API_CATEGORY_SOP.UPDATE(data.id), data)
      notificationSuccess(`Ubah Data "${response.data.data.name || ''}" Berhasil`)
      setTimeout(function () {
        window.location.href = '/admin/kategori-sop'
      }, 1000)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  categorySops: state => {
    return state.categorySops
  },
  categorySopDetail: state => {
    return state.categorySopDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  getDeleteMessage: state => {
    return state.deleteMessage
  },
  importData: state => {
    return state.importData
  },
  modalAdd: state => {
    return state.modalAdd
  },
  modalEdit: state => {
    return state.modalEdit
  },
  getAddCategorySopMsg: state => {
    return state.addCategorySop
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
