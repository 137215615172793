import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_VENDOR } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  dataVendor: {meta:{},data:[]},
  meta: {page:1,limit:10},
  syncVendor: {}
}

const mutations = {
  "SET_VENDOR": (state, payload) => {
    Vue.set(state, 'dataVendor', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SYNC_VENDOR": (state, payload) => {
    Vue.set(state, 'syncVendor', payload)
  }
}

const actions = {
  async getDataVendor(context, data) {
    try {
      const response = await apiGetAuth(API_VENDOR.LIST, data)
      context.commit('SET_VENDOR', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getSyncVendor(context, data) {
    try {
      const response = await apiGetAuth(API_VENDOR.SYNC, data)
      context.commit('SYNC_VENDOR', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  dataVendor: state => {
    return state.dataVendor
  },
  meta: state => {
    return state.meta
  },
  syncVendor: state => {
    return state.syncVendor
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
