<template>
  <div class="header-background">
    <modal-confirm />
    <div class="uk-grid uk-grid-small">
      <div class="uk-width-auto uk-text-left">
        <a
          style="margin-top:5px;"
          class="header-link"
          uk-toggle="target: #toggle-animation; animation: uk-animation-slide-left"
        >
          <span uk-navbar-toggle-icon />
        </a>
      </div>
      <div
        style="font-size:15px;"
        class="header-link uk-width-auto"
      >
        {{ getDateHeader() }}
      </div>

      <div
        style="height: 60px;"
        class="uk-width-expand text-right"
      >
        <a
          class="header-link"
          href="#"
        >
          <div class="user-profile">
            <span class="username uk-margin-small-left">{{ profile.name }}</span>
            <span class="user-status uk-margin-small-left">{{ profile.role }}</span>
          </div>
          <img
            :src="!profile.photo ? `${images}/avatar.png` : profile.photo.slice(0,4) == 'http' ? profile.photo : image_url+profile.photo"
            alt="avatar.png"
            class="avatar-img"
          >
        </a>
        <div
          uk-dropdown="mode: click;"
          class="dropdown-menu"
        >
          <ul
            class="uk-nav-default uk-nav-parent-icon"
            uk-nav
          >
            <li class="profil-popup list-popup uk-nav-header">
              <router-link
                style="text-decoration: none; padding: 0 24px;"
                class="uk-dropdown-close"
                to="/admin/profile"
              >
                <span class="profile-wrapper">
                  <div class="dropdown-profile">
                    <span class="dropdown-name uk-margin-small-left">{{ profile.name }}</span>
                    <span class="dropdown-status uk-margin-small-left">{{ profile.role }}</span>
                    <span class="dropdown-email uk-margin-small-left">{{ profile.email }}</span>
                  </div>
                  <img
                    :src="!profile.photo ? `${images}/avatar.png` : profile.photo.slice(0,4) == 'http' ? profile.photo : image_url+profile.photo"
                    alt=""
                    class="profil-img"
                  >
                </span>
              </router-link>
            </li>
            <li
              class="change-popup list-popup"
              @click="showConfirmModal"
            >
              <router-link
                style="text-decoration: none; padding: 0 24px;"
                class="change-btn uk-dropdown-close"
                to=""
              >
                <span class="change-wrapper">
                  <img
                    v-lazy="`${images}/icon/password.svg`"
                    style="opacity: 0.5;margin: 17px 0;"
                    class="uk-margin-small-right"
                  >
                  Ganti Password
                </span>
              </router-link>
            </li>
            <li class="logout-popup list-popup">
              <router-link
                style="text-decoration: none; padding: 0 24px;"
                class="logout-btn"
                to="/"
                @click.native="logout"
              >
                <span
                  style="opacity: 0.5;"
                  class="uk-margin-small-right"
                  uk-icon="icon: sign-out"
                />
                Keluar
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateHeaderString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import ModalConfirm from './ModalConfirm'
import { getUserProfile } from '@/utils/auth'

export default {
  components: {
    ModalConfirm
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      profile: {}
    }
  },
  mounted() {
    this.profile = getUserProfile()
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
    },
    getDateHeader() {
      return dateHeaderString(new Date())
    },
    showConfirmModal() {
      window.UIkit.modal('#modal-confirm').show()
    }
  }
}
</script>

<style scoped>
.header-background {
  padding: 0 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}
.header-link {
  color: #999;
  text-decoration: none;
  align-items: center;
  min-height: 50px;
  display: flex;
}
.header-link:hover {
  color: #7b7b7b;
}
.text-right {
  display: grid;
  justify-content: end;
}
.text-center {
  display: grid;
  justify-content: center;
}
.user-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.username {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #025231;
}
.user-status {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #1F2E28;
}
.profil-popup {
  text-transform: capitalize;
}
.profil-popup:hover {
  background: #F0F5F3;
  cursor: pointer;
}
.profile-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0EBE6;
}
.dropdown-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dropdown-name {
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #025231;
  margin-top: 16px;
  margin-left: 0 !important;
}
.dropdown-status {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #1F2E28;
  margin: 4px 0 !important;
}
.dropdown-email {
  text-transform: lowercase;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #85AD9C;
  margin-bottom: 16px;
  margin-left: 0 !important;
}
.profil-img {
  width: 56px;
  max-width: 56px;
  height: 56px;
  max-height: 56px;
  margin-left: 16px;
  border-radius: 40px;
}
.avatar-img {
  width: 33px;
  max-width: 33px;
  height: 33px;
  max-height: 33px;
  margin-left: 8px;
  border-radius: 40px;
}
.dropdown-menu {
  width: 320px;
  left: unset !important;
  right: 24px !important;
  padding: 0;
  border-radius: 0px 0px 16px 16px;
  margin-top: 0;
  background: #FFFFFF;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
}
.list-popup {
  padding: 0;
}
.change-popup {
  height: 58px;
}
.change-btn {
  height: 58px;
  display: flex;
  align-items: center;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}
.change-btn:hover {
  cursor: pointer;
  background: #F0F5F3;
  color: #025231;;
  opacity: 1;
}
.change-wrapper {
  width: 85%;
  border-bottom: 1px solid #E0EBE6;
  color: #025231;
}
.change-popup {
  height: 58px;
}
.logout-btn {
  height: 58px;
  display: flex;
  align-items: center;
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #F92626;
}
.logout-btn:hover {
  cursor: pointer;
  background: rgba(249, 38, 38, 0.08);
  border-radius: 0px 0px 16px 16px;
  color: #F92626;
  opacity: 1;
}
</style>
