import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_ROLE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  role: {},
  roles: {meta:{},data:[]},
  modalDelete: {},
  deleteRole: {},
  modalEdit: {},
  modalAdd: {},
  addRole: {},
  updateRole: {}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_ROLES": (state, payload) => {
    Vue.set(state, 'roles', payload)
  },
  "SET_ROLE": (state, payload) => {
    Vue.set(state, 'role', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteRole', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addRole', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateRole', payload)
  }
}

const actions = {
  async getRole(context, data) {
    try {
      const response = await apiGetAuth(API_ROLE.LIST, data)
      context.commit('SET_ROLES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getRoleDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ROLE.DETAIL(data))
      context.commit('SET_ROLE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteRole(context, data) {
    try {
      const response = await apiDeleteAuth(API_ROLE.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveRole(context, data) {
    try {
      const response = await apiPostAuth(API_ROLE.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editRole(context, data) {
    try {
      const response = await apiPutAuth(API_ROLE.UPDATE(data.id), data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  roles: state => {
    return state.roles
  },
  role: state => {
    return state.role
  },
  modalDelete: state => {
    return state.modalDelete
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  getDeleteRoleMsg: state => {
    return state.deleteRole
  },
  getAddRoleMsg: state => {
    return state.addRole
  },
  getUpdateRoleMsg: state => {
    return state.updateRole
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
