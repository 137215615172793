<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-1-2@s font-header">
        {{ headerTitle }}
      </div>
      <div class="uk-width-1-2@s uk-flex uk-flex-right">
        <label
          class="uk-form-label uk-margin-right filter-margin"
          for="form-horizontal-text"
        >Periode</label>
        <div class="uk-form-controls uk-margin-large-right">
          <div
            uk-grid
            class="uk-grid-small"
          >
            <div class="uk-width-1-1">
              <datepicker
                ref="periodeMonth"
                v-model="filterDate"
                input-class="uk-input"
                placeholder="MM-YYYY"
                minimum-view="month"
                :disabled-dates="beforeJuly"
                :format="monthShortLongDate"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <FourStatus />
    <div
      uk-grid
      class="uk-margin-top uk-grid-medium"
    >
      <div class="uk-width-1-1">
        <MappingArea />
      </div>
      <div class="uk-margin-top uk-width-1-1@s uk-width-1-1@m uk-width-1-3@l uk-width-1-3@xl">
        <ReasonFailed />
      </div>
      <div class="uk-margin-top uk-width-1-2@s uk-width-1-2@m uk-width-1-3@l uk-width-1-3@xl uk-height-1-1">
        <YtfuAcquisition />
      </div>
      <div class="uk-margin-top uk-width-1-2@s uk-width-1-2@m uk-width-1-3@l uk-width-1-3@xl uk-height-1-1">
        <YtdAcquisition />
      </div>
      <div class="uk-margin-top uk-padding-bottom uk-width-1-1">
        <ClosingRate />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import getHeaderTitle from "@/utils/header-title"
import Datepicker from "vuejs-datepicker"
import FourStatus from "./Component/FourStatus"
import MappingArea from "./Component/MappingArea"
import ReasonFailed from "./Component/ReasonFailed"
import ClosingRate from "./Component/ClosingRate"
import YtdAcquisition from "./Component/Ytd-Acquisition"
import YtfuAcquisition from "./Component/Ytfu-Acquisition"
import { dateString, monthShortLongDate } from "@/utils/formater"

export default {
  components: {
    Datepicker,
    FourStatus,
    MappingArea,
    ReasonFailed,
    ClosingRate,
    YtdAcquisition,
    YtfuAcquisition
  },
  data() {
    return {
      beforeJuly: {to: new Date('31 Jun 2020')},
      filterDate: null
    }
  },
  computed: {
    ...mapGetters({
      dataTotalStatus: "acquisitionDashboard/dataTotalStatus",
      dataMeta: "acquisitionDashboard/dataMeta"
    }),
    headerTitle() {
      return getHeaderTitle(this.$route.fullPath)
    }
  },
  watch: {
    'filterDate'() {
      const timestamp = this.filterDate
      const date = new Date(timestamp)
      const month = date.getMonth() + 1 // Get month (0-11), add 1 to adjust to 1-12
      const year = date.getFullYear() // Get the full year

      const formattedDate = ("0" + month).slice(-2) + "-" + year
      this.handleFilter(formattedDate)
    }
  },
  async mounted() {
    await this.handleAction()
  },
  methods: {
    ...mapActions({
      fetchTotalStatus: "acquisitionDashboard/fetchTotalStatus",
      fetchStatusMitra: "acquisitionDashboard/fetchStatusMitra",
      fetchReasonFailed: "acquisitionDashboard/fetchReasonFailed",
      fetchMappingArea: "acquisitionDashboard/fetchMappingArea",
      fetchAcquisitionRate: "acquisitionDashboard/fetchAcquisitionRate"
    }),
    ...mapMutations({
      setDataMeta: "acquisitionDashboard/SET_DATA_META"
    }),
    async handleAction(payload) {
      await Promise.all([
        this.fetchTotalStatus(payload), 
        this.fetchStatusMitra(payload), 
        this.fetchReasonFailed(payload),
        this.fetchMappingArea(payload), 
        this.fetchAcquisitionRate(payload)
      ])
    },
    getDatetime(date) {
      return dateString(date)
    },
    async handleFilter(t) {
      this.setDataMeta({
        ...this.dataMeta,
        period: t
      })
      await this.handleAction(this.dataMeta)
    },
    monthShortLongDate(t){
      return monthShortLongDate(t)
    }
  }
}
</script>
