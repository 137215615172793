import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_FARM } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  meta: {page:1,limit:10},
  farm: {},
  farms: {meta:{},data:[]},
  modalDelete: {},
  deleteFarm: {},
  modalEdit: {},
  modalAdd: {},
  addFarm: {},
  updateFarm: {}
}

const mutations = {
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_FARMS": (state, payload) => {
    Vue.set(state, 'farms', payload)
  },
  "SET_FARM": (state, payload) => {
    Vue.set(state, 'farm', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteFarm', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addFarm', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateFarm', payload)
  }
}

const actions = {
  async getFarm(context, data) {
    try {
      const response = await apiGetAuth(API_FARM.LIST, data)
      context.commit('SET_FARMS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getFarmDetail(context, data) {
    try {
      const response = await apiGetAuth(API_FARM.DETAIL(data))
      context.commit('SET_FARM', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteFarm(context, data) {
    try {
      const response = await apiDeleteAuth(API_FARM.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveFarm(context, data) {
    try {
      const response = await apiPostAuth(API_FARM.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async editFarm(context, data) {
    try {
      const response = await apiPutAuth(API_FARM.UPDATE, data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  meta: state => {
    return state.meta
  },
  farms: state => {
    return state.farms
  },
  farm: state => {
    return state.farm
  },
  modalDelete: state => {
    return state.modalDelete
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  getDeleteFarmMsg: state => {
    return state.deleteFarm
  },
  getAddFarmMsg: state => {
    return state.addFarm
  },
  getUpdateFarmMsg: state => {
    return state.updateFarm
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
