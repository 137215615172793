import * as Cookies from 'js-cookie'
import router from '@/router'

const IMAGE_URL = process.env.VUE_APP_IMAGE_URL

export const setCookie = (cookie) => {
  Cookies.set('paranje-token', cookie.token, { path: '/' })
  Cookies.set('paranje-id', cookie.id, { path: '/' })
  return {
    token: Cookies.get('paranje-token')
  }
}

export const setProfile = (data) => {
  const stringProfile = JSON.stringify({
    name: data.name,
    role: data.role,
    email: data.email,
    photo: data.photo
  })
  Cookies.set('paranje-user', stringProfile, { path: '/' })
}

export const getUserProfile = () => {
  const stringProfile = Cookies.get('paranje-user')
  const jsonProfile = JSON.parse(stringProfile)
  return jsonProfile
}

export const setUpdateProfile = (data) => {
  const stringProfile = JSON.stringify({
    name: data.name,
    role: data.role,
    email: data.email,
    photo: IMAGE_URL + data.photo
  })
  Cookies.set('paranje-user', stringProfile, { path: '/' })
}

export const setStorageRoleInfo = (role_management, role) => {
  if (role.Name == 'Super Admin') {
    role_management = []
  }
  const obj = {
    role_management : role_management,
    role : role
  }
  localStorage.setItem('paranje-role-info', JSON.stringify(obj))
  return localStorage.getItem('paranje-role-info')
}

export const getUserToken = () => {
  return Cookies.get('paranje-token')
}

export const getUserRoleInfo = () => {
  return localStorage.getItem('paranje-role-info')
}

export const isLogin = () => {
  const token = Cookies.get('paranje-token')
  const usercookie = Cookies.get('paranje-user')
  const userid = Cookies.get('paranje-id')
  const permissionsRole = checkRoleManagement()
  if (token && permissionsRole && usercookie && userid) {
    return true
  } 
  return false
}

export const isCanAccess = (type, menu) => {
  const userAccesses = JSON.parse(localStorage.getItem('paranje-role-info'))
  let result = false
  if (userAccesses.role.Name === `Super Admin`) {
    result = true
  } else {
    const findAccess = userAccesses.role_management.find(x => x.name === menu)
    result = findAccess[type]
  }
  return result
}

export const checkRoleManagement = () => {
  const parseRoles = JSON.parse(localStorage.getItem('paranje-role-info'))
  let result = false
  if (parseRoles) {
    if (parseRoles.role.Name === 'Super Admin') {
      result = true
    } else {
      if (parseRoles.role_management.length > 0) {
        const isViewExist = parseRoles.role_management.find(x => x.view === true)
        result = isViewExist ? isViewExist : false 
      } else {
        result = false
      }
    }
  }
  return result
}

export const logoutUser = () => {
  Cookies.remove('paranje-token', { path: '/' })
  Cookies.remove('paranje-id', { path: '/' })
  Cookies.remove('paranje-user', { path: '/' })
  localStorage.clear()
  router.go(0)
}
