<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div>
          <h4 class="title label-green">
            YTD Acquisition Rate
          </h4>
          <h5>
            Target 
            <span 
              class="uk-text-bold label-green"
            >25%
            </span>
          </h5>
        </div>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <template v-else>
          <div class="wrapper-chart chartjs-container uk-align-center">
            <Doughnut
              :chart-data="chartData"
              :options="doughnutOption"
            />
            <h5 class="mid-percentage">
              {{ handlePercentage(totalRate) || '0' }}%
            </h5>
          </div>
          <div class="uk-margin-top">
            <div class="uk-flex uk-flex-start uk-flex-middle">
              <span class="rectangle-outer bg-prospect" />
              <span class="title-legend">
                Mitra Prospek ( {{ handlePercentage(dataChartRate.mitra_prospect) || '0' }} )
              </span>
            </div>
            <div class="uk-flex uk-flex-start uk-flex-middle">
              <span class="rectangle-outer bg-farmer" />
              <span class="title-legend">
                Paranje Farmers ( {{ handlePercentage(dataChartRate.mitra_farmer) || '0' }} )
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { float0Decimals } from '@/utils/formater'
import Doughnut from '@/components/globals/chart/DoughnutChart.js'
import ComponentLoading from '@/components/globals/loading'

export default {
  components: {
    Doughnut,
    ComponentLoading
  },
  data() {
    return {
      dataChartRate: {},
      isLoading: true,
      totalRate: null,
      doughnutOption: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true
        },
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function(tooltipItem, data) {
              return data['datasets'][0]['data'][tooltipItem[0]['index']]+' '+data['labels'][tooltipItem[0]['index']]
            },
            label: function() {
              return null
            }
          },
          backgroundColor: '#FFF',
          titleFontSize: 12,
          titleFontColor: '#025231',
          bodyFontColor: '#000',
          bodyFontSize: 12,
          displayColors: false
        }
      }
      // chartData: {
      //   labels: ['Paranje Farmers', 'Mitra Prospek'],
      //   datasets: [{
      //     backgroundColor: ['#2D99FF', '#2CD9C5'],
      //     data: [2, 1]
      //   }]
      // }
    }
  },
  computed: {
    ...mapGetters({
      dataAcquisitionRate: 'acquisitionDashboard/dataAcquisitionRate'
    })
  },
  watch: {
    dataAcquisitionRate() {
      if (this.dataAcquisitionRate) {
        this.updateChart()
      }
    }
  },
  methods: {
    handlePercentage(value) {
      return float0Decimals(value)
    },
    updateChart() {
      this.chartData = {}
      this.handleChartData()
      this.isLoading = false
    },
    handleChartData(){
      this.dataChartRate = this.dataAcquisitionRate
      this.totalRate = this.dataAcquisitionRate.acquisition_rate
      this.chartData.labels = ['Paranje Farmers', 'Mitra Prospek']
      this.chartData.datasets = [{
        backgroundColor: ['#2D99FF', '#2CD9C5'],
        hoverOffset: 10,
        data: [
          this.handlePercentage(this.dataChartRate.mitra_farmer),
          this.handlePercentage(this.dataChartRate.mitra_prospect)
        ]
      }]
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
  .wrapper-status {
    padding: 16px;
  }

  .chartjs-container {
    position: inherit !important;
    width: 160px !important;
    height: 160px !important;
  }


  .wrapper-status .title {
    font-size: 20px;
    font-weight: 700;
  }


  .wrapper-status h5 {
    font-family: ClanOT;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    margin-top: 0;
  }

  .wrapper-status h4 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 5px;
  }

  .wrapper-chart {
    position: relative;
  }

  .wrapper-chart .mid-percentage {
    font-family: ClanOT;
    font-style: normal;
    font-weight: 700;
    color: #000000;
    line-height: 0;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .title-legend {
    font-family: ClanOT;
    font-style: normal;
    font-size: 12px;
    margin-left: 12px ; 
  }

.rectangle-outer{
  width: 18px;
  height: 8px;
}

.bg-farmer{
  background: #2D99FF !important;
}

.bg-prospect{
  background: #2CD9C5 !important;
}
</style>
