/* eslint-disable no-undef */
import 'dayjs/locale/id'
import * as dayjs from 'dayjs'
import { PARTNER_STATUS } from "@/utils/constant"
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.locale('id')

// Date & Time
export const datetimeString = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY, HH:mm')
  return null
}

export const dateStringSimply = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY')
  return null
}

export const dateString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD')
  return null
}

export const dateParanjeString = (date) => {
  if (date) return dayjs(date).format('DD MMM YYYY')
  return null
}

export const dateUtcParanjeString = (date) => {
  if (date) return dayjs.utc(date).format('DD MMM YYYY')
  return null
}

export const dateParanjeCompleteString = (date) => {
  if (date) return dayjs(date).format('DD MMMM YYYY')
  return null
}

export const dateHeaderString = (date) => {
  if (date) return dayjs(date).format('dddd, DD MMMM YYYY')
  return null
}

export const monthShortLongDate = (date) => {
  if (date) return dayjs(date).format('MMM YYYY')
  return null
}

export const getYearForRange = (date) => {
  if (date) return dayjs(date).format('YYYY')
  return null
}

export const dateStartTimeString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD 00:00:00')
  return null
}

export const dateEndTimeString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD 23:59:59')
  return null
}

export const dateStringThreeMonthLetter = (date) => {
  if (date) return dayjs(date).format('DD-MMM-YYYY')
  return null
}

export const dateStringMonthOfYear = (date) => {
  if (date) return dayjs(date).format('MM-YYYY')
  return null
}

export const hoursString = (date) => {
  if (date) return dayjs(date).format('HH:mm')
  return null
}

export const hourStringUtc = (date) => {
  if (date) return dayjs.utc(date).utcOffset(420).format('HH:mm')
  return null
}

export const dateUnixString = () => {
  return dayjs().unix().toString()
}

export const dateAddDays = (date, day) => {
  if (date) return dayjs(date).add(day, 'day')
  return null
}

export const dateDiffDays = (nextDate, prevDate) => {
  if (nextDate) return dayjs(nextDate).diff(prevDate, 'day')
  return null
}

export const dateSubstract = (date, totalSubstract, formatDate) => {
  if (date) return dayjs(date).subtract(totalSubstract, formatDate)
  return null
}

export const float3Decimals = (number, limit) => {
  if (number) {
    const setDecimal = (Number(number) === number && number % 1 !== 0) ? number.toFixed(limit ? limit : 3).replace('.', ',') : number
    const newNumber = setDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") // thousands separator
    return newNumber
  } else if (number == 0) {
    return number
  }
  return null
}


//for no rounding
export const roundedToFloat3DecimalsNoRounding = (number, limit) => {
  if (number) {
    const setDecimal = (Number(number) === number && number % 1 !== 0) ? number.toFixedNoRounding(limit ? limit : 3).replace('.', ',') :  number.toFixedNoRounding(limit ? limit : 3).replace('.', ',')
    const newNumber = setDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") // thousands separator
    return newNumber
  } else if (number == 0) {
    return number
  }
  return null
}
//for toFixed
export const roundedToFloat3Decimals = (number, limit) => {
  if (number) {
    const setDecimal = (Number(number) === number && number % 1 !== 0) ? number.toFixed(limit ? limit : 3).replace('.', ',') :  number.toFixedNoRounding(limit ? limit : 3).replace('.', ',')
    const newNumber = setDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") // thousands separator
    return newNumber
  } else if (number == 0) {
    return number
  }
  return null
}

export const float2Decimals = (number) => {
  if (number) {
    if (number.toString().includes(',')) {
      const newNumber = Number(number.replace(',', '.'))
      const setDecimal = (newNumber % 1 !== 0) ? newNumber.toFixed(2) : newNumber
      return Number(setDecimal)
    } else {
      const newNumber = Number(number)
      const setDecimal = (newNumber % 1 !== 0) ? newNumber.toFixed(2) : newNumber
      return Number(setDecimal)
    }
  } else if (number == 0) {
    return number
  }
  return null
}

export const float1Decimals = (number) => {
  if (number) {
    if (number.toString().includes(',')) {
      const newNumber = Number(number.replace(',', '.'))
      const setDecimal = (newNumber % 1 !== 0) ? newNumber.toFixed(1) : newNumber
      return Number(setDecimal)
    } else {
      const newNumber = Number(number)
      const setDecimal = (newNumber % 1 !== 0) ? newNumber.toFixed(1) : newNumber
      return Number(setDecimal)
    }
  } else if (number == 0) {
    return number
  }
  return null
}

export const float0Decimals = (number) => {
  if (number) {
    number = Number(number)
    const setDecimal = number.toFixed(0)
    return setDecimal
  } else if (number == 0) {
    return number
  }
  return number = 0
}

export const roundedDecimals = (number) => {
  if (number) return number.toFixed(2)
  return number
}

export const roundedDecimalsSwapComma = (number) => {
  if (number) return number.toFixed(2).replace(".", ",")
  return number
}

export const thousandSeparator = (number) => {
  if (number>999 || number<-999){
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  } else {
    return number
  }
}

export const thousandSeparatorFloat = (number, limit) => {
  if (number == undefined || number == null) {
    return '-'
  } else {
    if(limit == 1){
      return thousandSeparator(number.toFixed(limit).replace(".",",").replace(",0"," "))
    } else {
      const result = thousandSeparator(number.toFixed(limit).replace(".",","))
      return removeUnusedComma(result)
    }
  }
}

export const thousandsSeparatorOninPut = (number, limit) => {
  // make sure input type is text
  if (typeof number != "string") {
    number = String(number)
  }
  const output = limit ? thousandSeparatorFloat(removeAlpabetic(number), limit) : thousandSeparator(removeAlpabetic(number))
  return output
}

export const removeAlpabetic = (string) => {
  if (typeof string != "string") {
    string = String(string)
  }
  if (string[0] === '0') {
    string = string.substring(1)
  }
  const output = string == '' ? 0 : string.replace(/[^0-9,]/g, '')
  return output
}

export const removeUnusedComma = (v) => {
  return v.replace(/,+/g, ',')
}

export const generateColor = () => {
  return `#${(Math.random()*0xFFFFFF<<0).toString(16)}`
}

export const currency = (value) => {
  if (value > 999 && value != 0){
    return  `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //already using thousands separator
  } else {
    return `Rp ${value}`
  } 
}

export const currencyIDR = (value) => {
  if (value) return `IDR ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //already using thousands separator
  return null
}

export const summaryOneTarget = (dataArray, target) => {
  if (dataArray) return dataArray.reduce((a, b) => a + b[target], 0)
  return null
}

export const summaryTwoTarget = (dataArray, target1, target2) => {
  if (dataArray) return dataArray.reduce((a, b) => a + ( b[target1] * b[target2] ), 0)
  return null
}

export const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const capitalize = (string) => {
  if(string == null || string == ''){
    return '-'
  }
  const lower = string.toLowerCase()
  return string.charAt(0).toUpperCase() + lower.slice(1)
}

export const snakeToCapitalized = (string) => {
  if(string == null || string == ''){
    return '-'
  }
  const change = string.replace(/(^\w)/g, g => g[0].toUpperCase()).replace(/([-_]\w)/g, g => " " + g[1].toUpperCase()).trim()
  return change
}

// print if no difference
export const unitPrintNoDiff = (array,target) => {
  let result = ' '
  let counter = 0
  switch(target) {
  case 'unit_code':
    for(let i = 0; i < array.length; i++) {
      if(array[i].unit_code != array[0].unit_code){
        counter++
      }
    }
    result = counter > 0 ? ' ' :  array[0].unit_code
    break
  case 'unit':
    for(let i = 0; i < array.length; i++) {
      if(array[i].unit != array[0].unit){
        counter++
      }
    }
    result = counter > 0 ? ' ' :  array[0].unit
    break
  default:
    result = ' '
  }
  
  return result ? result.toLowerCase() : result
}

Number.prototype.toFixedNoRounding = function(n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g")
  const a = this.toString().match(reg)[0]
  const dot = a.indexOf(".")
  if (dot === -1) { // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n)
  }
  const b = n - (a.length - dot) + 1
  return b > 0 ? (a + "0".repeat(b)) : a
}

// Shorten Formatter
export const ifFalsyReturnZero = (theVariable) => {
  if(theVariable > 1 || theVariable !== 0 || theVariable !== null) {
    return theVariable
  } else {
    theVariable = 0
    return theVariable
  }
}

// Component Shortener

export const encodeStatusMitra = (number) => {
  for (let i = 0; i < PARTNER_STATUS.length; i++) {
    if (PARTNER_STATUS[i].code === number) {
      return PARTNER_STATUS[i].value
    }
  }
}

// Image Compressor

export const compressedImage = (file, maxSizeInMB) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      const image = new Image()
      image.src = event.target.result
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        let maxSizeInBytes = maxSizeInMB * 1000000 // Convert maxSizeInMB to bytes
        let width = image.width
        let height = image.height
        let scaleFactor = 1

        const compressFile = (file, maxSize) => {
          canvas.width = width * scaleFactor
          canvas.height = height * scaleFactor
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, { type: file.type })

            if (compressedFile.size <= maxSize) {
              resolve(compressedFile)
            } else {
              scaleFactor -= 0.2
              if (scaleFactor > 0) {
                compressFile(file, maxSize)
              } else {
                resolve(file)
              }
            }
          }, file.type, 0.7)
        }

        compressFile(file, maxSizeInBytes)
      }
    }
    reader.readAsDataURL(file)
  })
}
