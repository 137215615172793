import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_SUB_ACTIVITY_CATEGORY } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  subActivityCategorys: {meta:{},data:[]},
  subActivityCategoryDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  deleteSubActivityCategory: {},
  modalAdd: {},
  modalEdit: {},
  addSubActivityCategory: {},
  editSubActivityCategory: {}
}

const mutations = {
  "SET_SUB_ACTIVITY_CATEGORYS": (state, payload) => {
    Vue.set(state, 'subActivityCategorys', payload)
  },
  "SET_SUB_ACTIVITY_CATEGORY_DETAIL": (state, payload) => {
    Vue.set(state, 'subActivityCategoryDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteSubActivityCategory', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addSubActivityCategory', payload)
  },
  "SET_EDIT_RESPONSE": (state, payload) => {
    Vue.set(state, 'editSubActivityCategory', payload)
  }
}

const actions = {
  async getSubActivityCategory(context, data) {
    try {
      const response = await apiGetAuth(API_SUB_ACTIVITY_CATEGORY.LIST, data)
      context.commit('SET_SUB_ACTIVITY_CATEGORYS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getSubActivityCategoryDetail(context, data) {
    try {
      const response = await apiGetAuth(API_SUB_ACTIVITY_CATEGORY.DETAIL(data))
      context.commit('SET_SUB_ACTIVITY_CATEGORY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importSubActivityCategory(context, data) {
    try {
      const response = await apiPostAuth(API_SUB_ACTIVITY_CATEGORY.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteSubActivityCategory(context, data) {
    try {
      const response = await apiDeleteAuth(API_SUB_ACTIVITY_CATEGORY.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveSubActivityCategory(context, data) {
    try {
      const response = await apiPostAuth(API_SUB_ACTIVITY_CATEGORY.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async saveEditSubActivityCategory(context, data) {
    try {
      const response = await apiPutAuth(API_SUB_ACTIVITY_CATEGORY.UPDATE(data.id), data)
      context.commit('SET_EDIT_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  subActivityCategorys: state => {
    return state.subActivityCategorys
  },
  subActivityCategoryDetail: state => {
    return state.subActivityCategoryDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  getDeleteSubActivityCategoryMsg: state => {
    return state.deleteSubActivityCategory
  },
  modalAdd: state => {
    return state.modalAdd
  },
  modalEdit: state => {
    return state.modalEdit
  },
  getAddSubActivityCategoryMsg: state => {
    return state.addSubActivityCategory
  },
  getEditSubActivityCategoryMsg: state => {
    return state.editSubActivityCategory
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
