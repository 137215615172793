// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import VeeValidate from 'vee-validate'
import * as VueGoogleMaps from 'vue2-google-maps'
import App from './App'
import router from './router'
import { store } from './store'
import interceptorsSetup from '@/configs/axios'
import VueLazyload from 'vue-lazyload'
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VeeValidate)
Vue.use(VueLazyload)
Vue.use(VueMask)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'geometry,drawing,places'
  }
})

interceptorsSetup()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
