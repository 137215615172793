<template>
  <div
    id="modal-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-edit-user"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <form @submit.prevent="onSubmit">
        <div class="uk-modal-title">
          Konfirmasi Password
        </div>
        <div
          style="margin: 40px 0 20px"
          class="uk-text-left"
        >
          <h4>Password</h4>
          <div class="uk-inline uk-width-1-1 password-field">
            <input
              id="password"
              v-model="password"
              v-validate="'required|min:8'"
              name="password"
              class="uk-input"
              :type="passwordFieldType"
              placeholder="Masukkan password..."
              autocomplete="off"
              :class="{'uk-form-danger': errors.has('password')}"
            >
            <img
              v-lazy="`${images}/icon/${eyeIcon}`"
              alt=""
              class="toggle-eye"
              @click="passwordVisibility"
            >
          </div>
          <span
            v-show="errors.has('password')"
            class="uk-text-small uk-text-danger"
          >{{ errors.first('password') }}</span>
        </div>
        <div style="margin-top:25px">
          <button
            class="uk-button uk-button-default uk-margin-right uk-modal-close"
            type="button"
            :disabled="is_loading"
            @click="goToBack"
          >
            Kembali
          </button>
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary"
            type="submit"
            :disabled="is_loading"
          >
            Konfirmasi
          </button>
          <button
            v-else
            class="uk-button uk-button-primary"
            type="button"
          >
            <div uk-spinner />
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      is_loading: false,
      password: '',
      passwordFieldType: 'password',
      images: PREFIX_IMAGE,
      eyeIcon: 'eye.svg'
    }
  },
  methods: {
    ...mapActions({
      checkUser: 'user/checkUser'
    }),
    goToBack() {
      window.UIkit.modal('#modal-confirm').hide()
    },
    onSubmit() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.handleCheckUser()
          
        }
      })
    },
    async handleCheckUser () {
      try {
        this.is_loading = true
        await this.checkUser(this.password)
        setTimeout(() => {
          window.location = '/admin/change-password'
        }, 1000)
      } catch (error) {
        console.log(error.response)
        this.is_loading = false
      }
    },
    passwordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
      this.eyeIcon = this.eyeIcon === 'eye.svg' ? 'eye-slash.svg' : 'eye.svg'
    }
  }
}
</script>

<style scoped>
.uk-modal-dialog {
  width: 390px;
  max-width: 390px;
  height: 255px;
  padding: 24px 24px;
}
.uk-modal-title {
  color:#025231;
  font-family: ClanOT;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
}
h4 {
  font-family: ClanOT;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #025231;
  margin-bottom: 5px;
}
.password-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.toggle-eye {
  position: absolute;
  margin-right: 10px;
}
</style>
