import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth  } from '@/utils/api'
import { API_ACTIVITY } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  activities: {meta:{},data:[]},
  activityFilters: {meta:{},data:[]},
  activityDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  modalEdit: {},
  modalAdd: {},
  addActivity: {},
  updateActivity: {},
  deleteActivity: {}
}

const mutations = {
  "SET_ACTIVITIES": (state, payload) => {
    Vue.set(state, 'activities', payload)
  },
  "SET_ACTIVITYS_FILTER": (state, payload) => {
    Vue.set(state, 'activityFilters', payload)
  },
  "SET_ACTIVITY_DETAIL": (state, payload) => {
    Vue.set(state, 'activityDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addActivity', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateActivity', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteActivity', payload)
  }
}

const actions = {
  async getActivity(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY.LIST, data)
      context.commit('SET_ACTIVITIES', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityFilter(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY.LIST, data)
      context.commit('SET_ACTIVITYS_FILTER', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY.DETAIL(data))
      context.commit('SET_ACTIVITY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importActivity(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteActivity(context, data) {
    try {
      const response = await apiDeleteAuth(API_ACTIVITY.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveActivity(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async updateActivity(context, data) {
    try {
      const response = await apiPutAuth(API_ACTIVITY.UPDATE(data.id), data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  activities: state => {
    return state.activities
  },
  activityFilters: state => {
    return state.activityFilters
  },
  activityDetail: state => {
    return state.activityDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  getAddActivityMsg: state => {
    return state.addActivity
  },
  getUpdateActivityMsg: state => {
    return state.updateActivity
  },
  getDeleteActivityMsg: state => {
    return state.deleteActivity
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
