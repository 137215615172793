<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Vue from 'vue'

Vue.component('multiselect', Multiselect)

UIkit.use(Icons)

window.UIkit = UIkit

export default {
  name: 'App'
}
</script>
<style lang="less">
@import "../node_modules/uikit/src/less/uikit.theme.less";
@import "./style.less";

</style>

