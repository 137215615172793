const METHOD = {
  GET: 'get',
  PATCH: 'patch',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

const ACCEPTED_IMAGE = ['jpg', 'jpeg', 'png']

const PREFIX_IMAGE = '/images'

const STATUS = {
  ACTIVE: 'Aktif',
  INACTIVE: 'Tidak Aktif'
}

const DURATION_ACTIVITY = {
  START_PREPARATION: 6,
  END_PREPARATION: 1,
  CHICK_IN: 1,
  PEMELIHARAAN: 1,
  CHICK_IN_HARVEST: 40,
  HARVEST: 2,
  CHICK_OUT: 1,
  SANITATION: 6
}

const SORTING = [
  {
    name: 'Ascending',
    value: 'ASC'
  }, {
    name: 'Descending',
    value: 'DESC'
  }
]

const INSTALMENT_LIST = [
  {
    name: 'Ada',
    value: 1
  },
  {
    name: 'Tidak Ada',
    value: 0
  }
]

const MAPPING_FILTER = [
  {
    name: 'Semua'
  },
  {
    name: 'Paranje Farmers'
  }
]

const PREVIOUS_RHPP_SOLUTION = [
  {
    name: 'Penambahan',
    value: 1
  },
  {
    name: 'Pengurangan',
    value: 0
  }
]

const DEFERRAL_GUARANTEE = [
  {
    name: '100%',
    value: 100
  },
  {
    name: '50%',
    value: 50
  },
  {
    name: 'Tidak Ada',
    value: 0
  }
]

const PARTNER_STATUS = [
  {
    value: 'Mitra Prospek',
    code: 0
  }, {
    value: 'Mitra Prospek - Tidak Tertarik',
    code: 1
  }, {
    value: 'Mitra Potensial',
    code: 2
  }, {
    value: 'Mitra Potensial - Tidak Layak',
    code: 3
  }, {
    value: 'Submit & Verification',
    code: 4
  }, {
    value: 'Submit & Verification - Upload Dokumen',
    code: 5
  }, {
    value: 'Paranje Farmers',
    code: 6
  }, {
    value: 'Paranje Farmers - Tanggal SPK Sudah Ditentukan',
    code: 7
  }, {
    value: 'Paranje Farmers - Dokumen SPK Diterima',
    code: 8
  }, {
    value: 'Paranje Farmers - Bukti Transfer Diterima',
    code: 9
  }, {
    value: 'Paranje Farmers - Cicilan Dana Jaminan Diterima',
    code: 10
  }, {
    value: 'Paranje Farmers - Dana Jaminan Lunas',
    code: 11
  }, {
    value: 'Mitra Mengundurkan Diri',
    code: 12
  }
]

const ACQUISITION_FILE_IMPORT_PATH = [
  {
    value: 'Mitra-Prospek-Status-0.csv',
    code: 0,
    endPoint: `mitra-prospect`
  }, {
    value: 'Mitra-Prospek-Tidak-Tertarik-Status-1.csv',
    code: 1,
    endPoint: `mitra-prospect-not-interested`
  }, {
    value: 'Mitra-Prospek-Tertarik-Status-2.csv',
    code: 2,
    endPoint: `mitra-prospect-interested`
  }, {
    value: 'Mitra-Potensial-Tidak-Layak-Status-3.csv',
    code: 3,
    endPoint: `mitra-potential-not-eligible`
  }, {
    value: 'Mitra-Potential-Layak-status-4.csv',
    code: 4,
    endPoint:  `mitra-potential-eligible`
  }, {
    value: 'Mitra-Submit-And-Verification-status-5.csv',
    code: 5,
    endPoint: `mitra-submit-and-verification`
  }, {
    value: 'Mitra-Submit-Verified-Status-6.csv',
    code: 6,
    endPoint: `mitra-submit-and-verification-verified`
  }, {
    value: 'Mitra-Farmer-Plan-Spk-status-7.csv',
    code: 7,
    endPoint: `mitra-farmer-spk-planning`
  }, {
    value: 'Mitra-Farmer-Upload-Spk-Status-8.csv',
    code: 8,
    endPoint: `mitra-farmer-upload-spk`
  }, {
    value: 'Mitra-Upload-Bukti-Status-9.csv',
    code: 9,
    endPoint: `mitra-farmer-installment-receipt`
  }, {
    value: 'Mitra-Resign-Status-12.csv',
    code: 12,
    endPoint: `mitra-resign`
  }
]

const DATA_LOCATION_PLAN = [
  { name: 'Aceh - Banda Aceh' },
  { name: 'Sumatera Utara - Medan' },
  { name: 'Sumatera Barat - Padang' },
  { name: 'Riau - Pekanbaru' },
  { name: 'Kepulauan Riau - Tanjungpinang' },
  { name: 'Jambi - Jambi' },
  { name: 'Sumatera Selatan - Palembang' },
  { name: 'Kepulauan Bangka Belitung - Pangkal Pinang' },
  { name: 'Bengkulu - Bengkulu' },
  { name: 'Lampung - Bandar Lampung' },
  { name: 'DKI Jakarta - Jakarta' },
  { name: 'Banten - Serang' },
  { name: 'Jawa Barat - Bandung' },
  { name: 'Jawa Tengah - Semarang' },
  { name: 'DI Yogyakarta - Yogyakarta' },
  { name: 'Jawa Timur - Surabaya' },
  { name: 'Bali - Denpasar' },
  { name: 'Nusa Tenggara Barat - Mataram' },
  { name: 'Nusa Tenggara Timur - Kupang' },
  { name: 'Kalimantan Barat - Pontianak' },
  { name: 'Kalimantan Tengah - Palangkaraya' },
  { name: 'Provinsi Kalimantan Selatan - Banjarmasin' },
  { name: 'Kalimantan Timur - Samarinda' },
  { name: 'Kalimantan Utara - Tanjung Selor' },
  { name: 'Sulawesi Utara - Manado' },
  { name: 'Gorontalo - Gorontalo' },
  { name: 'Sulawesi Tengah - Palu' },
  { name: 'Sulawesi Barat - Mamuju' },
  { name: 'Provinsi Sulawesi Selatan - Makassar' },
  { name: 'Sulawesi Tenggara - Kendari' },
  { name: 'Maluku - Ambon' },
  { name: 'Maluku Utara - Sofifi' },
  { name: 'Papua Barat - Manokwari' },
  { name: 'Papua - Jayapura' },
  { name: 'Lainnya' }
]

const DATA_INFO_PARANJE = [
  { name: 'Media Sosial - Instagram' },
  { name: 'Media Sosial - Facebook' },
  { name: 'Majalah' },
  { name: 'Orang lain' },
  { name: 'Website' },
  { name: 'Technical Service & Business Partner Paranje' },
  { name: 'Lainnya' }
]

const DATA_PROFESSION = [
  { name: 'Wiraswasta' },
  { name: 'Karyawan Swasta' },
  { name: 'Karyawan BUMD/PNS' },
  { name: 'Pelajar/Mahasiswa' },
  { name: 'Ibu rumah tangga' },
  { name: 'Pensiunan' },
  { name: 'Lainnya' }
]

const DATA_REASON = [
  { name: 'Kendala Modal' },
  { name: 'Tidak Dapat Izin' },
  { name: 'Permasalahan Lahan' },
  { name: 'Tidak Percaya PARANJE' },
  { name: 'Tidak Ada Sumber Daya' },
  { name: 'Mengundurkan Diri' },
  { name: 'Lainnya' }
]

const CAGE_DIRECTION = [
  { name: 'Barat - Timur' },
  { name: 'Barat - Selatan' },
  { name: 'Barat - Utara' },
  { name: 'Utara - Selatan' },
  { name: 'Utara - Barat' },
  { name: 'Utara - Timur' },
  { name: 'Timur - Barat' },
  { name: 'Timur - Selatan' },
  { name: 'Timur - Utara' },
  { name: 'Selatan - Barat' },
  { name: 'Selatan - timur' },
  { name: 'Selatan - Utara' }
]

const SUPPORTING_FACILITIES = [
  { name: 'Gudang' },
  { name: 'Mess' },
  { name: 'Gudang dan Mess' },
  { name: 'Tidak Ada' },
  { name: 'Lainnya' }
]

const LAND_STATUS = [
  { name: 'Milik Sendiri' },
  { name: 'Milik Orang Tua' },
  { name: 'Milik Saudara Kandung' }
]

const TAX_LIST = [
  {
    name: 'Pribadi (PPh 21) dengan NPWP',
    show: true,
    value: "2.5"
  },
  {
    name: 'Pribadi (PPh 21) tanpa NPWP',
    show: false,
    value: "3"
  },
  {
    name: 'Badan (PPh 23) dengan NPWP',
    show: true,
    value: "2"
  },
  {
    name: 'Badan (PPh 23) tanpa NPWP',
    show: false,
    value: "4"
  }
]

const PROVINCE = [
  { name: "ACEH", latitude: 4.3685491, longitude: 97.0253024 },
  { name: "SUMATERA UTARA", latitude: 3.58967, longitude: 98.6738 },
  { name: "SUMATERA BARAT", latitude: -0.924759, longitude: 100.363 },
  { name: "RIAU", latitude: 0.9400084, longitude: 101.6556177 },
  { name: "JAMBI", latitude: -1.6395832, longitude: 102.9436845 },
  { name: "SUMATERA SELATAN", latitude: -2.9888297, longitude: 104.756857 },
  { name: "BENGKULU", latitude: -3.816013243, longitude: 102.2833464 },
  { name: "LAMPUNG", latitude: -4.8555039, longitude: 105.0272986 },
  { name: "KEPULAUAN BANGKA BELITUNG", latitude: -2.12067, longitude: 106.113 },
  { name: "KEPULAUAN RIAU", latitude: 1.1061, longitude: 104.038 },
  { name: "DKI JAKARTA", latitude: -6.1753936, longitude: 106.827186 },
  { name: "JAWA BARAT", latitude: -6.9344694, longitude: 107.6049539 },
  { name: "JAWA TENGAH", latitude: -6.9904038, longitude: 110.4229455 },
  { name: "DI YOGYAKARTA", latitude: -7.9778384, longitude: 110.3672257 },
  { name: "JAWA TIMUR", latitude: -7.2459717, longitude: 112.7378266 },
  { name: "BANTEN", latitude: -6.4453801, longitude: 106.1375586 },
  { name: "BALI", latitude: -8.6524973, longitude: 115.2191175 },
  { name: "NUSA TENGGARA BARAT", latitude: -8.58377, longitude: 116.107 },
  { name: "NUSA TENGGARA TIMUR", latitude: -10.1632, longitude: 123.602 },
  { name: "KALIMANTAN BARAT", latitude: 0.0226903, longitude: 109.345 },
  { name: "KALIMANTAN TENGAH", latitude: -2.20741, longitude: 113.916 },
  { name: "KALIMANTAN SELATAN", latitude: -3.31875, longitude: 114.593 },
  { name: "KALIMANTAN TIMUR", latitude: -0.50178, longitude: 117.139 },
  { name: "KALIMANTAN UTARA", latitude: 3.30002, longitude: 117.633 },
  { name: "SULAWESI UTARA", latitude: 1.49006, longitude: 124.841 },
  { name: "SULAWESI TENGAH", latitude: -0.895779, longitude: 119.868 },
  { name: "SULAWESI SELATAN", latitude: -5.1343, longitude: 119.412 },
  { name: "SULAWESI TENGGARA", latitude: -3.99181, longitude: 122.518 },
  { name: "GORONTALO", latitude: 0.7186174, longitude: 122.4555927 },
  { name: "SULAWESI BARAT", latitude: -3.54593, longitude: 118.961 },
  { name: "MALUKU", latitude: -3.118837, longitude: 129.4207759 },
  { name: "MALUKU UTARA", latitude: 0.785233, longitude: 127.383 },
  { name: "PAPUA BARAT", latitude: -0.86341, longitude: 131.254 },
  { name: "PAPUA", latitude: -2.645407, longitude: 140.8113427 }
]

const CONTRACT_PRICE_TYPE_LIST = [
  {
    name: 'Semua',
    value: 'all'
  },
  {
    name: 'Rp.0',
    value: '0'
  },
  {
    name: 'Rp. 8.000.000',
    value: '8000000'
  },
  {
    name: 'Rp. 10.000.000',
    value: '10000000'
  },
  {
    name: 'Rp. 12.000.000',
    value: '12000000'
  }
]


export {
  METHOD,
  ACCEPTED_IMAGE,
  PREFIX_IMAGE,
  STATUS,
  DURATION_ACTIVITY,
  SORTING,
  INSTALMENT_LIST,
  PREVIOUS_RHPP_SOLUTION,
  DEFERRAL_GUARANTEE,
  PARTNER_STATUS,
  ACQUISITION_FILE_IMPORT_PATH,
  DATA_LOCATION_PLAN,
  DATA_INFO_PARANJE,
  DATA_PROFESSION,
  DATA_REASON,
  CAGE_DIRECTION,
  SUPPORTING_FACILITIES,
  LAND_STATUS,
  TAX_LIST,
  MAPPING_FILTER,
  PROVINCE,
  CONTRACT_PRICE_TYPE_LIST
}
