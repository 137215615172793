import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_ACTIVITY_CAGE } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  activityCages: {meta:{},data:[]},
  activityCagesFilter: {meta:{},data:[]},
  activityCageDetail: {meta:{},data:{}},
  meta: {page:1,limit:10},
  modalDelete: {},
  importData: {},
  modalEdit: {},
  modalAdd: {},
  showAlert: {is_show: false},
  addActivityCage: {},
  updateActivityCage: {},
  deleteActivityCage: {}
}

const mutations = {
  "SET_ACTIVITY_CAGE": (state, payload) => {
    Vue.set(state, 'activityCages', payload)
  },
  "SET_ACTIVITY_FILTER": (state, payload) => {
    Vue.set(state, 'activityCagesFilter', payload)
  },
  "SET_ACTIVITY_DETAIL": (state, payload) => {
    Vue.set(state, 'activityCageDetail', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_IMPORT_DATA": (state, payload) => {
    Vue.set(state, 'importData', payload)
  },
  "SET_MODAL_EDIT": (state, payload) => {
    Vue.set(state, 'modalEdit', payload)
  },
  "SET_MODAL_ADD": (state, payload) => {
    Vue.set(state, 'modalAdd', payload)
  },
  "SET_SHOW_ALERT": (state, payload) => {
    Vue.set(state, 'showAlert', payload)
  },
  "SET_ADD_RESPONSE": (state, payload) => {
    Vue.set(state, 'addActivityCage', payload)
  },
  "SET_UPDATE_RESPONSE": (state, payload) => {
    Vue.set(state, 'updateActivityCage', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteActivityCage', payload)
  }
}

const actions = {
  async getActivityCage(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CAGE.LIST, data)
      context.commit('SET_ACTIVITY_CAGE', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityCageFilter(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CAGE.LIST, data)
      context.commit('SET_ACTIVITY_FILTER', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async getActivityCageDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_CAGE.DETAIL(data))
      context.commit('SET_ACTIVITY_DETAIL', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async importActivityCage(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY_CAGE.IMPORT, data)
      context.commit('SET_IMPORT_DATA', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteActivityCage(context, data) {
    try {
      const response = await apiDeleteAuth(API_ACTIVITY_CAGE.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async saveActivityCage(context, data) {
    try {
      const response = await apiPostAuth(API_ACTIVITY_CAGE.ADD, data)
      context.commit('SET_ADD_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  },
  async updateActivityCage(context, data) {
    try {
      const response = await apiPutAuth(API_ACTIVITY_CAGE.UPDATE(data.id), data)
      context.commit('SET_UPDATE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
      throw err
    }
  }
}

const getters = {
  activityCages: state => {
    return state.activityCages
  },
  activityCagesFilter: state => {
    return state.activityCagesFilter
  },
  activityCageDetail: state => {
    return state.activityCageDetail
  },
  meta: state => {
    return state.meta
  },
  modalDelete: state => {
    return state.modalDelete
  },
  importData: state => {
    return state.importData
  },
  modalEdit: state => {
    return state.modalEdit
  },
  modalAdd: state => {
    return state.modalAdd
  },
  showAlert: state => {
    return state.showAlert
  },
  getAddActivityCageMsg: state => {
    return state.addActivityCage
  },
  getUpdateActivityCageMsg: state => {
    return state.updateActivityCage
  },
  getDeleteActivityCageMsg: state => {
    return state.deleteActivityCage
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
