<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1">
      <div class="uk-card uk-card-default kandang-card wrapper-status">
        <div
          class="uk-child-width-expand@s uk-grid-small uk-margin-bottom"
          uk-grid
        >
          <div class="uk-width-1-3 uk-flex uk-flex-middle">
            <h4 class="label-green">
              YTD Mapping Area
            </h4>
          </div>
          <div class="uk-width-expand uk-flex uk-flex-right">
            <label
              class="uk-form-label filter-margin uk-width-1-5 label-green"
              for="status"
            >
              Status Mitra
            </label>
            <div class="uk-form-controls uk-width-2-5 uk-margin-right">
              <multiselect
                id="status"
                v-model="dataFilterStatus"
                name="status"
                label="name"
                track-by="name"
                placeholder="Pilih"
                open-direction="bottom"
                deselect-label="Required!"
                :options="dataStatus"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
                :allow-empty="false"
                @change="handleFilter"
              >
                <span slot="noResult">Oops! Data tidak ditemukan</span>
              </multiselect>
            </div>
            <label
              class="uk-form-label filter-margin uk-width-1-2 label-green"
              for="status"
            >
              Pilih Lokasi
            </label>
            <div class="uk-form-controls">
              <multiselect
                id="status"
                v-model="dataFilter"
                name="status"
                label="name"
                track-by="name"
                placeholder="Semua"
                open-direction="bottom"
                :options="['a', 'b']"
                :close-on-select="true"
                :max-height="500"
                :show-no-result="true"
                @input="handleFilter"
              >
                <span slot="noResult">Oops! Data tidak ditemukan</span>
              </multiselect>
            </div>
          </div>
        </div>
        <hr>
        <template v-if="isLoading">
          <ComponentLoading />
        </template>
        <div
          v-else
          class="wrapper-image-map"
        >
          <maps
            :lat-val="dataFilter.latitude"
            :long-val="dataFilter.longitude"
            :zoom-val="dataFilter.zoom"
            :map-dataset="mapDataset"
            :filter="dataFilterStatus.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE, MAPPING_FILTER, PROVINCE } from '@/utils/constant'
import ComponentLoading from '@/components/globals/loading'
import Maps from '@/components/globals/modal/MapsChloropleth.vue'

export default {
  components: {
    ComponentLoading,
    Maps
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      isLoading: true,
      dataStatus: MAPPING_FILTER,
      dataFilter: null,
      dataFilterStatus: null,
      provArea: PROVINCE,
      mapDataset: []
    }
  },
  computed: {
    ...mapGetters({
      dataMappingAreaChloropleth: 'acquisitionDashboard/dataMappingAreaChloropleth',
      dataMeta: "acquisitionDashboard/dataMeta"
    })
  },
  watch: {
    async dataFilterStatus(){
      this.handleFilter()
    },
    dataMappingAreaChloropleth() {
      if (this.dataMappingAreaChloropleth) {
        this.mapDataset = this.dataMappingAreaChloropleth
      }
      else {
        return this.mapDataset = []
        
      }
      this.isLoading = false
    }
  },
  mounted(){
    this.dataFilterStatus = MAPPING_FILTER.find((item) => item.name == 'Semua')
    this.dataFilter = PROVINCE.find((item) => item.name=="DKI JAKARTA")
    this.fetchGeoJSON()
  },
  methods: {
    ...mapActions({
      fetchMappingAreaDataset: "acquisitionDashboard/fetchMappingAreaDataset"
    }),
    ...mapMutations({
      setDataMeta: 'acquisitionDashboard/SET_DATA_META'
    }),
    async handleFilter() {
      this.setDataMeta({
        ...this.dataMeta,
        status: this.dataFilterStatus ? this.dataFilterStatus.name != 'Semua' ? 'farmers' : '' : ''
      })
      await this.fetchMappingAreaDataset(this.dataMeta)
    }
  }
}
</script>

<style scoped>
  .wrapper-status {
    padding: 16px;
  }

  .wrapper-status h4 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .wrapper-status h1 {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
  }

  .wrapper-status span {
    font-family: ClanOTMedium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .wrapper-status .status-minus {
    color: #F92626;
  }

  .wrapper-status .status-plus {
    color: #025231;
  }

  .wrapper-image-map {
    position: relative;
  }

  .wrapper-image-map img {
    width: 100%;
  }

  .wrapper-image-map .section-target-map img {
    width: 15px;
    height: auto;
  }

  .section-target-map span {
    font-size: 6px;
    color: #000000;
  }

  .section-target-map .position-default {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  .section-target-map .position-kab-bogor {
    top: 25%;
    left: 23%;
  }
    .section-target-map .position-bogor {
    top: 28%;
    left: 16%;
  }
  .section-target-map .position-sukabumi {
   bottom: 35%;
    left: 14%;
  }

  .section-target-map .position-kab-cianjur {
    top: 66%;
    left: 30%;
  }

  .section-target-map .position-bandung {
    top: 47%;
    left: 39%;
  }

  .section-target-map .position-kab-bandung {
    top: 53%;
    left: 38%;
  }

  .section-target-map .position-kab-bandung-barat {
    top: 37%;
    left: 43%;
  }

  .section-target-map .position-purwakarta {
    top: 25%;
    left: 40%;
  }

  .section-target-map .position-bekasi {
    top: 5%;
    left: 30%;
  }

  .section-target-map .position-kab-bekasi {
    top: 15%;
    left: 30%;
  }

  .section-target-map .position-karawang {
    top: 10%;
    left: 40%;
  }

  .section-target-map .position-subang {
    top: 20%;
    left: 52%;
  }

  .section-target-map .position-indramayu {
    top: 20%;
    left: 70%;
  }

  .section-target-map .position-sumedang {
    top: 40%;
    left: 62%;
  }

  .section-target-map .position-garut {
    top: 75%;
    left: 55%;
  }

  .section-target-map .position-tasikmalaya {
    top: 80%;
    left: 68%;
  }

  .section-target-map .position-pangandaran {
    top: 83%;
    left: 85%;
  }

  .section-target-map .position-ciamis {
    top: 60%;
    left: 80%;
  }

  .section-target-map .position-banjar {
    top: 70%;
    left: 87%;
  }

  .section-target-map .position-cirebon {
    top: 50%;
    left: 87%;
  }

  .section-target-map .position-cirebon-2 {
    top: 28%;
    left: 82%;
  }

  .section-target-map .position-kab-kuningan {
    top: 40%;
    left: 91%;
  }

  .section-target-map .position-majalengka {
    top: 40%;
    left: 75%;
  }
</style>
