import Vue from 'vue'
import { apiGetAuth } from '@/utils/api'
import { API_ACTIVITY_DETAIL_MASTER } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  masterActivityDetails: [],
  meta: {page:1,limit:10}
}

const mutations = {
  "SET_ACTIVITY_LOG_DETAILS": (state, payload) => {
    Vue.set(state, 'masterActivityDetails', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  }
}

const actions = {
  async getMasterActivityDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_DETAIL_MASTER.LIST_ALL, data)
      context.commit('SET_ACTIVITY_LOG_DETAILS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  masterActivityDetails: state => {
    return state.masterActivityDetails
  },
  meta: state => {
    return state.meta
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
