import Vue from 'vue'
import { apiGetAuth, apiDeleteAuth } from '@/utils/api'
import { API_ACTIVITY_LOG_DETAIL } from '@/utils/api-url'
import {
  notificationDanger
} from '@/utils/notification'


const state = {
  activityLogDetails: {meta:{},data:[]},
  deleteActivityLogDetail: {},
  modalDelete: {},
  meta: {page:1,limit:10}
}

const mutations = {
  "SET_ACTIVITY_LOG_DETAILS": (state, payload) => {
    Vue.set(state, 'activityLogDetails', payload)
  },
  "SET_DELETE_RESPONSE": (state, payload) => {
    Vue.set(state, 'deleteActivityLogDetail', payload)
  },
  "SET_MODAL_DELETE": (state, payload) => {
    Vue.set(state, 'modalDelete', payload)
  },
  "SET_META": (state, payload) => {
    Vue.set(state, 'meta', payload)
  }
}

const actions = {
  async getActivityLogDetail(context, data) {
    try {
      const response = await apiGetAuth(API_ACTIVITY_LOG_DETAIL.LIST, data)
      context.commit('SET_ACTIVITY_LOG_DETAILS', response.data.data)
    } catch (err) {
      notificationDanger(err)
    }
  },
  async deleteActivityLogDetail(context, data) {
    try {
      const response = await apiDeleteAuth(API_ACTIVITY_LOG_DETAIL.DELETE(data))
      context.commit('SET_DELETE_RESPONSE', response.data)
    } catch (err) {
      notificationDanger(err)
    }
  }
}

const getters = {
  activityLogDetails: state => {
    return state.activityLogDetails
  },
  getDeleteActivityLogDetailMsg: state => {
    return state.deleteActivityLogDetail
  },
  modalDelete: state => {
    return state.modalDelete
  },
  meta: state => {
    return state.meta
  }
}

const namespaced = true
export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
